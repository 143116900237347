import React, { useEffect, useState, useRef } from 'react';
import { DashboardTileWidget, OnboardingForm } from '../widgets';
import { FLEET_MANAGING_BASE_URL, SUPPORT_SERVICE_BASE_URL, getStorageItem, setStorageItem } from '../../../app/services/baseService';
import Axios from 'axios';
import TrendingUpTwoToneIcon from '@mui/icons-material/TrendingUpTwoTone';
import CloseIcon from '@mui/icons-material/Close';
import { SimpleModal } from '../widgets/modals/SimpleModal';
import LineChart from '../charts/LineChart';
import BarChart from '../charts/BarChart';
import RiderSafetyInfo from '../info/RiderSafetyInfo';
import { useSelector } from "react-redux";
import { ChipDisplay } from '../widgets';
import PerfectScrollbar from "react-perfect-scrollbar";
import {
    Avatar,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography,
    Snackbar,
    Button,
    IconButton,
    Skeleton,
    Alert,
} from '@mui/material';
import { Nav, Tab } from "react-bootstrap";
import { makeStyles } from '@mui/styles';
import BasicLoader from '../loaders/SimpleLoader';
import { DashboardTileWidgetAlt } from '../widgets/tiles/DashboardTileWidget';
import Co2TwoToneIcon from '@mui/icons-material/Co2TwoTone';
import OutboundTwoToneIcon from '@mui/icons-material/OutboundTwoTone';
import ShutterSpeedTwoToneIcon from '@mui/icons-material/ShutterSpeedTwoTone';
import TimelapseTwoToneIcon from '@mui/icons-material/TimelapseTwoTone';
import DynamicApexChart from '../charts/DynamicApexChart';
import OpenInNewTwoToneIcon from '@mui/icons-material/OpenInNewTwoTone';
import { useHistory } from 'react-router-dom';
import DateRangeTwoToneIcon from '@mui/icons-material/DateRangeTwoTone';
import RouteTwoToneIcon from '@mui/icons-material/RouteTwoTone';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        maxWidth: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    changeLogModal: {
        width: '100%',
        maxWidth: '100%',
        backgroundColor: theme.palette.background.paper,
        height: '400px',
        overflow: 'scroll'
    },
    overflower: {
        overflow: 'auto',
        overflowAnchor: 'auto'
    },
    inactiveRow: {
        borderWidth: '1px',
        borderColor: '#006200'
    }
}));

const perfectScrollbarOptions = {
    wheelSpeed: 2,
    wheelPropagation: false,
    swipeEasing: true,
    minScrollbarLength: 20
};

export function DashboardDefault() {

    const history = useHistory();
    const invoicedAmount = 1265035;
    const collectedAmount = 1220798;

    const [reRender, setReRender] = useState(0);

    const [vehicleCount, setVehicleCount] = useState(0);
    const [loadingVehicleCount, setLoadingVehicleCount] = useState(false);

    const [activeVehicleCount, setActiveVehicleCount] = useState(0);
    const [loadingActiveVehicleCount, setLoadingActiveVehicleCount] = useState(false);

    const [fleetSizeCount, setFleetSizeCount] = useState(0);
    const [loadingFleetSizeCount, setLoadingFleetSizeCount] = useState(false);

    const [projectVehicleCount, setProjectVehicleCount] = useState(0);
    const [loadingProjectVehicleCount, setLoadingProjectVehicleCount] = useState(false);

    const [assignedVehiclesCount, setAssignedVehiclesCount] = useState(0);
    const [loadingAssignedVehiclesCount, setLoadingAssignedVehiclesCount] = useState(false);

    const [staffVehicleCount, setStaffVehicleCount] = useState(0);
    const [loadingStaffVehicleCount, setLoadingStaffVehicleCount] = useState(false);

    const [customerVehicleCount, setCustomerVehicleCount] = useState(0);
    const [loadingCustomerVehicleCount, setLoadingCustomerVehicleCount] = useState(false);

    const [driverCount, setDriverCount] = useState(0);
    const [activeDriverCount, setActiveDriverCount] = useState(0);
    const [loadingActiveDriverCount, setLoadingActiveDriverCount] = useState(false);

    const [testDriversCount, setTestDriversCount] = useState(0);
    const [staffDriversCount, setStaffDriversCount] = useState(0);

    const [showTotalBikePop, setShowTotalBikePop] = useState(false);
    const [fleetTravel, setFleetTravel] = useState(false);
    const [fleetCoAvoided, setFleetCoAvoided] = useState(false);
    const [showAllRidersGender, setShowAllRidersGender] = useState(false);
    const [showActiveRidersGender, setShowActiveRidersGender] = useState(false);
    const [showInActiveRidersGender, setShowInActiveRidersGender] = useState(false);
    const [showChurnedRidersGender, setShowChurnedRidersGender] = useState(false);
    const [showTotalHarshBrakingEvents, setShowTotalHarshBrakingEvents] = useState(false);
    const [showTotalOverSpeedingEvents, setShowTotalOverSpeedingEvents] = useState(false);
    const [showTotalKMRiddenPast10pm, setShowTotalKMRiddenPast10pm] = useState(false);
    const [showRiderSafetyInfo, setShowRiderSafetyInfo] = useState(false);
    const [activeReleaseNotes, setActiveReleaseNotes] = useState();

    const [vehiclesInRepair, setVehiclesInRepair] = useState(0);
    const [vehiclesNotDeployed, setVehiclesNotDeployed] = useState(0);

    const [loadingVehiclesInRepair, setLoadingVehiclesInRepair] = useState(true);
    const [loadingVehiclesNotDeployed, setLoadingVehiclesNotDeployed] = useState(true);

    const [triggerSnackbarOpen, setTriggerSnackbarOpen] = useState(false);
    const [severity, setSeverity] = useState('info');

    const [showChangeLog, setShowChangeLog] = useState(false);
    const [changeLogUserFirstName, setChangeLogUserFirstName] = useState("");
    const [changeLogUserRole, setChangeLogUserRole] = useState([]);

    const [vehicleCountTrends, setVehicleCountTrends] = useState(null);
    const [fleetTravelCountTrending, setFleetTravelCountTrending] = useState(null);
    const [isLoadingVehicleCountTrends, setIsLoadingVehicleCountTrends] = useState(true);
    const [isLoadingVehicleCountTrendsError, setIsLoadingVehicleCountTrendsError] = useState(false);

    const [togoFleetSizeCount, setTogoFleetSizeCount] = useState(0);
    const [loadingTogoFleetSizeCount, setLoadingTogoFleetSizeCount] = useState(false);

    const [maxYaxisVal, setMaxYaxisVal] = useState(100);
    const [showActionButtons, setShowActionButtons] = useState(false);

    const [key, setKey] = useState("Monthly");

    const [analyticsData, setAnalyticsData] = useState(null);
    const [isLoadingAnalytics, setIsLoadingAnalytics] = useState(true);

    const [weeklyMaxYaxisVal, setWeeklyMaxYaxisVal] = useState(100);
    const [monthlyMaxYaxisVal, setMonthlyMaxYaxisVal] = useState(100);
    const [maxFleetMileageValue, setMaxFleetMileageValue] = useState(500000);
    const [maxCarbonAvoidedValue, setMaxCarbonAvoidedValue] = useState(50000);
    const [maxHarshBrakingValue, setMaxHarshBrakingValue] = useState(50000);
    const [maxOverSpeedingValue, setMaxOverSpeedingValue] = useState(50000);
    const [maxUnsafeDistanceTraveledValue, setMaxUnsafeDistanceTraveledValue] = useState(50000);

    const fetchErrorMessage = useRef();
    const fetchVehicleCountTrendsErrorMessage = useRef();

    const classes = useStyles();

    const { user } = useSelector((state) => state.auth);
    const userRole = user.user_data.document.role;

    const RenderVehicleBreakDown = (props) => {
        return (
            <>
                <div className="mt-1">
                    <div className="row m-0">
                        <div className='col p-2'>
                            <div className="bg-light-primary px-6 py-8 rounded-xl">
                                <span className="svg-icon svg-icon-3x svg-icon-primary d-block my-2">
                                    <h2>
                                        {props.fleetSize}
                                    </h2>
                                </span>
                                <span
                                    className="text-primary font-weight-bold font-size-h6 mt-2"
                                >
                                    Ghana Fleet
                                </span>
                            </div>
                        </div>
                        <div className='col p-2'>
                            <div className="bg-light-primary px-6 py-8 rounded-xl">
                                <span className="svg-icon svg-icon-3x svg-icon-primary d-block my-2">
                                    <h2>
                                        {props.togoFleetSize}
                                    </h2>
                                </span>
                                <span
                                    className="text-primary font-weight-bold font-size-h6 mt-2"
                                >
                                    Togo Fleet
                                </span>
                            </div>
                        </div>
                        <div className='col p-2'>
                            <div className="bg-light-warning px-6 py-8 rounded-xl">
                                <span className="svg-icon svg-icon-3x svg-icon-warning d-block my-2">
                                    <h2>
                                        {props.projectVehicles}
                                    </h2>
                                </span>
                                <span className="text-warning font-weight-bold font-size-h6">
                                    Prototypes
                                </span>
                            </div>
                        </div>
                        <div className='col p-2'>
                            <div className="bg-light-secondary px-6 py-8 rounded-xl">
                                <span className="svg-icon svg-icon-3x svg-icon-secondary d-block my-2">
                                    <h2>
                                        {props.customerVehicleCount}
                                    </h2>
                                </span>
                                <span
                                    className="text-secondary font-weight-bold font-size-h6 mt-2"
                                >
                                    Customer Vehicles
                                </span>
                            </div>
                        </div>
                        <div className='col p-2'>
                            <div className="bg-light-info px-6 py-8 rounded-xl">
                                <span className="svg-icon svg-icon-3x svg-icon-info d-block my-2">
                                    <h2>
                                        {props.staffVehicleCount}
                                    </h2>
                                </span>
                                <span
                                    className="text-info font-weight-bold font-size-h6 mt-2"
                                >
                                    Staff Vehicles
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="row m-0">
                        <div className='col p-2'>
                            <div className="bg-light-success px-6 py-8 rounded-xl">
                                <span className="svg-icon svg-icon-3x svg-icon-success d-block my-2">
                                    <h2>
                                        {props.activeVehicles}
                                    </h2>
                                </span>
                                <span
                                    className="text-success font-weight-bold font-size-h6 mt-2"
                                >
                                    Active Vehicle
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className='p-2'>
                        <div className="row m-0 bg-light-danger px-6 py-8 rounded-xl">
                            <div className='col p-2'>
                                <div className="bg-light-danger px-6 py-8 rounded-xl">
                                    <span className="svg-icon svg-icon-3x svg-icon-danger d-block my-2">
                                        <h2>
                                            {props.inactiveVehicles}
                                        </h2>
                                    </span>
                                    <span
                                        className="text-danger font-weight-bold font-size-h6 mt-2"
                                    >
                                        Inactive Vehicles
                                    </span>
                                </div>
                            </div>
                            <div className='col p-2'>
                                <div className="bg-light-success px-6 py-8 rounded-xl">
                                    <span className="svg-icon svg-icon-3x svg-icon-success d-block my-2">
                                        <h2>
                                            {props.vehiclesNotDeployed}
                                        </h2>
                                    </span>
                                    <span
                                        className="text-success font-weight-bold font-size-h6 mt-2"
                                    >
                                        <span>
                                            Ready
                                            {" "}
                                            <OpenInNewTwoToneIcon
                                                className="cursor-pointer"
                                                onClick={() => {
                                                    history.push('/vehicles/ready');
                                                }}
                                            />
                                        </span>
                                    </span>
                                </div>
                            </div>
                            <div className='col p-2'>
                                <div className="bg-light-warning px-6 py-8 rounded-xl">
                                    <span className="svg-icon svg-icon-3x svg-icon-warning d-block my-2">
                                        <h2>
                                            {props.vehiclesInRepair}
                                        </h2>
                                    </span>
                                    <span
                                        className="text-warning font-weight-bold font-size-h6 mt-2"
                                    >
                                        <span>
                                            In Repair
                                            {" "}
                                            <OpenInNewTwoToneIcon
                                                className="cursor-pointer"
                                                onClick={() => {
                                                    history.push('/vehicles/in-repair');
                                                }}
                                            />
                                        </span>
                                    </span>
                                </div>
                            </div>
                            <div className='col p-2'>
                                <div className="bg-light-warning px-6 py-8 rounded-xl">
                                    <span className="svg-icon svg-icon-3x svg-icon-warning d-block my-2">
                                        <h2>
                                            {props.vehiclesBrokenDown}
                                        </h2>
                                    </span>
                                    <span
                                        className="text-warning font-weight-bold font-size-h6 mt-2"
                                    >
                                        <span>
                                            Broken
                                            {" "}
                                            <OpenInNewTwoToneIcon
                                                className="cursor-pointer"
                                                onClick={() => {
                                                    history.push('/vehicles/broken');
                                                }}
                                            />
                                        </span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    };

    const CustomDriverRender = (props) => {
        return (
            <>
                <div className="mt-1">
                    <div className="row m-0">
                        <div className='col p-2'>
                            <div className="col bg-light-success px-6 py-8 rounded-xl">
                                <span className="svg-icon svg-icon-3x svg-icon-success d-block my-2">
                                    <h2>
                                        {props.activeDrivers}
                                    </h2>
                                </span>
                                <span
                                    className="text-success font-weight-bold font-size-h6 mt-2"
                                >
                                    Active Drivers
                                </span>
                            </div>
                        </div>
                        <div className='col p-2'>
                            <div className="col bg-light-secondary px-6 py-8 rounded-xl">
                                <span className="svg-icon svg-icon-3x svg-icon-secondary d-block my-2">
                                    <h2>
                                        {props.pipelineAccounts}
                                    </h2>
                                </span>
                                <span
                                    className="text-secondary font-weight-bold font-size-h6 mt-2"
                                >
                                    Drivers in onboarding Queue
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="row m-0">
                        <div className='col p-2'>
                            <div className="col bg-light-warning px-6 py-8 rounded-xl">
                                <span className="svg-icon svg-icon-3x svg-icon-warning d-block my-2">
                                    <h2>
                                        {props.testRiders}
                                    </h2>
                                </span>
                                <span className="text-warning font-weight-bold font-size-h6">
                                    Test Rider Accounts
                                </span>
                            </div>
                        </div>
                        <div className='col p-2'>
                            <div className="col bg-light-primary px-6 py-8 rounded-xl">
                                <span className="svg-icon svg-icon-3x svg-icon-primary d-block my-2">
                                    <h2>
                                        {props.staffRiders}
                                    </h2>
                                </span>
                                <span
                                    className="text-primary font-weight-bold font-size-h6 mt-2"
                                >
                                    Staff Rider Accounts
                                </span>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className="row m-0">
                        <div className="col bg-light-danger px-6 py-8 rounded-xl mr-7">
                            <span className="svg-icon svg-icon-3x svg-icon-danger d-block my-2">
                                <h2>
                                    {props.churnedDrivers}
                                </h2>
                            </span>
                            <span className="text-danger font-weight-bold font-size-h6">
                                Churned Drivers
                            </span>
                        </div>
                        <div className="col bg-light-warning px-6 py-8 rounded-xl">
                            <span className="svg-icon svg-icon-3x svg-icon-warning d-block my-2">
                                <h2>
                                    {props.delinquentDrivers}
                                </h2>
                            </span>
                            <span
                                className="text-warning font-weight-bold font-size-h6 mt-2"
                            >
                                Delinquent Drivers
                            </span>
                        </div>
                    </div>
                </div>
            </>
        );
    };

    const ShowChangeLogModal = (props) => {

        const changeLogBaseObj = props?.changeLogObject;
        if (!changeLogBaseObj) return (
            <>
                <Alert
                    elevation={6}
                    variant="filled"
                    severity="error"
                    onClose={handleSnackbarClose}
                >
                    <span id="message-id">
                        Could not fetch change log
                    </span>
                </Alert>
            </>
        );

        const changeLogFeatures = changeLogBaseObj[0].document?.features?.map((feature, index) =>
            <ListItem key={index}>
                <ListItemAvatar>
                    <Avatar sx={{ bgcolor: "#1F384C" }}>
                        <Typography
                            variant="h6"
                            component="h6"
                            color={'primary'}
                        >
                            {index + 1}
                        </Typography>
                    </Avatar>
                </ListItemAvatar>
                <ListItemText
                    primary={feature}
                    secondary=''
                />
            </ListItem>
        );

        const changeLogFixes = changeLogBaseObj[0].document?.fixes?.map((fix, index) =>
            <ListItem key={index}>
                <ListItemAvatar>
                    <Avatar sx={{ bgcolor: "#1F384C" }}>
                        <Typography variant="h6" component="h6" color={'#ffa726'}>
                            {index + 1}
                        </Typography>
                    </Avatar>
                </ListItemAvatar>
                <ListItemText primary={fix} secondary='' />
            </ListItem>
        );

        const changeLogImprovements = changeLogBaseObj[0].document?.improvements?.map((improvement, index) =>
            <ListItem key={index}>
                <ListItemAvatar>
                    <Avatar sx={{ bgcolor: "#1F384C" }}>
                        <Typography variant="h6" component="h6" color={'secondary'}>
                            {index + 1}
                        </Typography>
                    </Avatar>
                </ListItemAvatar>
                <ListItemText primary={improvement} secondary='' />
            </ListItem>
        );

        return (
            <>
                <div className='centerMan'>
                    {
                        changeLogUserRole.includes("guest") ?
                            <>
                                <div className="bg-light-warning px-6 py-2 rounded-xl mb-2">
                                    <small className="text-warning font-weight-bold">
                                        This is a GUEST account which means you will have limited privileges on some functionality
                                    </small>
                                </div>
                                <h6 className='text-center'>
                                    {`Hi ${changeLogUserFirstName},`}
                                </h6>
                                <h6 className='text-center'>
                                    Here some new features we've added across our service platforms to improve your experience
                                </h6>
                            </>
                            :
                            <>
                                <h6 className='text-center'>
                                    {`Hi ${changeLogUserFirstName},`}
                                </h6>
                                <h6 className='text-center'>
                                    Here some new features we've added across our service platforms to improve your experience
                                </h6>
                            </>
                    }
                </div>
                <div className={classes.changeLogModal}>
                    <List className={classes.root}>
                        <div className='border-bottom border-success'>
                            <div className='centerMan'>
                                <ChipDisplay
                                    color={'primary'}
                                    size={'medium'}
                                    label={'NEW FEATURES'}
                                />
                            </div>
                            {changeLogFeatures}
                        </div>
                        <div className='border-bottom border-warning'>
                            <div className='centerMan mt-5'>
                                <ChipDisplay
                                    color={'warning'}
                                    size={'medium'}
                                    label={'FIXES'}
                                />
                            </div>
                            {changeLogFixes}
                        </div>
                        <div>
                            <div className='centerMan mt-5'>
                                <ChipDisplay
                                    color={'secondary'}
                                    size={'medium'}
                                    label={'IMPROVEMENTS'}
                                />
                            </div>
                            {changeLogImprovements}
                        </div>
                    </List>
                </div>
            </>
        );

    };

    function handleSnackbarClose(event, reason) {
        if (reason === 'clickaway')
            return;
        setTriggerSnackbarOpen(false);
    }

    function onChangeWorker() {
        setReRender(prevState => prevState + 1);
    }

    useEffect(() => {

        let isMounted = true;
        const controller = new AbortController();

        if (!user?.user_data?.document?.bioText || !user?.user_data?.document?.department || !user?.user_data?.document?.roleTitle) {
            history.push('/user-onboarding', {
                initialValues: {
                    firstName: user?.user_data?.document?.firstName ?? "",
                    lastName: user?.user_data?.document?.lastName ?? "",
                    email: user?.user_data?.document?.email ?? "",
                    gender: user?.user_data?.document?.gender ?? "",
                    phoneNumber: user?.user_data?.document?.phoneNumber ?? user?.user_data?.document?.phone,
                    country: user?.user_data?.document?.country ?? "",
                    roleTitle: user?.user_data?.document?.roleTitle ?? "",
                    department: user?.user_data?.document?.department ?? "",
                    bioText: user?.user_data?.document?.bioText ?? "",
                },
                createEditText: "Complete",
                fieldDisabled: true,
                onChanger: onChangeWorker(),
                adminID: user?.user_data?.document?.ID,
                displayName: user?.user_data?.document?.displayName ?? user?.user_data?.document?.firstName
            });
        }

        const checkChangeLogDismissed = async () => {

            const url = new URL('/release-notes?filter=isActive', `${SUPPORT_SERVICE_BASE_URL}`);

            try {

                const response = Axios.get(url.href, { signal: controller.signal });
                const data = (await response).data;

                setActiveReleaseNotes(data?.data);

            } catch (error) {

                setSeverity("error");

                if (error?.code === "ERR_NETWORK" || error?.code === "ECONNABORTED") {

                    fetchErrorMessage.current = "network error, please check your internet connection";
                    return setTriggerSnackbarOpen(true);

                }

                fetchErrorMessage.current = error?.response?.data?.message ?? error?.response?.data?.details;
                setTriggerSnackbarOpen(true);

            } finally {

                const changeLogUserFirstName = user.user_data.document.firstName;
                const userRole = user.user_data.document.role;

                setChangeLogUserFirstName(changeLogUserFirstName);
                setChangeLogUserRole(userRole);

                //const isChangeLogOpenedBefore = getStorageItem("openedChangeLog");
                //if (!isChangeLogOpenedBefore)
                //isMounted && setShowChangeLog(true);

            }

        };

        const getDriverCount = async () => {

            const url = new URL('/counters/drivers/getDriverCount', `${FLEET_MANAGING_BASE_URL}`);

            try {

                const response = Axios.get(url.href, { signal: controller.signal });
                const data = (await response).data;

                setDriverCount(data.count);

            } catch (error) {

                setSeverity("error");

                if (error?.code === "ERR_NETWORK" || error?.code === "ECONNABORTED") {

                    fetchErrorMessage.current = "network error, please check your internet connection";
                    return setTriggerSnackbarOpen(true);

                }

                fetchErrorMessage.current = error?.response?.data?.message ?? error?.message ?? error?.response?.data?.code ?? error?.code;
                setTriggerSnackbarOpen(true);

            } finally {

            }

        };

        const getActiveDriverCount = async () => {

            setLoadingActiveDriverCount(true);
            const url = new URL('/counters/drivers/getActiveDriverCount', `${FLEET_MANAGING_BASE_URL}`);

            try {

                const response = Axios.get(url.href, { signal: controller.signal });
                const data = (await response).data;

                setActiveDriverCount(data.count);

            } catch (error) {

                setSeverity("error");

                if (error?.code === "ERR_NETWORK" || error?.code === "ECONNABORTED") {

                    fetchErrorMessage.current = "network error, please check your internet connection";
                    return setTriggerSnackbarOpen(true);

                }

                fetchErrorMessage.current = error?.response?.data?.message ?? error?.message ?? error?.response?.data?.code ?? error?.code;
                setTriggerSnackbarOpen(true);

            } finally {
                setLoadingActiveDriverCount(false);
            }

        };

        const getTestDriversCount = async () => {

            const url = new URL('/counters/drivers/getTestDriversCount', `${FLEET_MANAGING_BASE_URL}`);

            try {

                const response = Axios.get(url.href, { signal: controller.signal });
                const data = (await response).data;

                setTestDriversCount(data.count);

            } catch (error) {

                setSeverity("error");

                if (error?.code === "ERR_NETWORK" || error?.code === "ECONNABORTED") {

                    fetchErrorMessage.current = "network error, please check your internet connection";
                    return setTriggerSnackbarOpen(true);

                }

                fetchErrorMessage.current = error?.response?.data?.message ?? error?.message ?? error?.response?.data?.code ?? error?.code;
                setTriggerSnackbarOpen(true);

            }

        };

        const getStaffDriversCount = async () => {

            const url = new URL('/counters/drivers/getStaffDriverCount', `${FLEET_MANAGING_BASE_URL}`);

            try {

                const response = Axios.get(url.href, { signal: controller.signal });
                const data = (await response).data;

                setStaffDriversCount(data.count);

            } catch (error) {

                setSeverity("error");

                if (error?.code === "ERR_NETWORK" || error?.code === "ECONNABORTED") {

                    fetchErrorMessage.current = "network error, please check your internet connection";
                    return setTriggerSnackbarOpen(true);

                }

                fetchErrorMessage.current = error?.response?.data?.message ?? error?.message ?? error?.response?.data?.code ?? error?.code;
                setTriggerSnackbarOpen(true);

            }

        };

        const getVehicleCount = async () => {

            const url = new URL('/counters/vehicles/getVehicleCount', `${FLEET_MANAGING_BASE_URL}`);

            try {

                const response = Axios.get(url.href, { signal: controller.signal });
                const data = (await response).data;

                setVehicleCount(data.count);

            } catch (error) {

                setSeverity("error");

                if (error?.code === "ERR_NETWORK" || error?.code === "ECONNABORTED") {

                    fetchErrorMessage.current = "network error, please check your internet connection";
                    return setTriggerSnackbarOpen(true);

                }

                fetchErrorMessage.current = error?.response?.data?.message ?? error?.message ?? error?.response?.data?.code ?? error?.code;
                setTriggerSnackbarOpen(true);

            } finally {
                setLoadingVehicleCount(false);
            }

        };

        const getActiveVehicleCount = async () => {

            setLoadingActiveVehicleCount(true);
            const url = new URL('/counters/vehicles/getActiveVehicleCount', `${FLEET_MANAGING_BASE_URL}`);

            try {

                const response = Axios.get(url.href, { signal: controller.signal });
                const data = (await response).data;

                setActiveVehicleCount(data.count);


            } catch (error) {

                setSeverity("error");

                if (error?.code === "ERR_NETWORK" || error?.code === "ECONNABORTED") {

                    fetchErrorMessage.current = "network error, please check your internet connection";
                    return setTriggerSnackbarOpen(true);

                }

                fetchErrorMessage.current = error?.response?.data?.message ?? error?.message ?? error?.response?.data?.code ?? error?.code;
                setTriggerSnackbarOpen(true);

            } finally {
                setLoadingActiveVehicleCount(false);
            }

        };

        const getAssignedVehiclesCount = async () => {

            setLoadingAssignedVehiclesCount(true);
            const url = new URL('/counters/vehicles/getAssignedVehiclesCount', `${FLEET_MANAGING_BASE_URL}`);

            try {

                const response = Axios.get(url.href, { signal: controller.signal });
                const data = (await response).data;

                setAssignedVehiclesCount(data.count);

            } catch (error) {

                setSeverity("error");

                if (error?.code === "ERR_NETWORK" || error?.code === "ECONNABORTED") {

                    fetchErrorMessage.current = "network error, please check your internet connection";
                    return setTriggerSnackbarOpen(true);

                }

                fetchErrorMessage.current = error?.response?.data?.message ?? error?.message ?? error?.response?.data?.code ?? error?.code;
                setTriggerSnackbarOpen(true);

            } finally {
                setLoadingAssignedVehiclesCount(false);
            }

        };

        const getFleetSizeCount = async () => {

            setLoadingFleetSizeCount(true);
            const url = new URL('/counters/vehicles/getFleetSize', `${FLEET_MANAGING_BASE_URL}`);

            try {

                const response = Axios.get(url.href, { signal: controller.signal });
                const data = (await response).data;

                setFleetSizeCount(data.count);

            } catch (error) {

                setSeverity("error");

                if (error?.code === "ERR_NETWORK" || error?.code === "ECONNABORTED") {

                    fetchErrorMessage.current = "network error, please check your internet connection";
                    return setTriggerSnackbarOpen(true);

                }

                fetchErrorMessage.current = error?.response?.data?.message ?? error?.message ?? error?.response?.data?.code ?? error?.code;
                setTriggerSnackbarOpen(true);

            } finally {
                setLoadingFleetSizeCount(false);
            }

        };

        const getProjectVehicleCount = async () => {

            setLoadingProjectVehicleCount(true);
            const url = new URL('/counters/vehicles/getProjectVehiclesCount', `${FLEET_MANAGING_BASE_URL}`);

            try {

                const response = Axios.get(url.href, { signal: controller.signal });
                const data = (await response).data;

                setProjectVehicleCount(data.count);

            } catch (error) {

                setSeverity("error");

                if (error?.code === "ERR_NETWORK" || error?.code === "ECONNABORTED") {

                    fetchErrorMessage.current = "network error, please check your internet connection";
                    return setTriggerSnackbarOpen(true);

                }

                fetchErrorMessage.current = error?.response?.data?.message ?? error?.message ?? error?.response?.data?.code ?? error?.code;
                setTriggerSnackbarOpen(true);

            } finally {
                setLoadingProjectVehicleCount(false);
            }

        };

        const getVehicleCountTrends = async () => {

            const url = new URL('/counters/vehicles/getVehiclesAnalytics', `${FLEET_MANAGING_BASE_URL}`);
            const xAxisArray = [];
            const specDataArray = [];
            const coAvoidedArray = [];
            const harshBrakingArray = [];
            const overSpeedingArray = [];
            const lateNightTravelCountArray = [];
            const unsafeDistanceTravelArray = [];

            try {

                const response = await Axios.get(url.href, { signal: controller.signal });
                const counterData = response.data;
                setVehicleCountTrends(counterData);

                const macYaxisVal = Math.max(...counterData.countArray);
                setMaxYaxisVal(macYaxisVal);

                const dataArray = counterData.data.reverse();
                for (let i = 0; i < dataArray.length; i++) {

                    const dataBlock = dataArray[i];
                    const coValue = ((Number(dataBlock.totalMileage) * 92.45) / 1000);
                    const harshBrakingVal = dataBlock.harshBrakingCount ?? 0;
                    const overSpeedingCountVal = dataBlock.overSpeedingCount ?? 0;
                    const lateNightTravelCountVal = dataBlock.mileageBeyondEveningCount ?? 0;
                    const unsafeDistanceTravelCountVal = dataBlock.unsafeMiles ?? 0;

                    xAxisArray.push(`${dataBlock.year}-${dataBlock.month}`);
                    specDataArray.push((dataBlock.totalMileage).toFixed(3));
                    coAvoidedArray.push(coValue.toFixed(3));
                    harshBrakingArray.push(harshBrakingVal);
                    overSpeedingArray.push(overSpeedingCountVal);
                    lateNightTravelCountArray.push(lateNightTravelCountVal);
                    unsafeDistanceTravelArray.push(unsafeDistanceTravelCountVal.toFixed(3));

                    setFleetTravelCountTrending({
                        xAxisArray: xAxisArray,
                        specDataArray: specDataArray,
                        coAvoidedArray: coAvoidedArray,
                        harshBrakingArray: harshBrakingArray,
                        overSpeedingArray: overSpeedingArray,
                        lateNightTravelCountArray: lateNightTravelCountArray,
                        unsafeDistanceTravelArray: unsafeDistanceTravelArray,
                    });

                    if ((i + 1) === dataArray.length) {
                        setTimeout(() => {

                            const maxTotalMileage = Math.max(...specDataArray);
                            setMaxFleetMileageValue(maxTotalMileage);

                            const maxCarbonAvoided = Math.max(...coAvoidedArray);
                            setMaxCarbonAvoidedValue(maxCarbonAvoided);

                            const maxHarshBrakingNumber = Math.max(...harshBrakingArray);
                            setMaxHarshBrakingValue(maxHarshBrakingNumber);

                            const maxOverSpeedingNumber = Math.max(...overSpeedingArray);
                            setMaxOverSpeedingValue(maxOverSpeedingNumber);

                            const maxUnsafeDistanceTraveled = Math.max(...unsafeDistanceTravelArray);
                            setMaxUnsafeDistanceTraveledValue(maxUnsafeDistanceTraveled);

                            setShowActionButtons(true);
                            setIsLoadingVehicleCountTrends(false);

                        }, 5000);
                    }

                }

            } catch (error) {

                setSeverity("error");

                if (error?.code === "ERR_NETWORK" || error?.code === "ECONNABORTED") {

                    fetchErrorMessage.current = "network error, please check your internet connection";
                    return setTriggerSnackbarOpen(true);

                }

                setIsLoadingVehicleCountTrendsError(true);
                fetchVehicleCountTrendsErrorMessage.current = error?.response?.data?.message ?? error?.message ?? error?.response?.data?.code ?? error?.code;
                return setTriggerSnackbarOpen(true);

            }

        };

        const getStaffVehicleCount = async () => {

            setLoadingStaffVehicleCount(true);
            const url = new URL('/counters/vehicles/staff-vehicle-count', `${FLEET_MANAGING_BASE_URL}`);

            try {

                const response = Axios.get(url.href, { signal: controller.signal });
                const data = (await response).data;

                setStaffVehicleCount(data.count);

            } catch (error) {

                setSeverity("error");

                if (error?.code === "ERR_NETWORK" || error?.code === "ECONNABORTED") {

                    fetchErrorMessage.current = "network error, please check your internet connection";
                    return setTriggerSnackbarOpen(true);

                }

                fetchErrorMessage.current = error?.response?.data?.message ?? error?.message ?? error?.response?.data?.code ?? error?.code;
                setTriggerSnackbarOpen(true);

            } finally {
                setLoadingStaffVehicleCount(false);
            }

        };

        const getCustomerVehicleCount = async () => {

            setLoadingCustomerVehicleCount(true);
            const url = new URL('/counters/vehicles/customer-vehicle-count', `${FLEET_MANAGING_BASE_URL}`);

            try {

                const response = Axios.get(url.href, { signal: controller.signal });
                const data = (await response).data;

                setCustomerVehicleCount(data.count);

            } catch (error) {

                setSeverity("error");

                if (error?.code === "ERR_NETWORK" || error?.code === "ECONNABORTED") {

                    fetchErrorMessage.current = "network error, please check your internet connection";
                    return setTriggerSnackbarOpen(true);

                }

                fetchErrorMessage.current = error?.response?.data?.message ?? error?.message ?? error?.response?.data?.code ?? error?.code;
                setTriggerSnackbarOpen(true);

            } finally {
                setLoadingCustomerVehicleCount(false);
            }

        };

        const getWahuTogoVehicleCount = async () => {

            setLoadingTogoFleetSizeCount(true);
            const url = new URL('/organizations/wahu-togo/vehicles/getAll', `${FLEET_MANAGING_BASE_URL}`);

            try {

                const response = Axios.get(url.href, { signal: controller.signal });
                const data = (await response).data;

                setTogoFleetSizeCount(data.max_count);

            } catch (error) {

                setSeverity("error");

                if (error?.code === "ERR_NETWORK" || error?.code === "ECONNABORTED") {

                    fetchErrorMessage.current = "network error, please check your internet connection";
                    return setTriggerSnackbarOpen(true);

                }

                fetchErrorMessage.current = error?.response?.data?.message ?? error?.message ?? error?.response?.data?.code ?? error?.code;
                setTriggerSnackbarOpen(true);

            } finally {
                setLoadingTogoFleetSizeCount(false);
            }

        };

        const getReadyVehicleCount = async () => {

            setLoadingVehiclesNotDeployed(true);
            const url = new URL('/counters/vehicles/ready-vehicle-count', `${FLEET_MANAGING_BASE_URL}`);

            try {

                const response = Axios.get(url.href, { signal: controller.signal });
                const data = (await response).data;

                setVehiclesNotDeployed(data.count);

            } catch (error) {

                setSeverity("error");

                if (error?.code === "ERR_NETWORK" || error?.code === "ECONNABORTED") {

                    fetchErrorMessage.current = "network error, please check your internet connection";
                    return setTriggerSnackbarOpen(true);

                }

                fetchErrorMessage.current = error?.response?.data?.message ?? error?.message ?? error?.response?.data?.code ?? error?.code;
                setTriggerSnackbarOpen(true);

            } finally {
                setLoadingVehiclesNotDeployed(false);
            }

        };

        const getVehiclesInRepairCount = async () => {

            setLoadingVehiclesInRepair(false);
            const url = new URL('/counters/vehicles/vehicle-in-repair-count', `${FLEET_MANAGING_BASE_URL}`);

            try {

                const response = Axios.get(url.href, { signal: controller.signal });
                const data = (await response).data;

                isMounted && setVehiclesInRepair(data.count);
                isMounted && setLoadingVehiclesInRepair(false);

            } catch (error) {

                setSeverity("error");

                if (error?.code === "ERR_NETWORK" || error?.code === "ECONNABORTED") {

                    fetchErrorMessage.current = "network error, please check your internet connection";
                    return setTriggerSnackbarOpen(true);

                }

                fetchErrorMessage.current = error?.response?.data?.message ?? error?.message ?? error?.response?.data?.code ?? error?.code;
                setTriggerSnackbarOpen(true);

            } finally {
                setLoadingVehiclesInRepair(false);
            }

        };

        const fetchAnalytics = async () => {

            const url = new URL('/counters/vehicles/getVehicleCount', `${FLEET_MANAGING_BASE_URL}`);

            try {

                const response = Axios.get(url.href);
                const data = (await response).data;

                const monthlyMaxYaxisVal = Math.max(...data.monthlyCountArray);
                setMonthlyMaxYaxisVal(monthlyMaxYaxisVal);

                const weeklyMaxYaxisVal = Math.max(...data.weeklyCountArray);
                setWeeklyMaxYaxisVal(weeklyMaxYaxisVal);

                setAnalyticsData(data);

            } catch (error) {

                setSeverity("error");

                if (error?.code === "ERR_NETWORK" || error?.code === "ECONNABORTED") {

                    fetchErrorMessage.current = "network error, please check your internet connection";
                    return setTriggerSnackbarOpen(true);

                }

                fetchErrorMessage.current = `Code: ${error?.response?.status}, Message: ${error?.response?.data?.message}`;
                setTriggerSnackbarOpen(true);

            } finally {
                setIsLoadingAnalytics(false);
            }

        };

        isMounted && getDriverCount();
        isMounted && getActiveDriverCount();
        isMounted && getTestDriversCount();
        isMounted && getStaffDriversCount();
        isMounted && checkChangeLogDismissed();
        isMounted && getVehicleCountTrends();
        isMounted && getVehicleCount();
        isMounted && getActiveVehicleCount();
        isMounted && getAssignedVehiclesCount();
        isMounted && getFleetSizeCount();
        isMounted && getProjectVehicleCount();
        isMounted && getStaffVehicleCount();
        isMounted && getCustomerVehicleCount();
        isMounted && getWahuTogoVehicleCount();
        isMounted && getReadyVehicleCount();
        isMounted && getVehiclesInRepairCount();
        isMounted && fetchAnalytics();

        return () => {

            isMounted = false;
            controller.abort();

            setActiveReleaseNotes([]);

            setDriverCount(0);
            setActiveDriverCount(0);
            setTestDriversCount(0);
            setStaffDriversCount(0);
            setVehicleCount(0);
            setActiveVehicleCount(0);
            setAssignedVehiclesCount(0);
            setFleetSizeCount(0);
            setProjectVehicleCount(0);
            setStaffVehicleCount(0);
            setCustomerVehicleCount(0);
            setTogoFleetSizeCount(0);
            setVehiclesNotDeployed(0);

            setVehicleCountTrends(null);
            setAnalyticsData(null);

        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={triggerSnackbarOpen}
                autoHideDuration={8000}
                onClose={handleSnackbarClose}
                ContentProps={{
                    'aria-describedby': 'message-id',
                }}
                action={[
                    <Button key="undo" color="primary" size="small" onClick={handleSnackbarClose}>
                        UNDO
                    </Button>,
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        className={classes.close}
                        onClick={handleSnackbarClose}
                    >
                        <CloseIcon />
                    </IconButton>
                ]}
            >
                <Alert
                    elevation={6}
                    variant="filled"
                    severity={severity}
                    onClose={handleSnackbarClose}
                >
                    <span id="message-id">
                        {fetchErrorMessage.current}
                    </span>
                </Alert>
            </Snackbar>
            <SimpleModal
                show={showChangeLog}
                close={() => {

                    setStorageItem("openedChangeLog", true);
                    setShowChangeLog(false);

                }}
                size="lg"
                modal_tittle={`Change Log`}
                body={
                    <ShowChangeLogModal
                        changeLogObject={activeReleaseNotes}
                    />
                }
            />
            <SimpleModal
                show={showTotalBikePop}
                close={() =>
                    setShowTotalBikePop(false)
                }
                size="lg"
                modal_tittle={`Vehicles Manufactured`}
                body={
                    <LineChart
                        categories={vehicleCountTrends}
                        data={vehicleCountTrends}
                    />
                }
            />
            <SimpleModal
                show={fleetTravel}
                close={() => setFleetTravel(false)}
                size="lg"
                modal_tittle={`Fleet Travel`}
                body={
                    <DynamicApexChart
                        categories={fleetTravelCountTrending?.xAxisArray}
                        data={fleetTravelCountTrending?.specDataArray}
                        chartTitle={"Distance Traveled by Fleet"}
                        exportFilename={"Wahu-Fleet-Travel"}
                        yAxisName={"Mileage in km"}
                        xAxisName={"Month"}
                        yAxisMinVal={0}
                        yAxisMaxVal={200000}
                    />
                }

            />
            <SimpleModal
                show={fleetCoAvoided}
                close={() => setFleetCoAvoided(false)}
                size="lg"
                modal_tittle={`CO2 Avoided by Fleet`}
                body={
                    <DynamicApexChart
                        categories={fleetTravelCountTrending?.xAxisArray}
                        data={fleetTravelCountTrending?.coAvoidedArray}
                        chartTitle={"CO2 Avoided by Fleet"}
                        exportFilename={"Wahu-Fleet-CO2-Avoided"}
                        yAxisName={"CO2 Avoided in kg"}
                        xAxisName={"Month"}
                        yAxisMinVal={0}
                        yAxisMaxVal={20000}
                    />
                }

            />
            <SimpleModal
                show={showTotalHarshBrakingEvents}
                close={() => setShowTotalHarshBrakingEvents(false)}
                size="lg"
                modal_tittle={`Harsh Braking Events`}
                body={
                    <DynamicApexChart
                        categories={fleetTravelCountTrending?.xAxisArray}
                        data={fleetTravelCountTrending?.harshBrakingArray}
                        chartTitle={"Harsh Braking Events across Fleet"}
                        exportFilename={"Wahu-Fleet-Harsh-Braking"}
                        yAxisName={"Harsh Braking Event Count"}
                        xAxisName={"Month"}
                        yAxisMinVal={0}
                        yAxisMaxVal={500}
                    />
                }
            />
            <SimpleModal
                show={showTotalOverSpeedingEvents}
                close={() => setShowTotalOverSpeedingEvents(false)}
                size="lg"
                modal_tittle={`Over-speeding Events`}
                body={
                    <DynamicApexChart
                        categories={fleetTravelCountTrending?.xAxisArray}
                        data={fleetTravelCountTrending?.overSpeedingArray}
                        chartTitle={"Over-speeding Events across Fleet"}
                        exportFilename={"Wahu-Fleet-Over-Speeding"}
                        yAxisName={"Over-speeding Event Count"}
                        xAxisName={"Month"}
                        yAxisMinVal={0}
                        yAxisMaxVal={3000}
                    />
                }
            />
            <SimpleModal
                show={showTotalKMRiddenPast10pm}
                close={() => setShowTotalKMRiddenPast10pm(false)}
                size="lg"
                modal_tittle={`Ridding Past 10PM`}
                body={
                    <DynamicApexChart
                        categories={fleetTravelCountTrending?.xAxisArray}
                        data={fleetTravelCountTrending?.lateNightTravelCountArray}
                        chartTitle={"Ridding Past 10PM Events across Fleet"}
                        exportFilename={"Wahu-Fleet-Ridding-Past-10PM"}
                        yAxisName={"Ridding Past 10PM Event Count"}
                        xAxisName={"Month"}
                        yAxisMinVal={0}
                        yAxisMaxVal={500}
                    />
                }
            />
            <SimpleModal
                show={showAllRidersGender}
                close={() => setShowAllRidersGender(false)}
                size="md"
                modal_tittle={`Total Number of Riders Onboarded`}
                body={
                    <BarChart />
                }

            />
            <SimpleModal
                show={showActiveRidersGender}
                close={() => setShowActiveRidersGender(false)}
                size="md"
                modal_tittle={`Total Number of Riders (Active)`}
                body={
                    <BarChart />
                }

            />
            <SimpleModal
                show={showInActiveRidersGender}
                close={() => setShowInActiveRidersGender(false)}
                size="md"
                modal_tittle={`Total Number of Riders (Deliquent)`}
                body={
                    <BarChart />
                }

            />
            <SimpleModal
                show={showChurnedRidersGender}
                close={() => setShowChurnedRidersGender(false)}
                size="md"
                modal_tittle={`Total Number of Riders (Churned)`}
                body={
                    <BarChart />
                }

            />
            <SimpleModal
                show={showRiderSafetyInfo}
                close={() => setShowRiderSafetyInfo(false)}
                size="md"
                modal_tittle={`Rider Safety Score (%)`}
                body={
                    <RiderSafetyInfo />
                }
            />

            {userRole.includes("admin") && <div className='row'>

                <div className="col-sm-12 col-md-12 col-lg-12 col-xxl-12">
                    <div className="card card-custom no-border-shadow rounded-xl">
                        <div className="card-body pt-2 pb-5 mt-n3">
                            <div className='mt-8'>
                                <div className="card card-custom card-stretch gutter-b rounded-xl">
                                    <div className="card-header border-0 pt-5">
                                        <h3 className="card-title align-items-start flex-column">
                                            <span className="card-label font-weight-bolder text-dark">
                                                eBikes Produced
                                            </span>
                                            <span className="text-muted mt-3 font-weight-bold font-size-sm">
                                                Monthly - Weekly Volumes
                                            </span>
                                        </h3>
                                        <div className="card-toolbar">
                                            <Tab.Container defaultActiveKey={key}>
                                                <Nav
                                                    as="ul"
                                                    onSelect={(_key) => setKey(_key)}
                                                    className="nav nav-pills nav-pills-sm nav-dark-75"
                                                >
                                                    <Nav.Item className="nav-item" as="li">
                                                        <Nav.Link
                                                            eventKey="Monthly"
                                                            className={`nav-link py-2 px-4 ${key === "Monthly" ? "active" : ""}`}
                                                        >
                                                            Monthly
                                                        </Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item className="nav-item" as="li">
                                                        <Nav.Link
                                                            eventKey="Weekly"
                                                            className={`nav-link py-2 px-4 ${key === "Weekly" ? "active" : ""}`}
                                                        >
                                                            Weekly
                                                        </Nav.Link>
                                                    </Nav.Item>
                                                </Nav>
                                            </Tab.Container>
                                        </div>
                                    </div>
                                    <div className="card-body pt-2 pb-5 mt-n3">
                                        <div className="bg-light-primary rounded-xl tab-content mt-5" id="myTabTables12">
                                            <div className={`tab-pane fade ${key === "Monthly" ? "show active" : ""}`}>
                                                {key === "Monthly" && (
                                                    <>
                                                        <div className="px-6 py-8 rounded-xl">
                                                            {isLoadingAnalytics && <BasicLoader size={"md"} />}
                                                            {!isLoadingAnalytics && analyticsData && <DynamicApexChart
                                                                categories={analyticsData?.monthlyTimeSeriesArray.reverse()}
                                                                data={analyticsData?.monthlyCountArray.reverse()}
                                                                exportFilename={"Vehicles Manufactured(Exported from Wahu OS)"}
                                                                chartTitle={"Vehicles Manufactured"}
                                                                xAxisName={"MONTH"}
                                                                yAxisName={"# of eBikes"}
                                                                yAxisMinVal={0}
                                                                yAxisMaxVal={monthlyMaxYaxisVal}
                                                                horizontalBar={false}
                                                                chartHeight={300}
                                                                labelFontWeight={"bold"}
                                                            />}
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                            <div className={`tab-pane fade ${key === "Weekly" ? "show active" : ""}`}>
                                                {key === "Weekly" && (
                                                    <>
                                                        <div className="px-6 py-8 rounded-xl">
                                                            {isLoadingAnalytics && <BasicLoader size={"md"} />}
                                                            {!isLoadingAnalytics && analyticsData && <DynamicApexChart
                                                                categories={analyticsData?.weeklyTimeSeriesArray.reverse()}
                                                                data={analyticsData?.weeklyCountArray.reverse()}
                                                                exportFilename={"Vehicles Manufactured(Exported from Wahu OS)"}
                                                                chartTitle={"Vehicles Manufactured"}
                                                                xAxisName={"WEEK"}
                                                                yAxisName={"# of eBikes"}
                                                                yAxisMinVal={0}
                                                                yAxisMaxVal={weeklyMaxYaxisVal}
                                                                horizontalBar={false}
                                                                chartHeight={300}
                                                                labelFontSize={"9px"}
                                                                labelFontWeight={"bold"}
                                                            />}
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='mt-5'>
                                <DashboardTileWidget
                                    title="Fleet Breakdown"
                                    number={loadingVehicleCount ? <BasicLoader size={"sm"} /> : vehicleCount}
                                    className="card-stretch gutter-b"
                                    iconSVG={"/media/svg/icons/General/ManufacturedBikes.svg"}
                                    callToActions={[{
                                        identifier: "Trends",
                                        name: "Month-on-Month",
                                        icon: <TrendingUpTwoToneIcon />
                                    }]}
                                    showTrendActions={false}
                                    clickTrendsEvent={() => {
                                        setShowTotalBikePop(true);
                                    }}
                                    infoIconColor='info'
                                    toolTipTittle={"Manufactured Vehicles"}
                                    showToolTip={true}
                                    tooltipMessage={"Total number of vehicles manufactured to date"}
                                    IconFontSize={"small"}
                                    showActionButtons={showActionButtons}
                                    customElement={<RenderVehicleBreakDown
                                        activeVehicles={loadingActiveVehicleCount ? <BasicLoader size={"sm"} /> : activeVehicleCount}
                                        inactiveVehicles={loadingVehicleCount ? <BasicLoader size={"sm"} /> : loadingActiveVehicleCount ? <BasicLoader size={"sm"} /> : vehicleCount - activeVehicleCount}
                                        fleetSize={loadingFleetSizeCount ? <BasicLoader size={"sm"} /> : fleetSizeCount}
                                        projectVehicles={loadingProjectVehicleCount ? <BasicLoader size={"sm"} /> : projectVehicleCount}
                                        customerVehicleCount={loadingCustomerVehicleCount ? <BasicLoader size={"sm"} /> : customerVehicleCount}
                                        staffVehicleCount={loadingStaffVehicleCount ? <BasicLoader size={"sm"} /> : staffVehicleCount}
                                        assignedVehiclesCount={loadingAssignedVehiclesCount ? <BasicLoader size={"sm"} /> : assignedVehiclesCount}
                                        unAssignedVehiclesCount={loadingVehicleCount ? <BasicLoader size={"sm"} /> : loadingAssignedVehiclesCount ? <BasicLoader size={"sm"} /> : vehicleCount - assignedVehiclesCount}
                                        vehiclesInRepair={loadingVehiclesInRepair ? <BasicLoader size={"sm"} /> : vehiclesInRepair}
                                        vehiclesBrokenDown={loadingVehicleCount ? <BasicLoader size={"sm"} /> : loadingActiveVehicleCount ? <BasicLoader size={"sm"} /> : loadingVehiclesInRepair ? <BasicLoader size={"sm"} /> : ((vehicleCount - activeVehicleCount) - (vehiclesInRepair + vehiclesNotDeployed))}
                                        vehiclesNotDeployed={loadingVehiclesNotDeployed ? <BasicLoader size={"sm"} /> : vehiclesNotDeployed}
                                        togoFleetSize={loadingTogoFleetSizeCount ? <BasicLoader size={"sm"} /> : togoFleetSizeCount}
                                    />}
                                    widgetHeight='100%'
                                    subTitle="Break down of vehicles produced by user base"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-sm-12 col-md-12 col-lg-12 col-xxl-12 mt-5">
                    <div className="card card-custom no-border-shadow rounded-xl">
                        <div className="card-body pt-2 pb-5 mt-n3">
                            <div className='row mt-5'>
                                <div className="col bg-light-primary p-2 rounded-xl">
                                    <span className='bold'>
                                        Invoiced Amount(GHS):
                                    </span>
                                    <br />
                                    <span className='bold' style={{
                                        fontSize: "20px"
                                    }}>
                                        1,265,035.00
                                    </span>
                                </div>
                                <div className="col">
                                    <div className="col bg-light-primary p-2 rounded-xl">
                                        <span className='bold'>
                                            Amount Received(GHS):
                                        </span>
                                        <br />
                                        <span className='bold' style={{
                                            fontSize: "20px"
                                        }}>
                                            1,220,798.00
                                        </span>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="col bg-light-success p-2 rounded-xl">
                                        <span className='bold'>
                                            Collections Rate:
                                        </span>
                                        <br />
                                        <span className='bold' style={{
                                            fontSize: "20px"
                                        }}>
                                            {`${((collectedAmount / invoicedAmount) * 100).toFixed(2)}%`}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>}

            {/* <div className='row mt-5'>
                <div className="col-sm-12 col-md-12 col-lg-12 col-xxl-12">
                    <DashboardTileWidget
                        title="Driver Accounts"
                        number={driverCount}
                        className="card-stretch gutter-b"
                        iconSVG={"/media/svg/icons/Communication/Group.svg"}
                        infoIconColor='info'
                        toolTipTittle={"Driver Accounts"}
                        showToolTip={true}
                        tooltipMessage={"Total number of drivers acquired to date"}
                        IconFontSize={"small"}
                        customElement={<CustomDriverRender
                            pipelineAccounts={driverCount - (testDriversCount + staffDriversCount + assignedVehiclesCount)}
                            activeDrivers={activeVehicleCount}
                            testRiders={testDriversCount}
                            staffRiders={staffDriversCount}
                            churnedDrivers={20}
                            delinquentDrivers={4}
                        />}
                        widgetHeight='100%'
                    />
                </div>
            </div> */}

            <div className='row mt-5'>
                <div className="col-lg-12 col-xxl-12 col-md-12 col-sm-12">
                    <DashboardTileWidgetAlt
                        title="Total Distance Traveled"
                        subTitle="Realtime data from fleet"
                        number={
                            <PerfectScrollbar
                                options={perfectScrollbarOptions}
                                className="scroll pr-7 mr-n7"
                                style={{
                                    maxHeight: "450px",
                                    position: "relative"
                                }}
                            >
                                <div className="card card-custom no-border-shadow rounded-xl">
                                    <div className="card-body">
                                        <div className="bg-light-primary rounded-xl tab-content py-3 px-3">
                                            {isLoadingVehicleCountTrends && <BasicLoader size={"sm"} />}
                                            {!isLoadingVehicleCountTrends && <DynamicApexChart
                                                xAxisName={"MONTH"}
                                                yAxisMinVal={0}
                                                horizontalBar={false}
                                                chartHeight={300}
                                                labelFontWeight={"bold"}
                                                categories={fleetTravelCountTrending?.xAxisArray}
                                                data={fleetTravelCountTrending?.specDataArray}
                                                chartTitle={"Distance Traveled by Fleet"}
                                                exportFilename={"Wahu-Fleet-Travel (Exported from Wahu OS)"}
                                                yAxisName={"Mileage in km"}
                                                yAxisMaxVal={maxFleetMileageValue}
                                                chartType={"line"}
                                            />}
                                        </div>
                                    </div>
                                </div>
                                <div className="pl-10 pr-10">
                                    {isLoadingVehicleCountTrends ? (
                                        isLoadingVehicleCountTrendsError ?
                                            (<>
                                                <div className='centerMan mb-1'>
                                                    <span className='label label-lg label-light-danger label-inline label-rounded p-8'>
                                                        <span>
                                                            <i className="fas fa-exclamation-triangle"></i>
                                                            {" "}
                                                            could not load mileage metrics:
                                                            {" "}
                                                            {fetchVehicleCountTrendsErrorMessage.current}
                                                        </span>
                                                    </span>
                                                </div>
                                                <div className="timeline timeline-justified timeline-4">
                                                    <div className="timeline-bar"></div>
                                                    <div className="timeline-items">
                                                        <div className="timeline-item">
                                                            <div className="timeline-badge">
                                                                <Skeleton
                                                                    sx={(theme) => ({
                                                                        backgroundColor: theme.palette.error.light,
                                                                        color: '#fff',
                                                                        p: '0.35rem',
                                                                        textAlign: 'center'
                                                                    })}
                                                                    variant="circular"
                                                                    width={20}
                                                                    height={20}
                                                                />
                                                            </div>
                                                            <div className="timeline-label">
                                                                <Skeleton
                                                                    sx={(theme) => ({
                                                                        backgroundColor: theme.palette.error.light,
                                                                        borderRadius: '0.35rem',
                                                                        color: '#fff',
                                                                        p: '0.35rem',
                                                                        textAlign: 'center'
                                                                    })}
                                                                    variant="rectangular"
                                                                    height={20}
                                                                    width={50}
                                                                />
                                                            </div>
                                                            <div className="timeline-content">
                                                                <Skeleton
                                                                    sx={(theme) => ({
                                                                        backgroundColor: theme.palette.error.light,
                                                                        borderRadius: '0.35rem',
                                                                        color: '#fff',
                                                                        p: '0.35rem',
                                                                        textAlign: 'center'
                                                                    })}
                                                                    variant="rectangular"
                                                                    height={20}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="timeline-item">
                                                            <div className="timeline-badge">
                                                                <Skeleton
                                                                    sx={(theme) => ({
                                                                        backgroundColor: theme.palette.error.light,
                                                                        color: '#fff',
                                                                        p: '0.35rem',
                                                                        textAlign: 'center'
                                                                    })}
                                                                    variant="circular"
                                                                    width={20}
                                                                    height={20}
                                                                />
                                                            </div>
                                                            <div className="timeline-label">
                                                                <Skeleton
                                                                    sx={(theme) => ({
                                                                        backgroundColor: theme.palette.error.light,
                                                                        borderRadius: '0.35rem',
                                                                        color: '#fff',
                                                                        p: '0.35rem',
                                                                        textAlign: 'center'
                                                                    })}
                                                                    variant="rectangular"
                                                                    height={20}
                                                                    width={50}
                                                                />
                                                            </div>
                                                            <div className="timeline-content">
                                                                <Skeleton
                                                                    sx={(theme) => ({
                                                                        backgroundColor: theme.palette.error.light,
                                                                        borderRadius: '0.35rem',
                                                                        color: '#fff',
                                                                        p: '0.35rem',
                                                                        textAlign: 'center'
                                                                    })}
                                                                    variant="rectangular"
                                                                    height={20}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>) : (<>
                                                <div className="timeline timeline-justified timeline-4">
                                                    <div className="timeline-bar"></div>
                                                    <div className="timeline-items">
                                                        <div className="timeline-item">
                                                            <div className="timeline-badge">
                                                                <Skeleton
                                                                    variant="circular"
                                                                    width={20}
                                                                    height={20}
                                                                    sx={() => ({
                                                                        color: '#fff',
                                                                        p: '0.35rem',
                                                                        textAlign: 'center'
                                                                    })}
                                                                />
                                                            </div>
                                                            <div className="timeline-label">
                                                                <Skeleton
                                                                    variant="rectangular"
                                                                    height={20}
                                                                    width={50}
                                                                    sx={() => ({
                                                                        color: '#fff',
                                                                        p: '0.35rem',
                                                                        borderRadius: '0.35rem',
                                                                        textAlign: 'center'
                                                                    })}
                                                                />
                                                            </div>
                                                            <div className="timeline-content">
                                                                <Skeleton
                                                                    variant="rectangular"
                                                                    height={20}
                                                                    sx={() => ({
                                                                        color: '#fff',
                                                                        p: '0.35rem',
                                                                        borderRadius: '0.35rem',
                                                                        textAlign: 'center'
                                                                    })}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="timeline-item">
                                                            <div className="timeline-badge">
                                                                <Skeleton
                                                                    variant="circular"
                                                                    width={20}
                                                                    height={20}
                                                                    sx={() => ({
                                                                        color: '#fff',
                                                                        p: '0.35rem',
                                                                        textAlign: 'center'
                                                                    })}
                                                                />
                                                            </div>
                                                            <div className="timeline-label">
                                                                <Skeleton
                                                                    variant="rectangular"
                                                                    height={20}
                                                                    width={50}
                                                                    sx={() => ({
                                                                        color: '#fff',
                                                                        p: '0.35rem',
                                                                        borderRadius: '0.35rem',
                                                                        textAlign: 'center'
                                                                    })}
                                                                />
                                                            </div>
                                                            <div className="timeline-content">
                                                                <Skeleton
                                                                    variant="rectangular"
                                                                    height={20}
                                                                    sx={() => ({
                                                                        color: '#fff',
                                                                        p: '0.35rem',
                                                                        borderRadius: '0.35rem',
                                                                        textAlign: 'center'
                                                                    })}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>)
                                    ) : (
                                        <>
                                            <div className="timeline timeline-justified timeline-4">
                                                <div className="timeline-bar"></div>
                                                {vehicleCountTrends?.data?.map((item, index) =>
                                                    <div className="timeline-items" key={index}>
                                                        <div className="timeline-item">
                                                            <div className="timeline-badge">
                                                                <RouteTwoToneIcon
                                                                    fontSize='large'
                                                                    color='success'
                                                                />
                                                            </div>
                                                            <div className="timeline-label">
                                                                <div className='py-2 px-2 bg-light-primary rounded-xl'>
                                                                    <span>
                                                                        <DateRangeTwoToneIcon
                                                                            color='success'
                                                                        />
                                                                        {" "}
                                                                        <span className='font-size-md'>
                                                                            {`${(item.month).toUpperCase()} ${item.year}`}
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="timeline-content">
                                                                {`${item.totalMileage} km`}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </>
                                    )}
                                </div>
                            </PerfectScrollbar>
                        }
                        className={"card-stretch gutter-b"}
                        callToActionsState={false}
                        widgetHeight='100%'
                    />
                </div>
                <div className="col-lg-12 col-xxl-12 col-md-12 col-sm-12 mt-5">
                    <DashboardTileWidgetAlt
                        title="CO2 Avoided"
                        subTitle="Powered by calculations provided by the Klik Foundation"
                        number={
                            <PerfectScrollbar
                                options={perfectScrollbarOptions}
                                className="scroll pr-7 mr-n7"
                                style={{
                                    maxHeight: "450px",
                                    position: "relative"
                                }}
                            >
                                <div className="card card-custom no-border-shadow rounded-xl">
                                    <div className="card-body">
                                        <div className="bg-light-primary rounded-xl tab-content py-3 px-3">
                                            {isLoadingVehicleCountTrends && <BasicLoader size={"sm"} />}
                                            {!isLoadingVehicleCountTrends && <DynamicApexChart
                                                xAxisName={"MONTH"}
                                                yAxisMinVal={0}
                                                horizontalBar={false}
                                                chartHeight={300}
                                                labelFontWeight={"bold"}
                                                categories={fleetTravelCountTrending?.xAxisArray}
                                                data={fleetTravelCountTrending?.coAvoidedArray}
                                                chartTitle={"Distance Traveled by Fleet"}
                                                exportFilename={"Wahu-Fleet-Travel (Exported from Wahu OS)"}
                                                yAxisName={"Mileage in km"}
                                                yAxisMaxVal={maxCarbonAvoidedValue}
                                                chartType={"line"}
                                            />}
                                        </div>
                                    </div>
                                </div>
                                <div className="pl-10 pr-10">
                                    {isLoadingVehicleCountTrends ? (
                                        isLoadingVehicleCountTrendsError ? (
                                            <>
                                                <div className='centerMan mb-1'>
                                                    <span className='label label-lg label-light-danger label-inline label-rounded p-8'>
                                                        <span>
                                                            <i className="fas fa-exclamation-triangle"></i>
                                                            {" "}
                                                            could not load carbon metrics:
                                                            {" "}
                                                            {fetchVehicleCountTrendsErrorMessage.current}
                                                        </span>
                                                    </span>
                                                </div>
                                                <div className="timeline timeline-justified timeline-4">
                                                    <div className="timeline-bar"></div>
                                                    <div className="timeline-items">
                                                        <div className="timeline-item">
                                                            <div className="timeline-badge">
                                                                <Skeleton
                                                                    sx={(theme) => ({
                                                                        backgroundColor: theme.palette.error.light,
                                                                        color: '#fff',
                                                                        p: '0.35rem',
                                                                        textAlign: 'center'
                                                                    })}
                                                                    variant="circular"
                                                                    width={20}
                                                                    height={20}
                                                                />
                                                            </div>
                                                            <div className="timeline-label">
                                                                <Skeleton
                                                                    sx={(theme) => ({
                                                                        backgroundColor: theme.palette.error.light,
                                                                        borderRadius: '0.35rem',
                                                                        color: '#fff',
                                                                        p: '0.35rem',
                                                                        textAlign: 'center'
                                                                    })}
                                                                    variant="rectangular"
                                                                    height={20}
                                                                    width={50}
                                                                />
                                                            </div>
                                                            <div className="timeline-content">
                                                                <Skeleton
                                                                    sx={(theme) => ({
                                                                        backgroundColor: theme.palette.error.light,
                                                                        borderRadius: '0.35rem',
                                                                        color: '#fff',
                                                                        p: '0.35rem',
                                                                        textAlign: 'center'
                                                                    })}
                                                                    variant="rectangular"
                                                                    height={20}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="timeline-item">
                                                            <div className="timeline-badge">
                                                                <Skeleton
                                                                    sx={(theme) => ({
                                                                        backgroundColor: theme.palette.error.light,
                                                                        color: '#fff',
                                                                        p: '0.35rem',
                                                                        textAlign: 'center'
                                                                    })}
                                                                    variant="circular"
                                                                    width={20}
                                                                    height={20}
                                                                />
                                                            </div>
                                                            <div className="timeline-label">
                                                                <Skeleton
                                                                    sx={(theme) => ({
                                                                        backgroundColor: theme.palette.error.light,
                                                                        borderRadius: '0.35rem',
                                                                        color: '#fff',
                                                                        p: '0.35rem',
                                                                        textAlign: 'center'
                                                                    })}
                                                                    variant="rectangular"
                                                                    height={20}
                                                                    width={50}
                                                                />
                                                            </div>
                                                            <div className="timeline-content">
                                                                <Skeleton
                                                                    sx={(theme) => ({
                                                                        backgroundColor: theme.palette.error.light,
                                                                        borderRadius: '0.35rem',
                                                                        color: '#fff',
                                                                        p: '0.35rem',
                                                                        textAlign: 'center'
                                                                    })}
                                                                    variant="rectangular"
                                                                    height={20}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <div className="timeline timeline-justified timeline-4">
                                                    <div className="timeline-bar"></div>
                                                    <div className="timeline-items">
                                                        <div className="timeline-item">
                                                            <div className="timeline-badge">
                                                                <Skeleton
                                                                    variant="circular"
                                                                    width={20}
                                                                    height={20}
                                                                    sx={() => ({
                                                                        color: '#fff',
                                                                        p: '0.35rem',
                                                                        textAlign: 'center'
                                                                    })}
                                                                />
                                                            </div>
                                                            <div className="timeline-label">
                                                                <Skeleton
                                                                    variant="rectangular"
                                                                    height={20}
                                                                    width={50}
                                                                    sx={() => ({
                                                                        color: '#fff',
                                                                        p: '0.35rem',
                                                                        borderRadius: '0.35rem',
                                                                        textAlign: 'center'
                                                                    })}
                                                                />
                                                            </div>
                                                            <div className="timeline-content">
                                                                <Skeleton
                                                                    variant="rectangular"
                                                                    height={20}
                                                                    sx={() => ({
                                                                        color: '#fff',
                                                                        p: '0.35rem',
                                                                        borderRadius: '0.35rem',
                                                                        textAlign: 'center'
                                                                    })}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="timeline-item">
                                                            <div className="timeline-badge">
                                                                <Skeleton
                                                                    variant="circular"
                                                                    width={20}
                                                                    height={20}
                                                                    sx={() => ({
                                                                        color: '#fff',
                                                                        p: '0.35rem',
                                                                        textAlign: 'center'
                                                                    })}
                                                                />
                                                            </div>
                                                            <div className="timeline-label">
                                                                <Skeleton
                                                                    variant="rectangular"
                                                                    height={20}
                                                                    width={50}
                                                                    sx={() => ({
                                                                        color: '#fff',
                                                                        p: '0.35rem',
                                                                        borderRadius: '0.35rem',
                                                                        textAlign: 'center'
                                                                    })}
                                                                />
                                                            </div>
                                                            <div className="timeline-content">
                                                                <Skeleton
                                                                    variant="rectangular"
                                                                    height={20}
                                                                    sx={() => ({
                                                                        color: '#fff',
                                                                        p: '0.35rem',
                                                                        borderRadius: '0.35rem',
                                                                        textAlign: 'center'
                                                                    })}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    ) : (
                                        <>
                                            <div className="timeline timeline-justified timeline-4">
                                                <div className="timeline-bar"></div>
                                                {vehicleCountTrends?.data?.map((item, index) =>
                                                    <div className="timeline-items" key={index}>
                                                        <div className="timeline-item">
                                                            <div className="timeline-badge">
                                                                <Co2TwoToneIcon
                                                                    fontSize='large'
                                                                    color='success'
                                                                />
                                                            </div>
                                                            <div className="timeline-label">
                                                                <div className='py-2 px-5 bg-light-primary rounded-xl'>
                                                                    <span>
                                                                        <DateRangeTwoToneIcon
                                                                            color='success'
                                                                        />
                                                                        {" "}
                                                                        <span className='font-size-md'>
                                                                            {`${(item.month).toUpperCase()} ${item.year}`}
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="timeline-content">
                                                                {`${((Number(item.totalMileage) * 92.45) / 1000).toFixed(3)} kg`}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </>
                                    )}
                                </div>
                            </PerfectScrollbar>
                        }
                        className={"card-stretch gutter-b"}
                        callToActionsState={false}
                        widgetHeight='100%'
                    />
                </div>
            </div>

            <div className='row mt-5'>
                <div className="col-lg-12 col-xxl-12 col-md-12 col-sm-12">
                    <DashboardTileWidgetAlt
                        title="Harsh Braking Events across fleet"
                        number={
                            <PerfectScrollbar
                                options={perfectScrollbarOptions}
                                className="scroll pr-7 mr-n7"
                                style={{
                                    maxHeight: "450px",
                                    position: "relative"
                                }}
                            >
                                <div className="card card-custom no-border-shadow rounded-xl">
                                    <div className="card-body">
                                        <div className="bg-light-primary rounded-xl tab-content py-3 px-3">
                                            {isLoadingVehicleCountTrends && <BasicLoader size={"sm"} />}
                                            {!isLoadingVehicleCountTrends && <DynamicApexChart
                                                xAxisName={"MONTH"}
                                                yAxisMinVal={0}
                                                horizontalBar={false}
                                                chartHeight={300}
                                                labelFontWeight={"bold"}
                                                categories={fleetTravelCountTrending?.xAxisArray}
                                                data={fleetTravelCountTrending?.harshBrakingArray}
                                                chartTitle={"Harsh Braking Events across Fleet"}
                                                exportFilename={"Harsh-Braking-Events (Exported from Wahu OS)"}
                                                yAxisName={"# of Harsh Braking Events"}
                                                yAxisMaxVal={maxHarshBrakingValue}
                                                chartType={"line"}
                                            />}
                                        </div>
                                    </div>
                                </div>
                                <div className="pl-10 pr-10">
                                    {isLoadingVehicleCountTrends ? (
                                        isLoadingVehicleCountTrendsError ? (
                                            <>
                                                <div className='centerMan mb-1'>
                                                    <span className='label label-lg label-light-danger label-inline label-rounded p-8'>
                                                        <span>
                                                            <i className="fas fa-exclamation-triangle"></i>
                                                            {" "}
                                                            could not load harsh braking metrics:
                                                            {" "}
                                                            {fetchVehicleCountTrendsErrorMessage.current}
                                                        </span>
                                                    </span>
                                                </div>
                                                <div className="timeline timeline-justified timeline-4">
                                                    <div className="timeline-bar"></div>
                                                    <div className="timeline-items">
                                                        <div className="timeline-item">
                                                            <div className="timeline-badge">
                                                                <Skeleton
                                                                    sx={(theme) => ({
                                                                        backgroundColor: theme.palette.error.light,
                                                                        color: '#fff',
                                                                        p: '0.35rem',
                                                                        textAlign: 'center'
                                                                    })}
                                                                    variant="circular"
                                                                    width={20}
                                                                    height={20}
                                                                />
                                                            </div>
                                                            <div className="timeline-label">
                                                                <Skeleton
                                                                    sx={(theme) => ({
                                                                        backgroundColor: theme.palette.error.light,
                                                                        borderRadius: '0.35rem',
                                                                        color: '#fff',
                                                                        p: '0.35rem',
                                                                        textAlign: 'center'
                                                                    })}
                                                                    variant="rectangular"
                                                                    height={20}
                                                                    width={50}
                                                                />
                                                            </div>
                                                            <div className="timeline-content">
                                                                <Skeleton
                                                                    sx={(theme) => ({
                                                                        backgroundColor: theme.palette.error.light,
                                                                        borderRadius: '0.35rem',
                                                                        color: '#fff',
                                                                        p: '0.35rem',
                                                                        textAlign: 'center'
                                                                    })}
                                                                    variant="rectangular"
                                                                    height={20}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="timeline-item">
                                                            <div className="timeline-badge">
                                                                <Skeleton
                                                                    sx={(theme) => ({
                                                                        backgroundColor: theme.palette.error.light,
                                                                        color: '#fff',
                                                                        p: '0.35rem',
                                                                        textAlign: 'center'
                                                                    })}
                                                                    variant="circular"
                                                                    width={20}
                                                                    height={20}
                                                                />
                                                            </div>
                                                            <div className="timeline-label">
                                                                <Skeleton
                                                                    sx={(theme) => ({
                                                                        backgroundColor: theme.palette.error.light,
                                                                        borderRadius: '0.35rem',
                                                                        color: '#fff',
                                                                        p: '0.35rem',
                                                                        textAlign: 'center'
                                                                    })}
                                                                    variant="rectangular"
                                                                    height={20}
                                                                    width={50}
                                                                />
                                                            </div>
                                                            <div className="timeline-content">
                                                                <Skeleton
                                                                    sx={(theme) => ({
                                                                        backgroundColor: theme.palette.error.light,
                                                                        borderRadius: '0.35rem',
                                                                        color: '#fff',
                                                                        p: '0.35rem',
                                                                        textAlign: 'center'
                                                                    })}
                                                                    variant="rectangular"
                                                                    height={20}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <div className="timeline timeline-justified timeline-4">
                                                    <div className="timeline-bar"></div>
                                                    <div className="timeline-items">
                                                        <div className="timeline-item">
                                                            <div className="timeline-badge">
                                                                <Skeleton
                                                                    variant="circular"
                                                                    width={20}
                                                                    height={20}
                                                                    sx={() => ({
                                                                        color: '#fff',
                                                                        p: '0.35rem',
                                                                        textAlign: 'center'
                                                                    })}
                                                                />
                                                            </div>
                                                            <div className="timeline-label">
                                                                <Skeleton
                                                                    variant="rectangular"
                                                                    height={20}
                                                                    width={50}
                                                                    sx={() => ({
                                                                        color: '#fff',
                                                                        p: '0.35rem',
                                                                        borderRadius: '0.35rem',
                                                                        textAlign: 'center'
                                                                    })}
                                                                />
                                                            </div>
                                                            <div className="timeline-content">
                                                                <Skeleton
                                                                    variant="rectangular"
                                                                    height={20}
                                                                    sx={() => ({
                                                                        color: '#fff',
                                                                        p: '0.35rem',
                                                                        borderRadius: '0.35rem',
                                                                        textAlign: 'center'
                                                                    })}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="timeline-item">
                                                            <div className="timeline-badge">
                                                                <Skeleton
                                                                    variant="circular"
                                                                    width={20}
                                                                    height={20}
                                                                    sx={() => ({
                                                                        color: '#fff',
                                                                        p: '0.35rem',
                                                                        textAlign: 'center'
                                                                    })}
                                                                />
                                                            </div>
                                                            <div className="timeline-label">
                                                                <Skeleton
                                                                    variant="rectangular"
                                                                    height={20}
                                                                    width={50}
                                                                    sx={() => ({
                                                                        color: '#fff',
                                                                        p: '0.35rem',
                                                                        borderRadius: '0.35rem',
                                                                        textAlign: 'center'
                                                                    })}
                                                                />
                                                            </div>
                                                            <div className="timeline-content">
                                                                <Skeleton
                                                                    variant="rectangular"
                                                                    height={20}
                                                                    sx={() => ({
                                                                        color: '#fff',
                                                                        p: '0.35rem',
                                                                        borderRadius: '0.35rem',
                                                                        textAlign: 'center'
                                                                    })}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    ) : (
                                        <>
                                            <div className="timeline timeline-justified timeline-4">
                                                <div className="timeline-bar"></div>
                                                {vehicleCountTrends?.data?.map((item, index) =>
                                                    <div className="timeline-items" key={index}>
                                                        <div className="timeline-item">
                                                            <div className="timeline-badge">
                                                                <OutboundTwoToneIcon
                                                                    fontSize='large'
                                                                    color='error'
                                                                />
                                                            </div>
                                                            <div className="timeline-label">
                                                                <div className='py-2 px-2 bg-light-primary rounded-xl'>
                                                                    <span>
                                                                        <DateRangeTwoToneIcon
                                                                            color='success'
                                                                        />
                                                                        {" "}
                                                                        <span className='font-size-md'>
                                                                            {`${(item.month).toUpperCase()} ${item.year}`}
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="timeline-content">
                                                                {`${item.harshBrakingCount} events`}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </>
                                    )}
                                </div>
                            </PerfectScrollbar>
                        }
                        className={"card-stretch gutter-b"}
                        callToActionsState={false}
                        widgetHeight='100%'
                    />
                </div>
                <div className="col-lg-12 col-xxl-12 col-md-12 col-sm-12 mt-5">
                    <DashboardTileWidgetAlt
                        title="Overspeeding Events Across Fleet"
                        number={
                            <PerfectScrollbar
                                options={perfectScrollbarOptions}
                                className="scroll pr-7 mr-n7"
                                style={{
                                    maxHeight: "450px",
                                    position: "relative"
                                }}
                            >
                                <div className="card card-custom no-border-shadow rounded-xl">
                                    <div className="card-body">
                                        <div className="bg-light-primary rounded-xl tab-content py-3 px-3">
                                            {isLoadingVehicleCountTrends && <BasicLoader size={"sm"} />}
                                            {!isLoadingVehicleCountTrends && <DynamicApexChart
                                                xAxisName={"MONTH"}
                                                yAxisMinVal={0}
                                                horizontalBar={false}
                                                chartHeight={300}
                                                labelFontWeight={"bold"}
                                                categories={fleetTravelCountTrending?.xAxisArray}
                                                data={fleetTravelCountTrending?.overSpeedingArray}
                                                chartTitle={"Overspeeding events across Fleet"}
                                                exportFilename={`Overspeeding-Events (Exported from Wahu OS) - ${new Date().toLocaleDateString()}`}
                                                yAxisName={"# of Overspeeding Events"}
                                                yAxisMaxVal={maxOverSpeedingValue}
                                                chartType={"line"}
                                            />}
                                        </div>
                                    </div>
                                </div>
                                <div className="pl-10 pr-10">
                                    {isLoadingVehicleCountTrends ? (
                                        isLoadingVehicleCountTrendsError ? (
                                            <>
                                                <div className='centerMan mb-1'>
                                                    <span className='label label-lg label-light-danger label-inline label-rounded p-8'>
                                                        <span>
                                                            <i className="fas fa-exclamation-triangle"></i>
                                                            {" "}
                                                            could not load metrics:
                                                            {" "}
                                                            {fetchVehicleCountTrendsErrorMessage.current}
                                                        </span>
                                                    </span>
                                                </div>
                                                <div className="timeline timeline-justified timeline-4">
                                                    <div className="timeline-bar"></div>
                                                    <div className="timeline-items">
                                                        <div className="timeline-item">
                                                            <div className="timeline-badge">
                                                                <Skeleton
                                                                    sx={(theme) => ({
                                                                        backgroundColor: theme.palette.error.light,
                                                                        color: '#fff',
                                                                        p: '0.35rem',
                                                                        textAlign: 'center'
                                                                    })}
                                                                    variant="circular"
                                                                    width={20}
                                                                    height={20}
                                                                />
                                                            </div>
                                                            <div className="timeline-label">
                                                                <Skeleton
                                                                    sx={(theme) => ({
                                                                        backgroundColor: theme.palette.error.light,
                                                                        borderRadius: '0.35rem',
                                                                        color: '#fff',
                                                                        p: '0.35rem',
                                                                        textAlign: 'center'
                                                                    })}
                                                                    variant="rectangular"
                                                                    height={20}
                                                                    width={50}
                                                                />
                                                            </div>
                                                            <div className="timeline-content">
                                                                <Skeleton
                                                                    sx={(theme) => ({
                                                                        backgroundColor: theme.palette.error.light,
                                                                        borderRadius: '0.35rem',
                                                                        color: '#fff',
                                                                        p: '0.35rem',
                                                                        textAlign: 'center'
                                                                    })}
                                                                    variant="rectangular"
                                                                    height={20}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="timeline-item">
                                                            <div className="timeline-badge">
                                                                <Skeleton
                                                                    sx={(theme) => ({
                                                                        backgroundColor: theme.palette.error.light,
                                                                        color: '#fff',
                                                                        p: '0.35rem',
                                                                        textAlign: 'center'
                                                                    })}
                                                                    variant="circular"
                                                                    width={20}
                                                                    height={20}
                                                                />
                                                            </div>
                                                            <div className="timeline-label">
                                                                <Skeleton
                                                                    sx={(theme) => ({
                                                                        backgroundColor: theme.palette.error.light,
                                                                        borderRadius: '0.35rem',
                                                                        color: '#fff',
                                                                        p: '0.35rem',
                                                                        textAlign: 'center'
                                                                    })}
                                                                    variant="rectangular"
                                                                    height={20}
                                                                    width={50}
                                                                />
                                                            </div>
                                                            <div className="timeline-content">
                                                                <Skeleton
                                                                    sx={(theme) => ({
                                                                        backgroundColor: theme.palette.error.light,
                                                                        borderRadius: '0.35rem',
                                                                        color: '#fff',
                                                                        p: '0.35rem',
                                                                        textAlign: 'center'
                                                                    })}
                                                                    variant="rectangular"
                                                                    height={20}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <div className="timeline timeline-justified timeline-4">
                                                    <div className="timeline-bar"></div>
                                                    <div className="timeline-items">
                                                        <div className="timeline-item">
                                                            <div className="timeline-badge">
                                                                <Skeleton
                                                                    variant="circular"
                                                                    width={20}
                                                                    height={20}
                                                                    sx={() => ({
                                                                        color: '#fff',
                                                                        p: '0.35rem',
                                                                        textAlign: 'center'
                                                                    })}
                                                                />
                                                            </div>
                                                            <div className="timeline-label">
                                                                <Skeleton
                                                                    variant="rectangular"
                                                                    height={20}
                                                                    width={50}
                                                                    sx={() => ({
                                                                        color: '#fff',
                                                                        p: '0.35rem',
                                                                        borderRadius: '0.35rem',
                                                                        textAlign: 'center'
                                                                    })}
                                                                />
                                                            </div>
                                                            <div className="timeline-content">
                                                                <Skeleton
                                                                    variant="rectangular"
                                                                    height={20}
                                                                    sx={() => ({
                                                                        color: '#fff',
                                                                        p: '0.35rem',
                                                                        borderRadius: '0.35rem',
                                                                        textAlign: 'center'
                                                                    })}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="timeline-item">
                                                            <div className="timeline-badge">
                                                                <Skeleton
                                                                    variant="circular"
                                                                    width={20}
                                                                    height={20}
                                                                    sx={() => ({
                                                                        color: '#fff',
                                                                        p: '0.35rem',
                                                                        textAlign: 'center'
                                                                    })}
                                                                />
                                                            </div>
                                                            <div className="timeline-label">
                                                                <Skeleton
                                                                    variant="rectangular"
                                                                    height={20}
                                                                    width={50}
                                                                    sx={() => ({
                                                                        color: '#fff',
                                                                        p: '0.35rem',
                                                                        borderRadius: '0.35rem',
                                                                        textAlign: 'center'
                                                                    })}
                                                                />
                                                            </div>
                                                            <div className="timeline-content">
                                                                <Skeleton
                                                                    variant="rectangular"
                                                                    height={20}
                                                                    sx={() => ({
                                                                        color: '#fff',
                                                                        p: '0.35rem',
                                                                        borderRadius: '0.35rem',
                                                                        textAlign: 'center'
                                                                    })}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    ) : (
                                        <>
                                            <div className="timeline timeline-justified timeline-4">
                                                <div className="timeline-bar"></div>
                                                {vehicleCountTrends?.data?.map((item, index) =>
                                                    <div className="timeline-items" key={index}>
                                                        <div className="timeline-item">
                                                            <div className="timeline-badge">
                                                                <ShutterSpeedTwoToneIcon
                                                                    fontSize='large'
                                                                    color='warning'
                                                                />
                                                            </div>
                                                            <div className="timeline-label">
                                                                <div className='py-2 px-2 bg-light-primary rounded-xl'>
                                                                    <span>
                                                                        <DateRangeTwoToneIcon
                                                                            color='success'
                                                                        />
                                                                        {" "}
                                                                        <span className='font-size-md'>
                                                                            {`${item.year} - ${(item.month).toUpperCase()}`}
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="timeline-content">
                                                                {`${item.overSpeedingCount} events`}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </>
                                    )}
                                </div>
                            </PerfectScrollbar>
                        }
                        className={`card-stretch gutter-b`}
                        callToActionsState={false}
                        widgetHeight='100%'
                    />
                </div>
                <div className="col-lg-12 col-xxl-12 col-md-12 col-sm-12 mt-5">
                    <DashboardTileWidgetAlt
                        title="Total Distance Ridden Past 10pm"
                        number={
                            <PerfectScrollbar
                                options={perfectScrollbarOptions}
                                className="scroll pr-7 mr-n7"
                                style={{
                                    maxHeight: "450px",
                                    position: "relative"
                                }}
                            >
                                <div className="card card-custom no-border-shadow rounded-xl">
                                    <div className="card-body">
                                        <div className="bg-light-primary rounded-xl tab-content py-3 px-3">
                                            {isLoadingVehicleCountTrends && <BasicLoader size={"sm"} />}
                                            {!isLoadingVehicleCountTrends && <DynamicApexChart
                                                xAxisName={"MONTH"}
                                                yAxisMinVal={0}
                                                horizontalBar={false}
                                                chartHeight={300}
                                                labelFontWeight={"bold"}
                                                categories={fleetTravelCountTrending?.xAxisArray}
                                                data={fleetTravelCountTrending?.unsafeDistanceTravelArray}
                                                chartTitle={"Unsafe Traveled across Fleet"}
                                                exportFilename={`Unsafe-Distance-Traveled (Exported from Wahu OS) - ${new Date().toLocaleDateString()}`}
                                                yAxisName={"Mileage in km"}
                                                yAxisMaxVal={maxUnsafeDistanceTraveledValue}
                                                chartType={"line"}
                                            />}
                                        </div>
                                    </div>
                                </div>
                                <div className="pl-10 pr-10">
                                    {isLoadingVehicleCountTrends ? (
                                        isLoadingVehicleCountTrendsError ? (
                                            <>
                                                <div className='centerMan mb-1'>
                                                    <span className='label label-lg label-light-danger label-inline label-rounded p-8'>
                                                        <span>
                                                            <i className="fas fa-exclamation-triangle"></i>
                                                            {" "}
                                                            could not load metrics:
                                                            {" "}
                                                            {fetchVehicleCountTrendsErrorMessage.current}
                                                        </span>
                                                    </span>
                                                </div>
                                                <div className="timeline timeline-justified timeline-4">
                                                    <div className="timeline-bar"></div>
                                                    <div className="timeline-items">
                                                        <div className="timeline-item">
                                                            <div className="timeline-badge">
                                                                <Skeleton
                                                                    sx={(theme) => ({
                                                                        backgroundColor: theme.palette.error.light,
                                                                        color: '#fff',
                                                                        p: '0.35rem',
                                                                        textAlign: 'center'
                                                                    })}
                                                                    variant="circular"
                                                                    width={20}
                                                                    height={20}
                                                                />
                                                            </div>
                                                            <div className="timeline-label">
                                                                <Skeleton
                                                                    sx={(theme) => ({
                                                                        backgroundColor: theme.palette.error.light,
                                                                        borderRadius: '0.35rem',
                                                                        color: '#fff',
                                                                        p: '0.35rem',
                                                                        textAlign: 'center'
                                                                    })}
                                                                    variant="rectangular"
                                                                    height={20}
                                                                    width={50}
                                                                />
                                                            </div>
                                                            <div className="timeline-content">
                                                                <Skeleton
                                                                    sx={(theme) => ({
                                                                        backgroundColor: theme.palette.error.light,
                                                                        borderRadius: '0.35rem',
                                                                        color: '#fff',
                                                                        p: '0.35rem',
                                                                        textAlign: 'center'
                                                                    })}
                                                                    variant="rectangular"
                                                                    height={20}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="timeline-item">
                                                            <div className="timeline-badge">
                                                                <Skeleton
                                                                    sx={(theme) => ({
                                                                        backgroundColor: theme.palette.error.light,
                                                                        color: '#fff',
                                                                        p: '0.35rem',
                                                                        textAlign: 'center'
                                                                    })}
                                                                    variant="circular"
                                                                    width={20}
                                                                    height={20}
                                                                />
                                                            </div>
                                                            <div className="timeline-label">
                                                                <Skeleton
                                                                    sx={(theme) => ({
                                                                        backgroundColor: theme.palette.error.light,
                                                                        borderRadius: '0.35rem',
                                                                        color: '#fff',
                                                                        p: '0.35rem',
                                                                        textAlign: 'center'
                                                                    })}
                                                                    variant="rectangular"
                                                                    height={20}
                                                                    width={50}
                                                                />
                                                            </div>
                                                            <div className="timeline-content">
                                                                <Skeleton
                                                                    sx={(theme) => ({
                                                                        backgroundColor: theme.palette.error.light,
                                                                        borderRadius: '0.35rem',
                                                                        color: '#fff',
                                                                        p: '0.35rem',
                                                                        textAlign: 'center'
                                                                    })}
                                                                    variant="rectangular"
                                                                    height={20}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <div className="timeline timeline-justified timeline-4">
                                                    <div className="timeline-bar"></div>
                                                    <div className="timeline-items">
                                                        <div className="timeline-item">
                                                            <div className="timeline-badge">
                                                                <Skeleton
                                                                    variant="circular"
                                                                    width={20}
                                                                    height={20}
                                                                    sx={() => ({
                                                                        color: '#fff',
                                                                        p: '0.35rem',
                                                                        textAlign: 'center'
                                                                    })}
                                                                />
                                                            </div>
                                                            <div className="timeline-label">
                                                                <Skeleton
                                                                    variant="rectangular"
                                                                    height={20}
                                                                    width={50}
                                                                    sx={() => ({
                                                                        color: '#fff',
                                                                        p: '0.35rem',
                                                                        borderRadius: '0.35rem',
                                                                        textAlign: 'center'
                                                                    })}
                                                                />
                                                            </div>
                                                            <div className="timeline-content">
                                                                <Skeleton
                                                                    variant="rectangular"
                                                                    height={20}
                                                                    sx={() => ({
                                                                        color: '#fff',
                                                                        p: '0.35rem',
                                                                        borderRadius: '0.35rem',
                                                                        textAlign: 'center'
                                                                    })}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="timeline-item">
                                                            <div className="timeline-badge">
                                                                <Skeleton
                                                                    variant="circular"
                                                                    width={20}
                                                                    height={20}
                                                                    sx={() => ({
                                                                        color: '#fff',
                                                                        p: '0.35rem',
                                                                        textAlign: 'center'
                                                                    })}
                                                                />
                                                            </div>
                                                            <div className="timeline-label">
                                                                <Skeleton
                                                                    variant="rectangular"
                                                                    height={20}
                                                                    width={50}
                                                                    sx={() => ({
                                                                        color: '#fff',
                                                                        p: '0.35rem',
                                                                        borderRadius: '0.35rem',
                                                                        textAlign: 'center'
                                                                    })}
                                                                />
                                                            </div>
                                                            <div className="timeline-content">
                                                                <Skeleton
                                                                    variant="rectangular"
                                                                    height={20}
                                                                    sx={() => ({
                                                                        color: '#fff',
                                                                        p: '0.35rem',
                                                                        borderRadius: '0.35rem',
                                                                        textAlign: 'center'
                                                                    })}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    ) : (
                                        <>
                                            <div className="timeline timeline-justified timeline-4">
                                                <div className="timeline-bar"></div>
                                                {vehicleCountTrends?.data?.map((item, index) =>
                                                    <div className="timeline-items" key={index}>
                                                        <div className="timeline-item">
                                                            <div className="timeline-badge">
                                                                <TimelapseTwoToneIcon
                                                                    fontSize='large'
                                                                    color='success'
                                                                />
                                                            </div>
                                                            <div className="timeline-label">
                                                                <div className='py-2 px-2 bg-light-primary rounded-xl'>
                                                                    <span>
                                                                        <DateRangeTwoToneIcon
                                                                            color='success'
                                                                        />
                                                                        {" "}
                                                                        <span className='font-size-md'>
                                                                            {`${(item.month).toUpperCase()} ${item.year}`}
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="timeline-content">
                                                                {`${item.mileageBeyondEveningCount} events`}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </>
                                    )}
                                </div>
                            </PerfectScrollbar>
                        }
                        className={`card-stretch gutter-b`}
                        callToActionsState={false}
                        widgetHeight='100%'
                    />
                </div>
            </div>

            {/* <div className='row'>
                <div className="col-lg-7 col-xxl-7">
                    <DonutChart
                        title="Rider Safety Score (%)"
                        openInfo={() => {
                            setShowRiderSafetyInfo(true);
                        }}
                        labels={["100%-90%", "80%-60%", "50% and lower"]}
                        series={[
                            55, 44, 17
                        ]}
                    />
                </div>
            </div> */}

            {/* <div className='row'>
                <div className="col-lg-6 col-xxl-6">
                    <DashboardTileWidget
                        title="Total Distance Traveled"
                        number={`90, 359.271 km`}
                        className="card-stretch gutter-b"
                        iconSVG={"/media/svg/icons/General/path-distance.svg"}
                        callToActions={
                            [{
                                name: "Trends",
                                icon: <TrendingUpTwoToneIcon />
                            },
                            {
                                name: "Per Rider",
                                icon: <TrendingUpTwoToneIcon />
                            }]
                        }
                        clickTrendsEvent={() => {
                            setShowTotalHarshBrakingEvents(true)
                        }}
                        clickPerRider={() => { setShowAllRidersGender(true) }}
                        widgetHeight='250px'
                    />
                </div>
                <div className="col-lg-6 col-xxl-6">
                    <DashboardTileWidget
                        title="Total Kilogram Of CO2 Avoided"
                        number={`8, 353.714 kg`}
                        className="card-stretch gutter-b"
                        iconSVG={"/media/svg/icons/General/molecule-co2.svg"}
                        callToActions={
                            [{
                                name: "Trends",
                                icon: <TrendingUpTwoToneIcon />
                            },
                            {
                                name: "Per Rider",
                                icon: <TrendingUpTwoToneIcon />
                            }]
                        }
                        clickTrendsEvent={() => { setShowTotalOverSpeedingEvents(true) }}
                        clickPerRider={() => { setShowAllRidersGender(true) }}
                        widgetHeight='250px'
                    />
                </div>

            </div> */}

            {/* Payment Behaviours KPIs */}
            {/* <div className='row'>
                <div className='col-lg-12 col-xxl-12'>
                    <h1 className='mb-5 mt-5'>
                        Payment Behaviours
                    </h1>
                </div>
                <div className="col-lg-6 col-xxl-4">
                    <DashboardTileWidget
                        title="Total Number of Expected Payments"
                        number={driverCount} className="card-stretch gutter-b"
                        iconSVG={"/media/svg/icons/General/payment-clock.svg"} callToActions={
                            [
                                {
                                    name: "Trends",
                                    icon: <TrendingUpTwoToneIcon />
                                },
                                {
                                    name: "Per Rider",
                                    icon: <TrendingUpTwoToneIcon />
                                }
                            ]
                        }
                        clickTrendsEvent={() => { setShowTotalOverSpeedingEvents(true) }}
                        clickPerRider={() => { setShowAllRidersGender(true) }}
                    />
                </div>
                <div className="col-lg-6 col-xxl-4">
                    <DashboardTileWidget
                        title="Total Number of Recieved Payments"
                        number={driverCount} className="card-stretch gutter-b"
                        iconSVG={"/media/svg/icons/General/pay-money.svg"} callToActions={
                            [
                                {
                                    name: "Trends",
                                    icon: <TrendingUpTwoToneIcon />
                                },
                                {
                                    name: "Per Rider",
                                    icon: <TrendingUpTwoToneIcon />
                                }
                            ]
                        }
                        clickTrendsEvent={() => { setShowTotalOverSpeedingEvents(true) }}
                        clickPerRider={() => { setShowAllRidersGender(true) }}
                    />
                </div>
                <div className="col-lg-6 col-xxl-4">
                    <DashboardTileWidget
                        title="Total Number of Delinquent Payments"
                        number={driverCount} className="card-stretch gutter-b"
                        iconSVG={"/media/svg/icons/General/flash-payment.svg"} callToActions={
                            [
                                {
                                    name: "Trends",
                                    icon: <TrendingUpTwoToneIcon />
                                },
                                {
                                    name: "Per Rider",
                                    icon: <TrendingUpTwoToneIcon />
                                }
                            ]
                        }
                        clickTrendsEvent={() => { setShowTotalOverSpeedingEvents(true) }}
                        clickPerRider={() => { setShowAllRidersGender(true) }}
                    />
                </div>
                <div className="col-lg-6 col-xxl-6">
                    <DonutChart title="Payment Plans Selected by %" openInfo={() => { }} labels={["300cedis Subscriptions", "400cedis Subscriptions", "Cash"]} />
                </div>
            </div> */}
            {/* Payment Behaviours Ends */}

            {/* <div className="row">

                <div className="col-lg-6 col-xxl-4">
                    <DashboardTileWidget
                        title="Total Number of Drivers"
                        number={totalNumOfDrivers} className="card-stretch gutter-b"
                        iconSVG={"/media/svg/icons/Communication/Group.svg"} callToActions={[{
                            name: "Trends",
                            icon: <TrendingUpTwoToneIcon />
                        }]}

                    />
                </div>
                <div className="col-lg-6 col-xxl-4">
                    <DashboardTileWidget title="Daily Harsh Breaking Events" number={totalNumOfDrivers} className="card-stretch gutter-b" />
                    <CustomMixedWidget title="Harsh Breaking Events" />
                </div>
                <div className="col-lg-6 col-xxl-4 ">
                    <DashboardTileWidget title="Over Speeding Events" number={totalNumOfDrivers} className="card-stretch gutter-b" />
                    <CustomStatsWidget title="Over Speeding Events" className="card-stretch card-stretch-half gutter-b" number="23" />
                </div>
                <div className="col-lg-6 col-xxl-4 order-2 order-xxl-1">
                    <CustomListWidget className="card-stretch gutter-b" title={"Upcoming Events"} />
                </div>
                <div className="col-xxl-4 order-1 order-xxl-1">
                    <DashboardTileWidget title="CO2 Saved" number={"1490kg"} className="card-stretch gutter-b" />
                    <CustomMixedWidget title="Total Distance Traveled" />
                </div>
                <div className="col-lg-6 col-xxl-4 order-1 order-xxl-2">
                    <ListsWidget3 className="card-stretch gutter-b" />
                </div>
                <div className="col-lg-6 col-xxl-4 order-1 order-xxl-2">
                    <ListsWidget4 className="card-stretch gutter-b" />
                </div>
                <div className="col-lg-12 col-xxl-4 order-1 order-xxl-2">
                    <ListsWidget8 className="card-stretch gutter-b" />
                </div>
            </div>
            <div className="row">
                <div className="col-lg-4">
                    <MixedWidget14 className="card-stretch gutter-b" />
                </div>
                <div className="col-lg-8">
                    <AdvanceTablesWidget4 className="card-stretch gutter-b" />
                </div>
            </div> */}
        </>
    );
}