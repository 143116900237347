/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useRef } from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_helpers";
import { AUTH_BASE_URL } from "../../../../app/services/baseService";
import axios from "axios";
import { FormAlert } from "../../controls";
import BasicLoader from "../../loaders/SimpleLoader";

export function AdderDetails({ className, adderID, eventTime, assignedEntity, assignmentType }) {

    const TableErrorMessage = useRef();

    const [isLoading, setIsLoading] = useState(false);
    const [adderData, setAdderData] = useState(null);
    const [isError, setIsError] = useState(false);

    const [alertType, setAlertType] = useState({
        type: '',
        icon: ''
    });

    const handleAlertDismiss = () => {
        setIsError(false);
    };

    useEffect(() => {

        setIsLoading(true);

        let isMounted = true;
        const controller = new AbortController();

        const fetchData = async () => {

            const url = new URL(`/users/admins/${adderID}`, `${AUTH_BASE_URL}`);

            try {

                const response = axios.get(url.href, { signal: controller.signal });
                const data = (await response).data;

                setAdderData(data);
                console.log(data);

            } catch (error) {

                setIsError(true);

                if (error?.code === "ERR_NETWORK" || error?.code === "ECONNABORTED") {

                    setAlertType({
                        type: 'danger',
                        icon: 'flaticon-warning mana-icon-white'
                    });
                    TableErrorMessage.current = "network error, please check your internet connection";
                    return setIsLoading(false);

                }

                setAlertType({
                    type: 'danger',
                    icon: 'flaticon-warning mana-icon-white'
                });
                TableErrorMessage.current = `Code: ${error?.response?.status ?? error?.status}, Message: ${error?.response.data.message}`;

            } finally {
                setIsLoading(false);
            };

        };

        isMounted === true && fetchData();

        return () => {

            isMounted = false;
            controller.abort();

            setAdderData(null);

        };

    }, [adderID]);

    return (
        <>

            <div className={`card card-custom ${className}`}>
                {isLoading && (<>
                    <div className="centerMan">
                        <BasicLoader size={"lg"} />
                    </div>
                </>)}
                {!isLoading && <div className="card-body d-flex flex-column">
                    <div className="flex-grow-1 pb-5">
                        <div className="d-flex align-items-center pr-2 mb-6">
                            <span className="text-muted font-weight-bold font-size-lg flex-grow-1 text-uppercase">
                                {`${assignedEntity} ${assignmentType} on ${new Date(eventTime).toUTCString()}`}
                            </span>
                            <div className="symbol symbol-50">
                                <span className="symbol-label bg-light-light">
                                    <SVG
                                        src={adderData?.user_data?.document?.profileImageURL ? adderData?.user_data?.document?.profileImageURL : toAbsoluteUrl("/media/svg/icons/General/User.svg")}
                                        className="h-50 align-self-center"
                                    ></SVG>
                                    {" "}
                                </span>
                            </div>
                        </div>
                        <a
                            href="#"
                            className="text-dark font-weight-bolder text-hover-primary font-size-h4"
                        >
                            <span>
                                {adderData?.user_data?.document?.firstName}
                            </span>
                            <br />
                            <span>
                                {adderData?.user_data?.document?.lastName}
                            </span>
                        </a>
                        <div className="row">
                            <div className="col p-2">
                                <div className="bg-light-primary rounded-xl pt-2 pb-2 pr-4 pl-4" style={{
                                    height: "115px"
                                }}>
                                    <p className="text-dark-50 mt-6">
                                        <span className="bold font-size-lg">
                                            Bio:
                                        </span>
                                        <br />
                                        {adderData?.user_data?.document?.bioText ?
                                            (<>
                                                <span className="bold" style={{
                                                    fontSize: "1.5em",
                                                }}>
                                                    {adderData?.user_data?.document?.bioText}
                                                </span>
                                            </>)
                                            :
                                            (<>
                                                <span className="text-danger">
                                                    Not added
                                                </span>
                                            </>)
                                        }
                                    </p>
                                </div>
                            </div>
                            <div className="col p-2">
                                <div className="bg-light-primary rounded-xl pt-2 pb-2 pr-4 pl-4" style={{
                                    height: "115px"
                                }}>
                                    <p className="text-dark-50 mt-6">
                                        <span className="bold font-size-lg">
                                            Role Title:
                                        </span>
                                        <br />
                                        {adderData?.user_data?.document?.roleTitle ?
                                            (<>
                                                <span className="bold" style={{
                                                    fontSize: "1.5em",
                                                }}>
                                                    {adderData?.user_data?.document?.roleTitle}
                                                </span>
                                            </>)
                                            :
                                            (<>
                                                <span className="text-danger">
                                                    Not set
                                                </span>
                                            </>)
                                        }
                                    </p>
                                </div>
                            </div>
                            <div className="col p-2">
                                <div className="bg-light-primary rounded-xl pt-2 pb-2 pr-4 pl-4" style={{
                                    height: "115px"
                                }}>
                                    <p className="text-dark-50 mt-6">
                                        <span className="bold font-size-lg">
                                            Department:
                                        </span>
                                        <br />
                                        {adderData?.user_data?.document?.department ?
                                            (<>
                                                <span className="bold" style={{
                                                    fontSize: "1.5em",
                                                }}>
                                                    {adderData?.user_data?.document?.department}
                                                </span>
                                            </>)
                                            :
                                            (<>
                                                <span className="text-danger">
                                                    Not set
                                                </span>
                                            </>)
                                        }
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="d-flex align-items-center">
                        <a href="#" className="symbol symbol-45 symbol-light mr-3">
                            <div className="symbol-label">
                                <SVG
                                    src={toAbsoluteUrl("/media/svg/avatars/001-boy.svg")}
                                    className="h-75 align-self-end"
                                ></SVG>
                            </div>
                        </a>
                        <a href="#" className="symbol symbol-45 symbol-light mr-3">
                            <div className="symbol-label">
                                <SVG
                                    src={toAbsoluteUrl("/media/svg/avatars/028-girl-16.svg")}
                                    className="h-75 align-self-end"
                                ></SVG>
                            </div>
                        </a>
                        <a href="#" className="symbol symbol-45 symbol-light">
                            <div className="symbol-label">
                                <SVG
                                    src={toAbsoluteUrl("/media/svg/avatars/024-boy-9.svg")}
                                    className="h-75 align-self-end"
                                ></SVG>
                            </div>
                        </a>
                    </div> */}
                    <FormAlert
                        className={"mt-2"}
                        isEnabled={isError}
                        type={alertType.type}
                        message={TableErrorMessage.current}
                        icon={alertType.icon}
                        handleDismiss={handleAlertDismiss}
                    />
                </div>}
            </div>

        </>
    );

}
