import {
    IconButton,
    Snackbar,
    Box,
    Button,
    TextField,
    Typography,
    Tooltip,
    Grid,
    Paper,
    styled,
    Alert,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import axios from "axios";
import { MaterialReactTable } from 'material-react-table';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { activateBattery, deactivateBattery } from '../../../../app/modules/Auth/_redux/authCrud';
import { FLEET_MANAGING_BASE_URL, setStorageItem } from '../../../../app/services/baseService';
import { convertUnixTimestampToUTC } from "../../../_helpers";
import { SimpleModal } from '../modals/SimpleModal';
import { CreateEditBatteryForm } from '../forms/CreateEditBatteryForm';
import { Edit, Delete } from '@mui/icons-material';
import AddBoxTwoToneIcon from '@mui/icons-material/AddBoxTwoTone';

const useStyles = makeStyles(theme => ({
    close: {
        padding: theme.spacing(0.5),
    },
    margin: {
        margin: theme.spacing(1),
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    rounder: {
        border: `1px solid #b9975b`
    }
}));

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

export function BatteriesSettingsTableSub({ tableClassName }) {

    const classes = useStyles();

    const [data, setData] = useState([]);
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 100,
    });
    const [rowCount, setRowCount] = useState(0);
    const [reRender, setReRender] = useState(0);
    const [open, setOpen] = useState(false);
    const [severity, setSeverity] = useState('info');
    const [actionBtnDisabled, setActionBtnDisabled] = useState(false);
    const [show, setShow] = useState(false);
    const [initialValues, setInitialValues] = useState({
        type: "",
        capacity: 0,
        operatingVoltage: 0,
        peakVoltage: 0
    });
    const [editState, setEditState] = useState(false);
    const [createEditText, setCreateEditText] = useState('Add Battery');
    const [modalHeaderText, setModalHeaderText] = useState('Add Battery');
    const [fieldDisabled, setFieldDisabled] = useState(false);

    const TableErrorMessage = useRef();
    const defaultValuesToSet = {
        type: "",
        capacity: 0,
        operatingVoltage: 0,
        peakVoltage: 0
    };

    function handleSnackbarClose(event, reason) {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    }

    function triggerSnackBar() {
        setOpen(true);
    }

    function onChangeWorker() {
        setReRender(prevState => prevState + 1);
    }

    useEffect(() => {

        let isMounted = true;
        const controller = new AbortController();

        const fetchData = async () => {

            if (!data.length) {
                setIsLoading(true);
            } else {
                setIsRefetching(true);
            }

            const url = new URL('/batteries/getAll', `${FLEET_MANAGING_BASE_URL}`);
            url.searchParams.set(
                'startNumber',
                `${pagination.pageIndex * pagination.pageSize}`,
            );
            url.searchParams.set('pageSize', `${pagination.pageSize}`);

            try {

                const response = axios.get(url.href, { signal: controller.signal });
                const data = (await response).data;

                setData(data.data);
                setRowCount(data.max_count);

            } catch (error) {

                if (error?.code === "ERR_NETWORK" || error?.code === "ECONNABORTED") {

                    setSeverity("error");
                    TableErrorMessage.current = "network error, please check your internet connection";
                    return setOpen(true);

                }

                setSeverity("error");
                TableErrorMessage.current = `Code: ${error.response.status}, Message: ${error.response.data.message}`;

                setIsError(true);
                setOpen(true);

            } finally {

                setIsLoading(false);
                setIsRefetching(false);

            }

        };

        isMounted && fetchData();

        return () => {

            isMounted = false;
            controller.abort();

            setData([]);

        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        pagination.pageIndex,
        pagination.pageSize,
        reRender
    ]);

    const columns = useMemo(() => [
        {
            accessorFn: (row) => row?.document?.type,
            id: 'type',
            header: 'Type',
            size: 250,
            Cell: ({ cell, row }) => (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '1rem',
                    }}
                >
                    <Typography>
                        {cell.getValue()}
                    </Typography>
                </Box>
            ),
        },
        {
            accessorFn: (row) => row?.document?.capacity,
            id: "capacity",
            enableClickToCopy: true,
            header: 'Capacity'
        },
        {
            accessorFn: (row) => row?.document?.operatingVoltage,
            id: "operatingVoltage",
            enableClickToCopy: true,
            header: 'Operating Voltage'
        },
        {
            accessorFn: (row) => row?.document?.peakVoltage,
            id: "peakVoltage",
            enableClickToCopy: true,
            header: 'Peak Voltage'
        },
        {
            accessorFn: (row) => row?.document?.isActive,
            header: 'Status',
            Cell: ({ cell }) => (
                <Box
                    sx={(theme) => ({
                        backgroundColor: cell.getValue() === true ? theme.palette.success.dark : cell.getValue() === false ? theme.palette.error.dark : theme.palette.grey.dark,
                        borderRadius: '0.35rem',
                        color: '#fff',
                        p: '0.35rem',
                        textAlign: 'center'
                    })}
                >
                    {cell.getValue() === true ? "Active" : "Inactive"}
                </Box>
            ),
        },
        {
            accessorFn: (row) => `${new Date(row?.document?.createdAt).toLocaleDateString()}`,
            id: 'document.ID',
            header: 'Date Created',
            filterFn: 'lessThanOrEqualTo',
            sortingFn: 'datetime',
            Cell: ({ cell }) => cell.getValue(),
            Header: ({ column }) => <em>
                {column.columnDef.header}
            </em>,
            Filter: ({ column }) => (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        onChange={(newValue) => {
                            column.setFilterValue(newValue);
                        }}
                        input={(params) => (
                            <TextField
                                {...params}
                                helperText={'Filter Mode: Less Than'}
                                sx={{ minWidth: '120px' }}
                                variant="standard"
                            />
                        )}
                        value={column.getFilterValue()}
                    />
                </LocalizationProvider>
            )
        }
    ],
        [],
    );

    return (
        <>
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                open={open}
                autoHideDuration={8000}
                onClose={handleSnackbarClose}
                ContentProps={{
                    'aria-describedby': 'message-id',
                }}
                action={[
                    <Button
                        key="undo"
                        color="inherit"
                        size="small"
                        onClick={handleSnackbarClose}
                    >
                        UNDO
                    </Button>,
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        className={classes.close}
                        onClick={handleSnackbarClose}
                    >
                        <CloseIcon />
                    </IconButton>,
                ]}
            >
                <Alert
                    elevation={6}
                    variant="filled"
                    onClose={handleSnackbarClose}
                    severity={severity}
                >
                    {<span id="message-id">
                        {TableErrorMessage.current}
                    </span>}
                </Alert>
            </Snackbar>
            <SimpleModal
                modal_tittle={modalHeaderText}
                show={show}
                close={() => setShow(false)}
                size="lg"
                body={<CreateEditBatteryForm
                    initialValues={initialValues}
                    editState={editState}
                    createEditText={createEditText}
                    fieldDisabled={fieldDisabled}
                    onChanger={onChangeWorker}
                />}
            />
            <div className={`card card-custom ${tableClassName}`}>
                <div className="card-header border-0 pt-5">
                    <h3 className="card-title align-items-start flex-column">
                        <span className="card-label font-weight-bolder text-dark">
                            Batteries on Vehicles
                        </span>
                        <span className="text-muted mt-3 font-weight-bold font-size-sm">
                            Manage Batteries from this table
                        </span>
                    </h3>
                    <div className="card-toolbar">
                        <div className="row">
                            <div className="col-xxl-12 order-1 order-xxl-1">
                                <Button
                                    variant="contained"
                                    size="large"
                                    color="primary"
                                    aria-label="Add"
                                    className={classes.margin}
                                    onClick={() => {
                                        setShow(true);
                                        setEditState(false);
                                        setFieldDisabled(false);
                                        setCreateEditText("Add Battery");
                                        setModalHeaderText("Add Battery Record");
                                        setInitialValues(defaultValuesToSet);
                                    }}
                                    startIcon={<AddBoxTwoToneIcon />}
                                >
                                    Add Battery
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-body pt-3 pb-10">
                    <MaterialReactTable
                        columns={columns}
                        data={data}
                        enableRowSelection
                        getRowId={(row) => row.id}
                        initialState={{ showColumnFilters: true }}
                        manualPagination
                        enableColumnFilterModes
                        enableColumnOrdering
                        enableGrouping
                        enableColumnPinning
                        enableRowPinning
                        enableRowActions
                        muiToolbarAlertBannerProps={
                            isError
                                ? {
                                    color: 'error',
                                    children: `${TableErrorMessage.current}`,
                                }
                                : undefined
                        }
                        onPaginationChange={setPagination}
                        rowCount={rowCount}
                        state={{
                            isLoading,
                            pagination,
                            showAlertBanner: isError,
                            showProgressBars: isRefetching
                        }}
                        renderDetailPanel={({ row }) => (

                            <div>
                                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} className="mb-4">
                                    <Grid item xs={3}>
                                        <Item>
                                            <Box sx={{ textAlign: 'left' }}>
                                                <Typography variant="label" className='bold'>ID: </Typography>
                                                <Typography variant="p">
                                                    {row.original.document?.ID}
                                                </Typography>
                                            </Box>
                                        </Item>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Item>
                                            <Box sx={{ textAlign: 'left' }}>
                                                <Typography variant="label" className='bold'>CreatedAt: </Typography>
                                                <Typography variant="p">
                                                    {convertUnixTimestampToUTC(row.original.document?.createdAt)}
                                                </Typography>
                                            </Box>
                                        </Item>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Item>
                                            <Box sx={{ textAlign: 'left' }}>
                                                <Typography variant="label" className='bold'>Last Updated: </Typography>
                                                <Typography variant="p">
                                                    {convertUnixTimestampToUTC(row.original.document?.updatedAt ?? row.original.document?.createdAt)}
                                                </Typography>
                                            </Box>
                                        </Item>
                                    </Grid>
                                </Grid>
                            </div>
                        )}
                        renderRowActions={({ row, table }) => (
                            <Box sx={{ display: 'flex', gap: '0rem' }}>
                                <Tooltip
                                    arrow
                                    placement="left"
                                    title="Edit">
                                    <IconButton
                                        onClick={() => {

                                            const batteryID = row.original.document.ID;
                                            const valuesToSet = {
                                                type: row.original.document.type,
                                                capacity: row.original.document.capacity,
                                                operatingVoltage: row.original.document.operatingVoltage,
                                                peakVoltage: row.original.document.peakVoltage
                                            };

                                            setStorageItem('batteryIdForEdit', batteryID);

                                            setEditState(true);
                                            setFieldDisabled(true);

                                            setCreateEditText("Save Changes");
                                            setModalHeaderText(`Edit Battery Record - ${batteryID}`);
                                            setInitialValues(valuesToSet);
                                            setShow(true);

                                        }}
                                    >
                                        <Edit color='info' />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip
                                    arrow
                                    placement="left"
                                    title="Delete">
                                    <IconButton>
                                        <Delete color='error' />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        )}
                        renderTopToolbarCustomActions={({ table }) => {

                            const handleDeactivation = () => {

                                setActionBtnDisabled(true);

                                table.getSelectedRowModel().flatRows.map(async (row) => {
                                    try {

                                        handleSnackbarClose();

                                        const response = await deactivateBattery(row.original.document.ID);
                                        TableErrorMessage.current = response.data.message;
                                        setSeverity('success');

                                        setReRender(prevState => prevState + 1);
                                        triggerSnackBar();
                                        setActionBtnDisabled(false);

                                    } catch (error) {

                                        setSeverity('warning');

                                        if (error.response) {
                                            TableErrorMessage.current = error?.response?.data?.message;
                                            triggerSnackBar();
                                            setActionBtnDisabled(false);
                                            return;
                                        }

                                        TableErrorMessage.current = error ?? "an expected error occurred";
                                        setActionBtnDisabled(false);
                                        triggerSnackBar();

                                    }
                                });
                            };

                            const handleActivation = () => {

                                setActionBtnDisabled(true);

                                table.getSelectedRowModel().flatRows.map(async (row) => {
                                    try {

                                        handleSnackbarClose();

                                        const response = await activateBattery(row.original.document.ID);
                                        TableErrorMessage.current = response.data.message;
                                        setSeverity('success');

                                        setReRender(prevState => prevState + 1);
                                        setActionBtnDisabled(false);
                                        triggerSnackBar();

                                    } catch (error) {

                                        setSeverity('warning');

                                        if (error.response) {
                                            TableErrorMessage.current = error?.response?.data?.message;
                                            setActionBtnDisabled(false);
                                            triggerSnackBar();
                                            return;
                                        }

                                        TableErrorMessage.current = error ?? "an expected error occurred";
                                        setActionBtnDisabled(false);
                                        triggerSnackBar();

                                    }
                                });
                            };

                            return (
                                <div style={{ display: 'flex', gap: '0.5rem' }}>
                                    <Button
                                        color="error"
                                        disabled={table.getSelectedRowModel().flatRows.length === 0 || actionBtnDisabled}
                                        onClick={handleDeactivation}
                                        variant="contained"
                                        size="small"
                                    >
                                        Deactivate Battery(ies)
                                    </Button>
                                    <Button
                                        color="success"
                                        disabled={table.getSelectedRowModel().flatRows.length === 0 || actionBtnDisabled}
                                        onClick={handleActivation}
                                        variant="contained"
                                        size="small"
                                    >
                                        Activate Battery(ies)
                                    </Button>
                                </div>
                            );

                        }}
                        displayColumnDefOptions={{
                            'mrt-row-actions': {
                                size: 120,
                                grow: false,
                            },
                        }}
                        enableStickyFooter
                        enableColumnResizing
                        muiSkeletonProps={{
                            animation: 'wave',
                            height: 30,
                        }}
                        muiLinearProgressProps={{
                            color: 'primary',
                        }}
                        muiPaginationProps={{
                            rowsPerPageOptions: [5, 10, 20, 30, 40, 50, 100, 150, 200, 300, 400, 600, 1000],
                            showFirstButton: true,
                            showLastButton: true,
                            color: 'success',
                            shape: 'rounded',
                            showRowsPerPage: true,
                            variant: 'outlined',
                        }}
                        paginationDisplayMode={"pages"}
                        paginateExpandedRows={true}
                        muiTableContainerProps={{
                            sx: {
                                maxHeight: '650px',
                            }
                        }}
                        enableBatchRowSelection
                        enableRowNumbers
                        rowNumberDisplayMode={'original'}
                        selectAllMode={"all"}
                        options={{
                            selection: true,
                        }}
                    />
                </div>
            </div>
        </>
    );
}
