import React, { useState, useEffect, useCallback, useRef } from "react";
import { useSubheader } from "../../_wahu/layout";
import { GoogleMap, Marker, useLoadScript, Polyline } from '@react-google-maps/api';
import { toAbsoluteUrl } from "../../_wahu/_helpers";
import { FLEET_MANAGING_BASE_URL } from "../services/baseService";
import axios from "axios";
import { VehiclesTable } from "../../_wahu/_partials/widgets";

const libraries = ['places'];
const mapContainerStyle = {
    height: "100%",
    width: "100%",
    borderRadius: "15px",
};
const center = {
    lat: 6.2414088,
    lng: 0.3311344
};
const options = {
    disableDefaultUI: true,
    zoomControl: true,
};

export const TrackingPage = () => {

    const pageErrorMessage = useRef();

    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        libraries,
    });
    const [triggerSnackBarOpen, setTriggerSnackBarOpen] = useState(false);
    const [triggerSnackBarClose, setTriggerSnackBarClose] = useState(false);
    const [showSelectAlertSection, setShowSelectAlertSection] = useState(true);
    const [selectAlertText, setSelectAlertText] = useState("");

    const suhbeader = useSubheader();
    const [bikePositions, setBikePositions] = useState([]);
    const [bikeRoutes, setBikeRoutes] = useState({});
    const [severity, setSeverity] = useState('info');

    const fetchBikeCoordinates = async (requestPayload) => {

        const url = new URL('/tracking/vehicle-locations', `${FLEET_MANAGING_BASE_URL}`);
        try {

            const response = axios.post(url.href, requestPayload);
            const data = (await response).data;
            console.log(data);

            setBikePositions(data);
            setBikeRoutes(prevRoutes => {
                const updatedRoutes = { ...prevRoutes };
                console.log(prevRoutes);
                data.forEach(bike => {
                    if (updatedRoutes[bike.vehicleID]) {
                        updatedRoutes[bike.vehicleID].push({ lat: bike.vehicleLatitude, lng: bike.vehicleLongitude });
                    } else {
                        updatedRoutes[bike.vehicleID] = [{ lat: bike.vehicleLatitude, lng: bike.vehicleLongitude }];
                    }
                });
                return updatedRoutes;
            });

        } catch (error) {

            if (error?.code === "ERR_NETWORK" || error?.code === "ECONNABORTED") {

                setSeverity("error");
                pageErrorMessage.current = "network error, please check your internet connection";
                setTriggerSnackBarOpen(true);

                return;

            }

            pageErrorMessage.current = `Code: ${error.response.status}, Message: ${error.response.data.message}`;
            setSeverity("error");
            setTriggerSnackBarOpen(true);

            return;

        }

    };

    const fetchRealTimeBikeData = useCallback(() => {
        const newPositions = [
            { id: 1, lat: 37.7749 + Math.random() * 0.01, lng: -122.4194 + Math.random() * 0.01 },
            { id: 2, lat: 37.7749 + Math.random() * 0.01, lng: -122.4194 + Math.random() * 0.01 },
        ];
        setBikePositions(newPositions);
    }, []);

    useEffect(() => {

        setSelectAlertText("Select vehicles to track");

        const load = async () => {
            try {
                suhbeader.setTitle("Vehicle Tracking");
            } catch (error) {
                // your Promise won't ever error, but an actual API might
                console.log(error);
            }
        };
        load();
        const requestPayload = {
            vehicleIDs: ['MV-0120bbaf-daac-4250-915d-f87106a89d79']
        };

        const interval = setInterval(async () => {
            fetchBikeCoordinates(requestPayload);
        }, 5000);
        return () => clearInterval(interval);

    }, [suhbeader]);

    if (loadError) return <div>Error loading maps</div>;
    if (!isLoaded) return <div>Loading Maps</div>;

    return (
        <>
            <div className="row">
                <div className="col-xxl-12">
                    <div className="card card-custom rounded-xl">
                        <div className="card-header border-0 pt-5">
                            <h3 className="card-title align-items-start flex-column">
                                <span className="card-label font-weight-bolder text-dark">
                                    Vehicle Models
                                </span>
                                <span className="text-muted mt-3 font-weight-bold font-size-sm">
                                    Manage Vehicle Models from this table
                                </span>
                            </h3>
                            <div className="card-toolbar">
                                <div className="row">

                                </div>
                            </div>
                        </div>
                        <div className="card-body pt-2 pb-0 mt-n3">
                            <div className="row">
                                <div className="col-xs-12 col-md-8">
                                    <div style={{ width: '100%', height: '600px', marginBottom: '10px' }}>
                                        <GoogleMap
                                            mapContainerStyle={mapContainerStyle}
                                            zoom={12}
                                            center={center}
                                            options={options}
                                        >
                                            {Object.entries(bikeRoutes).map(([bikeId, route]) => {
                                                return (
                                                    <React.Fragment key={bikeId}>
                                                        {/* Marker for the bike's current position */}
                                                        <Marker
                                                            position={route[route.length - 1]}  // The latest position of the bike
                                                            icon={{
                                                                url: toAbsoluteUrl("/media/svg/icons/General/BikeLocation.svg"),  // Replace with your custom bike icon URL
                                                                scaledSize: new window.google.maps.Size(40, 40),
                                                            }}
                                                        />
                                                        <Polyline
                                                            key={`${bikeId}-${route.length}`}
                                                            path={route}  // The array of coordinates (route)
                                                            options={{
                                                                strokeColor: '#ff2343',  // Line color (red for now, you can customize)
                                                                strokeOpacity: 1.0,
                                                                strokeWeight: 2,
                                                            }}
                                                        />
                                                    </React.Fragment>
                                                );

                                            })}
                                        </GoogleMap>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-md-4">
                                    {showSelectAlertSection && (<>
                                        <div className="bg-light-warning p-2 rounded-xl text-warning">
                                            <div className="centerMan">
                                                <span>
                                                    {selectAlertText}
                                                </span>
                                            </div>
                                        </div>
                                    </>)}
                                    <div className="bg-light-primary p-2 rounded-xl">
                                        <VehiclesTable />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
