import React, { useEffect } from "react";
import { Alert } from "react-bootstrap";

export function FormAlert({ isEnabled, type, message, icon, handleDismiss, className }) {

    useEffect(() => {

    }, [isEnabled]);

    if (isEnabled) {
        return (
            <>
                <div className={`${className}`}>
                    <Alert variant={type} onClose={handleDismiss} dismissible>
                        <Alert.Heading></Alert.Heading>
                        <p>
                            <i className={icon}></i>
                            {" "}
                            {message}
                        </p>
                    </Alert>
                </div>
            </>
        );
    }

    return null;

}
