export const entityTypesForDeletionArray = [
    "websiteFeedbackForm",
    "organizations",
    "vehicles",
    "driverCohorts",
    "inventoryParts",
    "consultationRequests",
    "contactUsRequest",
    "inventoryParts",
    "drivers",
];

export const riderOnboardingStepNames = [
    "ghanaCard",
    "digitalAddress",
    "infoSession",
    "payment-plan",
    "safetyVideo",
    "maintenanceVideo",
    "deposit",
    "attendInterview",
    "guarantorDetails",
    "homeVisit",
    "driverContractSigned",
    "guarantorContractSigned",
    "bikeReceived",
    "accessoriesReceived",
    "vetting",
];

export const generalFormActions = [
    "activateDriver",
    "deactivateDriver",
    "pauseSubscriptions",
    "unPauseSubscriptions",
];

export const allowedRoleList = [
    "dvla",
    "guest",
];

export const googleMapOptions = {
    mapId: "8769bc981aac3513",
    center: {
        lat: 6.2414088,
        lng: 0.3311344
    },
    zoom: 8,
    disableDefaultUI: true,
    heading: 25,
    tilt: 25,
};