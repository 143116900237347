import React, { useState, useRef, useEffect } from 'react';

import { useFormik } from "formik";
import axios from "axios";
import {
    Form,
} from 'react-bootstrap';
import * as Yup from "yup";
import {
    setDriverPaymentPlan,
    updateDriverGhanaCard,
    updateDriverGuarantorSignedContract,
    updateDriverPaymentPlan,
    updateDriverSignedContract,
    updatedDriverDigitalAddress,
    updatedGuarantorDetails
} from "../../../../app/modules/Auth/_redux/authCrud";
import { FormAlert } from '../../controls/';
import { formatDate, toAbsoluteUrl } from "../../../_helpers";
import {
    IconButton,
    Snackbar,
    Button,
    Grid,
    Alert,
    ImageListItemBar,
    ImageList,
    ImageListItem,
    Avatar,
} from '@mui/material';
import CircleTwoToneIcon from '@mui/icons-material/CircleTwoTone';
import { makeStyles } from '@mui/styles';
import HighlightOffTwoToneIcon from '@mui/icons-material/HighlightOffTwoTone';
import { SimpleModal } from "../../../../_wahu/_partials/widgets/modals/SimpleModal";
import { ConfirmationForm } from "./ConfirmationForm";
import { riderOnboardingStepNames } from "../../../_helpers/Interfaces";
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import BasicLoader from "../../loaders/SimpleLoader";
import SmsFailedTwoToneIcon from '@mui/icons-material/SmsFailedTwoTone';
import SdCardAlertTwoToneIcon from '@mui/icons-material/SdCardAlertTwoTone';
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';
import CancelTwoToneIcon from '@mui/icons-material/CancelTwoTone';
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';
import { SUPPORT_SERVICE_BASE_URL } from '../../../../app/services/baseService';
import CloseIcon from '@mui/icons-material/Close';
import ErrorTwoToneIcon from '@mui/icons-material/ErrorTwoTone';
import ChangeCircleTwoToneIcon from '@mui/icons-material/ChangeCircleTwoTone';
import BackspaceTwoToneIcon from '@mui/icons-material/BackspaceTwoTone';
import VerifiedTwoToneIcon from '@mui/icons-material/VerifiedTwoTone';

const useStyles = makeStyles(theme => ({
    margin: {
        margin: theme.spacing(2),
    },
    padding: {
        padding: theme.spacing(0, 2),
    },
    ghanaCardHeight: {
        height: "200px"
    },
    largerNumber: {
        fontSize: "18px !important",
    }
}));

function srcset(image, width, height, rows = 1, cols = 1) {
    return {
        src: `${image}?w=${width * cols}&h=${height * rows}&fit=crop&auto=format`,
        srcSet: `${image}?w=${width * cols}&h=${height * rows}&fit=crop&auto=format&dpr=2 2x`,
    };
}

export function DynamicOnboardingForm({ driverData, stateData, stepIdentifier, onChangeFunction, isChanger, modalBackControl }) {

    const classes = useStyles();
    const modalError = useRef();

    const stateDataKeyLength = Object.keys(stateData).length;

    const [loading, setLoading] = useState(false);
    const [alertType, setAlertType] = useState({
        type: '',
        icon: ''
    });
    const [isFormAlertEnabled, setFormAlertEnabled] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [confirmationModalTitle, setConfirmationModalTitle] = useState("Confirmation Prompt");
    const [upState, setUpState] = useState(false);
    const [downState, setDownState] = useState(false);
    const [reRender, setReRender] = useState(0);
    const [ghanaCardImages, setGhanaCardImages] = useState([
        {
            img: '',
            title: '',
            author: '',
        },
    ]);
    const [guarantorGhanaCardImages, setGuarantorGhanaCardImages] = useState([
        {
            img: '',
            title: '',
            author: '',
        },
    ]);
    const [imageArray, setImageArray] = useState([]);
    const [guarantorImageArray, setGuarantorImageArray] = useState([]);
    const [driverContractArray, setDriverContractArray] = useState([]);
    const [driverGuarantorContractArray, setDriverGuarantorContractArray] = useState([]);
    const [disableDriverContractSubBtn, setDisableDriverContractSubBtn] = useState(false);
    const [disableDriverGuarantorContractSubBtn, setDisableDriverGuarantorContractSubBtn] = useState(false);

    const [ghanaCardImageFiles, setGhanaCardImageFiles] = useState([]);
    const [guarantorGhanaCardImageFiles, setGuarantorGhanaCardImageFiles] = useState([]);
    const [driverContractFiles, setDriverContractFiles] = useState([]);
    const [driverGuarantorContractFiles, setDriverGuarantorContractFiles] = useState([]);

    const [toggleGhanaCardAdditionArea, setToggleGhanaCardAdditionArea] = useState(false);
    const [isUpdatingImages, setIsUpdatingImages] = useState(false);
    const [severity, setSeverity] = useState("");
    const [open, setOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [driverSubscriptionPlan, setDriverSubscriptionPlan] = useState(null);

    const [subscriptionPlans, setSubscriptionPlans] = useState([]);
    const [isSubscriptionPlansLoading, setIsSubscriptionPlansLoading] = useState(true);
    const [showSubscriptionPlansDiv, setShowSubscriptionPlansDiv] = useState(false);
    const [specDriverData, setSpecDriverData] = useState(null);
    const [stepData, setStepData] = useState(null);

    const [quizData, setQuizData] = useState(null);

    const initialDigitalAddressValues = {
        userID: driverData?.user_data?.document?.ID,
        address: stateData?.address ?? "",
        streetAddress: stateData?.streetAddress ?? "",
        district: stateData?.district ?? "",
        region: stateData?.region ?? "",
        ghanaPostGpsCode: stateData?.ghanaPostGpsCode ?? "",
        dateOfBirth: formatDate(stateData?.dateOfBirth).toString() ?? "",
        levelOfEducation: stateData?.levelOfEducation ?? "",
        mobileMoneyNumber: stateData?.mobileMoneyNumber ?? "",
    };

    const initialGuarantorDetailsValues = {
        driverID: driverData?.user_data?.document?.ID,
        firstName: stateData?.firstName ?? "",
        lastName: stateData?.lastName ?? "",
        phoneNumber: stateData?.phoneNumber ?? "",
        digitalAddress: stateData?.digitalAddress ?? "",
        email: stateData?.email ?? "",
        profession: stateData?.profession ?? "",
        businessAddress: stateData?.businessAddress ?? "",
        ghanaCardFrontImage: stateData?.ghanaCardFrontImageURL ?? "",
        ghanaCardBackImage: stateData?.ghanaCardBackImageURL ?? "",
    };

    const initialPaymentPlanValues = {
        driverID: driverData?.user_data?.document?.ID,
        subscriptionPlanID: stateData?.subscriptionPlanID ?? "",
        dailyPaymentBuffer: 0,
    };

    const initialDriverContractValues = {
        userID: driverData?.user_data?.document?.ID,
    };

    const initialDriverGuarantorContractValues = {
        userID: driverData?.user_data?.document?.ID,
    };

    const creationSchemaDigitalAddress = Yup.object().shape({
        userID: Yup.string().required(),
        address: Yup.string(),
        streetAddress: Yup.string(),
        district: Yup.string(),
        region: Yup.string().required(),
        ghanaPostGpsCode: Yup.string().required(),
        dateOfBirth: Yup.date().required(),
        levelOfEducation: Yup.string().required(),
        mobileMoneyNumber: Yup.string().required(),
    });

    const creationSchemaGuarantorDetails = Yup.object().shape({
        driverID: Yup.string().required(),
        firstName: Yup.string().required(),
        lastName: Yup.string().required(),
        phoneNumber: Yup.string().required(),
        digitalAddress: Yup.string().required(),
        email: Yup.string(),
        profession: Yup.string().required(),
        businessAddress: Yup.string().required(),
    });

    const paymentPlanCreationSchema = Yup.object().shape({
        driverID: Yup.string().required(),
        subscriptionPlanID: Yup.string().required(),
        dailyPaymentBuffer: Yup.number(),
    });

    const driverContractCreationSchema = Yup.object().shape({
        userID: Yup.string().required(),
    });

    const driverGuarantorContractCreationSchema = Yup.object().shape({
        userID: Yup.string().required(),
    });

    const enableLoading = () => {
        setLoading(true);
    };

    const disableLoading = () => {
        setLoading(false);
    };

    const handleAlertDismiss = () => {
        setFormAlertEnabled(false);
    };

    const toggleCardAdditionArea = () => {
        setToggleGhanaCardAdditionArea(prevState => !prevState);
    };

    const getDigitalAddressInputClasses = (fieldname) => {

        if (updateDigitalAddressFormik.touched[fieldname] && updateDigitalAddressFormik.errors[fieldname]) {
            return "is-invalid";
        }

        if (updateDigitalAddressFormik.touched[fieldname] && !updateDigitalAddressFormik.errors[fieldname]) {
            return "is-valid";
        }

        return "";

    };

    const getGuarantorDetailsInputClasses = (fieldname) => {

        if (guarantorDetailsFormik.touched[fieldname] && guarantorDetailsFormik.errors[fieldname]) {
            return "is-invalid";
        }

        if (guarantorDetailsFormik.touched[fieldname] && !guarantorDetailsFormik.errors[fieldname]) {
            return "is-valid";
        }

        return "";

    };

    const getDriverContractInputClasses = (fieldname) => {

        if (driverContractFormik.touched[fieldname] && driverContractFormik.errors[fieldname]) {
            return "is-invalid";
        }

        if (driverContractFormik.touched[fieldname] && !driverContractFormik.errors[fieldname]) {
            return "is-valid";
        }

        return "";

    };

    const changePaymentPlanClasses = (fieldname) => {

        if (paymentPlanFormik.touched[fieldname] && paymentPlanFormik.errors[fieldname]) {
            return "is-invalid";
        }

        if (paymentPlanFormik.touched[fieldname] && !paymentPlanFormik.errors[fieldname]) {
            return "is-valid";
        }

        return "";

    };

    const guarantorDetailsFormik = useFormik({
        initialValues: initialGuarantorDetailsValues,
        validationSchema: creationSchemaGuarantorDetails,
        onSubmit: (values, { setStatus, setSubmitting }) => {

            enableLoading();
            setTimeout(() => {

                if (guarantorGhanaCardImageFiles && guarantorGhanaCardImageFiles[0] && guarantorGhanaCardImageFiles[0].length >= 1 && guarantorGhanaCardImageFiles[0].length < 2) {

                    setAlertType({
                        type: 'danger',
                        icon: 'flaticon-warning mana-icon-white'
                    });
                    setStatus("you cannot upload only one side of guarantor's Ghana card");

                    disableLoading();
                    setSubmitting(false);

                    return setFormAlertEnabled(true);

                }

                const driverOnboardingGuarantorPayload = {
                    driverID: values.driverID,
                    firstName: values.firstName,
                    lastName: values.lastName,
                    phoneNumber: values.phoneNumber,
                    digitalAddress: values.digitalAddress,
                    email: values.email,
                    profession: values.profession,
                    businessAddress: values.businessAddress,
                    ghanaCardFrontImage: guarantorGhanaCardImageFiles.length >= 1 ? guarantorGhanaCardImageFiles[0][0] : null,
                    ghanaCardBackImage: guarantorGhanaCardImageFiles.length >= 1 ? guarantorGhanaCardImageFiles[0][1] : null,
                };

                updatedGuarantorDetails(driverOnboardingGuarantorPayload).then((response) => {

                    disableLoading();
                    setSubmitting(false);

                    if (response) {

                        setAlertType({
                            type: 'success',
                            icon: 'fa fa-check-circle mana-icon-white'
                        });
                        setStatus(`${response.data.message}, please wait...`);
                        setFormAlertEnabled(true);

                        setTimeout(() => {
                            onChangeFunction();
                            return isChanger();
                        }, 4000);

                    }

                }).catch((error) => {

                    setAlertType({
                        type: 'danger',
                        icon: 'flaticon-warning mana-icon-white'
                    });
                    setFormAlertEnabled(true);
                    setStatus(
                        error.response.data?.error?.code
                        ??
                        error.response.data.message
                        ??
                        'an error occurred, please try again later'
                    );

                    setSubmitting(false);
                    return disableLoading();

                });

            }, 1000);
        }
    });

    const driverContractFormik = useFormik({
        initialValues: initialDriverContractValues,
        validationSchema: driverContractCreationSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {

            enableLoading();
            setTimeout(() => {

                const driverOnboardingGuarantorPayload = {
                    userID: values.userID,
                    file: driverContractFiles.length >= 1 ? driverContractFiles[0][0] : null,
                };

                updateDriverSignedContract(driverOnboardingGuarantorPayload).then((response) => {

                    disableLoading();
                    setSubmitting(false);

                    if (response) {

                        setAlertType({
                            type: 'success',
                            icon: 'fa fa-check-circle mana-icon-white'
                        });
                        setStatus(response.data.message);

                        setFormAlertEnabled(true);

                        onChangeFunction();
                        return isChanger();

                    }

                }).catch((error) => {

                    setAlertType({
                        type: 'danger',
                        icon: 'flaticon-warning mana-icon-white'
                    });
                    setStatus(
                        error.response.data?.error?.code
                        ??
                        error.response.data.message
                        ??
                        'an error occurred, please try again later'
                    );
                    setFormAlertEnabled(true);

                    setSubmitting(false);
                    return disableLoading();

                });

            }, 1000);
        }
    });

    const driverGuarantorContractFormik = useFormik({
        initialValues: initialDriverGuarantorContractValues,
        validationSchema: driverGuarantorContractCreationSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {

            enableLoading();
            setTimeout(() => {

                const driverGuarantorContractPayload = {
                    userID: values.userID,
                    file: driverContractFiles.length >= 1 ? driverContractFiles[0][0] : null,
                };

                updateDriverGuarantorSignedContract(driverGuarantorContractPayload).then((response) => {

                    disableLoading();
                    setSubmitting(false);

                    if (response) {

                        setAlertType({
                            type: 'success',
                            icon: 'fa fa-check-circle mana-icon-white'
                        });
                        setStatus(response.data.message);

                        setFormAlertEnabled(true);

                        onChangeFunction();
                        return isChanger();

                    }

                }).catch((error) => {

                    setAlertType({
                        type: 'danger',
                        icon: 'flaticon-warning mana-icon-white'
                    });
                    setStatus(
                        error.response.data?.error?.code
                        ??
                        error.response.data.message
                        ??
                        'an error occurred, please try again later'
                    );
                    setFormAlertEnabled(true);

                    setSubmitting(false);
                    return disableLoading();

                });

            }, 1000);
        }
    });

    const updateDigitalAddressFormik = useFormik({
        initialValues: initialDigitalAddressValues,
        validationSchema: creationSchemaDigitalAddress,
        onSubmit: (values, { setStatus, setSubmitting }) => {

            enableLoading();
            setSubmitting(true);

            setTimeout(() => {

                const driverOnboardingDigitalAddressPayload = {
                    userID: values.userID,
                    address: values.address,
                    streetAddress: values.streetAddress,
                    district: values.district,
                    region: values.region,
                    ghanaPostGpsCode: values.ghanaPostGpsCode,
                    dateOfBirth: new Date(values.dateOfBirth).getTime(),
                    levelOfEducation: values.levelOfEducation,
                    mobileMoneyNumber: values.mobileMoneyNumber,
                };

                updatedDriverDigitalAddress(driverOnboardingDigitalAddressPayload).then((response) => {

                    disableLoading();
                    setSubmitting(false);

                    if (response) {

                        setFormAlertEnabled(true);

                        setAlertType({
                            type: 'success',
                            icon: 'fa fa-check-circle mana-icon-white'
                        });
                        onChangeFunction();
                        isChanger();
                        return setStatus(response.data.message);

                    }

                }).catch((error) => {

                    setFormAlertEnabled(true);

                    setAlertType({
                        type: 'danger',
                        icon: 'flaticon-warning mana-icon-white'
                    });

                    return setStatus(
                        error.response.data?.error?.code
                        ??
                        error.response.data.message
                        ??
                        'an error occurred, please try again later'
                    );

                }).finally(() => {
                    disableLoading();
                    setSubmitting(false);
                    isChanger();
                    return onChangeFunction();
                });

                return;

            }, 1000);
        },
    });

    const paymentPlanFormik = useFormik({
        initialValues: initialPaymentPlanValues,
        validationSchema: paymentPlanCreationSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {

            enableLoading();
            setSubmitting(true);

            setTimeout(async () => {

                if (driverData?.user_data?.document?.subscriptionPlan?.ID) {

                    const changePaymentPlanPayload = {
                        driverID: values.driverID,
                        newSubscriptionPlanID: values.subscriptionPlanID,
                        oldSubscriptionPlanID: driverData?.user_data?.document?.subscriptionPlan?.ID,
                        dailyPaymentBuffer: values.dailyPaymentBuffer,
                    };

                    return updateDriverPaymentPlan(changePaymentPlanPayload).then((response) => {

                        if (response) {

                            setAlertType({
                                type: 'success',
                                icon: 'fa fa-check-circle mana-icon-white'
                            });
                            setStatus(response.data.message);
                            setFormAlertEnabled(true);

                            disableLoading();
                            setSubmitting(false);
                            onChangeFunction();
                            return isChanger();

                        }

                    }).catch((error) => {

                        setAlertType({
                            type: 'danger',
                            icon: 'flaticon-warning mana-icon-white'
                        });
                        setStatus(
                            error.response.data?.error?.code
                            ??
                            error.response.data.message
                            ??
                            'an error occurred, please try again later'
                        );
                        setFormAlertEnabled(true);

                        disableLoading();
                        return setSubmitting(false);

                    });

                }

                const setPaymentPlanPayload = {
                    driverID: values.driverID,
                    subscriptionPlanID: values.subscriptionPlanID,
                };

                return setDriverPaymentPlan(setPaymentPlanPayload).then((response) => {

                    if (response) {

                        setAlertType({
                            type: 'success',
                            icon: 'fa fa-check-circle mana-icon-white'
                        });
                        setStatus(response.data.message);
                        setFormAlertEnabled(true);

                        disableLoading();
                        setSubmitting(false);
                        onChangeFunction();
                        return isChanger();

                    }

                }).catch((error) => {

                    setAlertType({
                        type: 'danger',
                        icon: 'flaticon-warning mana-icon-white'
                    });
                    setStatus(
                        error.response.data?.error?.code
                        ??
                        error.response.data.message
                        ??
                        'an error occurred, please try again later'
                    );
                    setFormAlertEnabled(true);

                    disableLoading();
                    return setSubmitting(false);

                });

            }, 1000);
        },
    });

    const getUserPic = () => {
        if (!specDriverData?.user_data?.document?.profileImageURL) {
            return "none";
        }

        return `url(${specDriverData.user_data.document.profileImageURL})`;
    };

    const handleNewProfileImage = (event) => {

        let files = event.target.files;
        ghanaCardImageFiles.push(files);

        const partImageArray = [];
        partImageArray.push(...imageArray);

        if (files) {

            for (let i = 0; i < files.length; i++) {

                const fileObject = files[i];
                const file = URL.createObjectURL(fileObject);
                partImageArray.push(file);

                if ((i + 1) === files.length) {
                    setImageArray(partImageArray);
                }

            }

        }

    };

    const handleGuarantorGhanaCardImage = (event) => {

        let files = event.target.files;
        guarantorGhanaCardImageFiles.push(files);

        const partImageArray = [];
        partImageArray.push(...guarantorImageArray);

        if (files) {

            for (let i = 0; i < files.length; i++) {

                const fileObject = files[i];
                const file = URL.createObjectURL(fileObject);
                partImageArray.push(file);

                if ((i + 1) === files.length) {
                    setGuarantorImageArray(partImageArray);
                }

            }

        }

    };

    const handleDriverContractFile = (event) => {

        let files = event.target.files;
        const fileObject = files[0];
        const fileType = fileObject.type;

        if (fileType !== 'application/pdf') {

            setAlertType({
                type: 'danger',
                icon: 'flaticon-warning mana-icon-white'
            });
            modalError.current = "only pdf files are allowed";
            setFormAlertEnabled(true);

            setDriverContractArray([]);
            setDriverContractFiles([]);
            setDisableDriverContractSubBtn(true);

            return;

        }

        if (fileType === 'application/pdf') {

            setFormAlertEnabled(false);

            driverContractFiles.push(files);
            const partImageArray = [];
            partImageArray.push(...driverContractArray);
            setDisableDriverContractSubBtn(false);

            if (files) {

                for (let i = 0; i < files.length; i++) {

                    const fileObject = files[i];

                    const file = URL.createObjectURL(fileObject);
                    partImageArray.push(file);

                    if ((i + 1) === files.length) {
                        setDriverContractArray(partImageArray);
                    }

                }

            }

        }

    };

    const handleDriverGuarantorContractFile = (event) => {

        let files = event.target.files;
        const fileObject = files[0];
        const fileType = fileObject.type;

        if (fileType !== 'application/pdf') {

            setAlertType({
                type: 'danger',
                icon: 'flaticon-warning mana-icon-white'
            });
            modalError.current = "only pdf files are allowed";
            setFormAlertEnabled(true);

            setDriverGuarantorContractArray([]);
            setDriverGuarantorContractFiles([]);
            setDisableDriverGuarantorContractSubBtn(true);

            return;

        }

        if (fileType === 'application/pdf') {

            setFormAlertEnabled(false);

            driverContractFiles.push(files);
            const partImageArray = [];
            partImageArray.push(...driverContractArray);
            setDisableDriverGuarantorContractSubBtn(false);

            if (files) {

                for (let i = 0; i < files.length; i++) {

                    const fileObject = files[i];

                    const file = URL.createObjectURL(fileObject);
                    partImageArray.push(file);

                    if ((i + 1) === files.length) {
                        setDriverGuarantorContractArray(partImageArray);
                    }

                }

            }

        }

    };

    function handleSnackbarClose(event, reason) {
        if (reason === 'clickaway')
            return;
        setOpen(false);
    }

    useEffect(() => {

        let isMounted = true;
        const subscriptionFetchController = new AbortController();
        const subscriptionPlansFetchController = new AbortController();

        const driverContractDataFetchController = new AbortController();
        const driverGuarantorContractDataFetchController = new AbortController();

        const quizDataFetchController = new AbortController();

        if (stepIdentifier === riderOnboardingStepNames[0]) {

            isMounted && setGhanaCardImages([
                {
                    img: stateData?.idCardFrontURL,
                    title: 'Front Image',
                    author: `${driverData?.user_data?.document?.firstName} ${driverData?.user_data?.document?.lastName}`,
                },
                {
                    img: stateData?.idCardBackURL,
                    title: 'Back Image',
                    author: `${driverData?.user_data?.document?.firstName} ${driverData?.user_data?.document?.lastName}`,
                }
            ]);

            isMounted && setIsLoading(false);

        }

        if (stepIdentifier === riderOnboardingStepNames[1]) {
            setIsLoading(false);
        }

        if (stepIdentifier === riderOnboardingStepNames[2]) {
            setIsLoading(false);
        }

        if (stepIdentifier === riderOnboardingStepNames[3]) {

            const subscriptionPlanID = stateData?.subscriptionPlanID;
            const fetchSubscriptionPlan = async () => {

                const url = new URL(`/riderSubscriptionPlans/getPlanById/${subscriptionPlanID}`, `${SUPPORT_SERVICE_BASE_URL}`);
                try {

                    const response = axios.get(url.href, {
                        signal: subscriptionFetchController.signal,
                    });
                    const data = (await response).data;

                    setDriverSubscriptionPlan(data);
                    setIsLoading(false);

                } catch (error) {

                    if (error?.code === "ERR_NETWORK" || error?.code === "ECONNABORTED") {

                        setSeverity("error");
                        modalError.current = "network error, please check your internet connection";
                        setOpen(true);

                        return setIsLoading(false);

                    }

                    modalError.current = `Code: ${error?.response?.status}, Message: ${error?.response?.data?.message}`;
                    isMounted && setSeverity("error");
                    setOpen(true);

                    return setIsLoading(false);;

                }

            };

            const fetchSubscriptionPlans = async () => {

                const url = new URL('/riderSubscriptionPlans/getAll', `${SUPPORT_SERVICE_BASE_URL}`);
                try {

                    const response = axios.get(url.href, {
                        signal: subscriptionPlansFetchController.signal,
                    });
                    const data = (await response).data;

                    setSubscriptionPlans(data);
                    setIsSubscriptionPlansLoading(false);

                } catch (error) {

                    if (error?.code === "ERR_NETWORK" || error?.code === "ECONNABORTED") {

                        setSeverity("error");
                        modalError.current = "network error, please check your internet connection";
                        setOpen(true);

                        return setIsSubscriptionPlansLoading(false);

                    }

                    modalError.current = `Code: ${error?.response?.status}, Message: ${error?.response?.data?.message}`;
                    isMounted && setSeverity("error");
                    setOpen(true);

                    return setIsSubscriptionPlansLoading(false);;

                }

            };

            fetchSubscriptionPlan();
            fetchSubscriptionPlans();

        }

        if (stepIdentifier === riderOnboardingStepNames[4]) {
            setIsLoading(false);
        }

        if (stepIdentifier === riderOnboardingStepNames[5]) {
            setIsLoading(false);
        }

        if (stepIdentifier === riderOnboardingStepNames[6]) {
            setIsLoading(false);
        }

        if (stepIdentifier === riderOnboardingStepNames[7]) {
            setIsLoading(false);
        }

        if (stepIdentifier === riderOnboardingStepNames[8]) {

            isMounted && setGuarantorGhanaCardImages([
                {
                    img: stateData?.ghanaCardFrontImageURL,
                    title: 'Front Image',
                    author: `${stateData?.firstName} ${stateData?.lastName}`,
                },
                {
                    img: stateData?.ghanaCardBackImageURL,
                    title: 'Back Image',
                    author: `${stateData?.firstName} ${stateData?.lastName}`,
                }
            ]);

            isMounted && setIsLoading(false);

        }

        if (stepIdentifier === riderOnboardingStepNames[9]) {
            setIsLoading(false);
        }

        if (stepIdentifier === riderOnboardingStepNames[10]) {

            const fetchDriverContractData = async () => {

                const url = new URL(`/users/onboarding/driver-contract/${driverData?.user_data?.document?.ID}`, `${SUPPORT_SERVICE_BASE_URL}`);
                try {

                    const response = axios.get(url.href, {
                        signal: driverContractDataFetchController.signal,
                    });
                    const data = (await response).data;

                    data?.document?.driverContractDocURL ? setDriverContractArray([data]) : setDriverContractArray([]);
                    setIsLoading(false);

                } catch (error) {

                    if (error?.code === "ERR_NETWORK" || error?.code === "ECONNABORTED") {

                        setSeverity("error");
                        modalError.current = "network error, please check your internet connection";
                        setOpen(true);

                        return setIsLoading(false);

                    }

                    modalError.current = `Code: ${error?.response?.status}, Message: ${error?.response?.data?.message}`;
                    isMounted && setSeverity("error");
                    setOpen(true);

                    return setIsLoading(false);

                }

            };

            isMounted && fetchDriverContractData();

        }

        if (stepIdentifier === riderOnboardingStepNames[11]) {

            const fetchDriverGuarantorContractData = async () => {

                const url = new URL(`/users/onboarding/driver-guarantor-contract/${driverData?.user_data?.document?.ID}`, `${SUPPORT_SERVICE_BASE_URL}`);
                try {

                    const response = axios.get(url.href, {
                        signal: driverGuarantorContractDataFetchController.signal,
                    });
                    const data = (await response).data;

                    data?.document?.guarantorContractDocURL ? setDriverGuarantorContractArray([data]) : setDriverGuarantorContractArray([]);
                    setIsLoading(false);

                } catch (error) {

                    if (error?.code === "ERR_NETWORK" || error?.code === "ECONNABORTED") {

                        setSeverity("error");
                        modalError.current = "network error, please check your internet connection";
                        setOpen(true);

                        return setIsLoading(false);

                    }

                    modalError.current = `Code: ${error?.response?.status}, Message: ${error?.response?.data?.message}`;
                    isMounted && setSeverity("error");
                    setOpen(true);

                    return setIsLoading(false);

                }

            };

            isMounted && fetchDriverGuarantorContractData();

        }

        if (stepIdentifier === riderOnboardingStepNames[12]) {
            setIsLoading(false);
        }

        if (stepIdentifier === riderOnboardingStepNames[13]) {
            setIsLoading(false);
        }

        if (stepIdentifier === riderOnboardingStepNames[14]) {

            const fetchQuizData = async () => {

                const url = new URL(`/quizzes/${stateData.quizID}`, `${SUPPORT_SERVICE_BASE_URL}`);
                try {

                    const response = axios.get(url.href, {
                        signal: quizDataFetchController.signal,
                    });
                    const data = (await response).data;
                    console.log(data);

                    setQuizData(data);
                    setIsLoading(false);

                } catch (error) {

                    if (error?.code === "ERR_NETWORK" || error?.code === "ECONNABORTED") {

                        setSeverity("error");
                        modalError.current = "network error, please check your internet connection";
                        setOpen(true);

                        return setIsLoading(false);

                    }

                    modalError.current = `Code: ${error?.response?.status}, Message: ${error?.response?.data?.message}`;
                    isMounted && setSeverity("error");
                    setOpen(true);

                    return setIsLoading(false);

                }

            };

            isMounted && fetchQuizData();

        }

        isMounted && setTimeout(() => {
            setSpecDriverData(driverData);
            setStepData(stateData);
        }, 2000);

        return () => {

            isMounted = false;
            subscriptionFetchController.abort();
            subscriptionPlansFetchController.abort();

            driverGuarantorContractDataFetchController.abort();
            driverContractDataFetchController.abort();

            quizDataFetchController.abort();

            setDriverSubscriptionPlan(null);
            setSubscriptionPlans([]);

            setGhanaCardImages([]);
            setGuarantorGhanaCardImages([]);

            setDriverContractArray([]);
            setDriverGuarantorContractArray([]);

        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        driverData,
        stateData,
        reRender,
        stateDataKeyLength,
        stepIdentifier,
    ]);

    return (
        <>

            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={open}
                autoHideDuration={8000}
                onClose={handleSnackbarClose}
                ContentProps={{
                    'aria-describedby': 'message-id',
                }}
                action={[
                    <Button key="undo" color="primary" size="small" onClick={handleSnackbarClose}>
                        UNDO
                    </Button>,
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        className={classes.close}
                        onClick={handleSnackbarClose}
                    >
                        <CloseIcon />
                    </IconButton>
                ]}
            >
                <Alert
                    elevation={6}
                    variant="filled"
                    severity={severity}
                    onClose={handleSnackbarClose}
                >
                    <span id="message-id">
                        {modalError.current}
                    </span>
                </Alert>
            </Snackbar>
            <SimpleModal
                modal_tittle={confirmationModalTitle}
                close={() => setShowConfirmationModal(false)}
                size="lg"
                show={showConfirmationModal}
                body={<ConfirmationForm
                    modalBackControl={() => {
                        setShowConfirmationModal(false);
                        modalBackControl();
                    }}
                    noBackModalRouter={() => {
                        setShowConfirmationModal(false);
                    }}
                    passedData={specDriverData}
                    stepIdentifier={stepIdentifier}
                    upState={upState}
                    downState={downState}
                    onChangerFunction={onChangeFunction}
                    isChanger={isChanger}
                />}
            />

            {!isLoading && specDriverData && stepData && <>
                <div className="form-group row">
                    <div className="col-lg-12 col-xl-12">
                        <div className="d-flex justify-content-end">
                            <span
                                onClick={() => {
                                    modalBackControl();
                                }}
                                className='cursor-pointer p-2 bg-light-danger rounded'
                            >
                                <BackspaceTwoToneIcon
                                    color='error'
                                />
                                {" "}
                                Go back
                            </span>
                        </div>
                        <div className="centerMan">
                            <div
                                className="image-input-wrapper image-input image-input-outline"
                                id="kt_profile_avatar"
                                style={{
                                    backgroundImage: `url(${specDriverData?.user_data?.document?.profileImageURL ?? toAbsoluteUrl("/media/users/blank.png")}`,
                                }}
                            >
                                <div
                                    className="image-input-wrapper"
                                    style={{ backgroundImage: `${getUserPic()}` }}
                                />
                            </div>
                            <div className="mt-1">
                                <div className="font-weight-bolder font-size-h5 text-dark-75 centerMan">
                                    {specDriverData?.user_data?.document?.firstName}
                                    {" "}
                                    {specDriverData?.user_data?.document?.lastName}
                                </div>
                                <div className='row'>
                                    <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                                        <div className="text-muted centerMan">
                                            <span>
                                                {specDriverData?.user_data?.document?.phone ?
                                                    (
                                                        <>
                                                            <a className='text-dark-65' href={`tel: ${specDriverData?.user_data?.document?.phone}`}>
                                                                {specDriverData?.user_data?.document?.phone}
                                                            </a>
                                                        </>
                                                    )
                                                    : specDriverData?.user_data?.document?.phoneNumber ? (
                                                        <>
                                                            <a className='text-dark-65' href={`tel: ${specDriverData?.user_data?.document?.phoneNumber}`}>
                                                                {specDriverData?.user_data?.document?.phoneNumber}
                                                            </a>
                                                        </>
                                                    )
                                                        :
                                                        (
                                                            <>
                                                                <SdCardAlertTwoToneIcon
                                                                    color="error"
                                                                    fontSize="large"
                                                                />
                                                                <span className='text-danger'>
                                                                    no phone number added
                                                                </span>
                                                            </>
                                                        )
                                                }
                                            </span>
                                        </div>
                                    </div>
                                    <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                                        <div className="text-muted centerMan">
                                            <span>
                                                {specDriverData?.user_data?.document?.email ?
                                                    (
                                                        <>
                                                            <a className='text-dark-65' href={`mailto: ${specDriverData.user_data.document.email}`}>
                                                                {specDriverData.user_data.document.email}
                                                            </a>
                                                        </>
                                                    )
                                                    : (
                                                        <>
                                                            <SmsFailedTwoToneIcon
                                                                color="error"
                                                                fontSize="large"
                                                            />
                                                        </>
                                                    )
                                                }
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-1 row">
                                    {stepData && stepData?.state ?
                                        <>
                                            <div className='col'>
                                                <div className='p-2 rounded-lg bg-light-success'>
                                                    <div className='centerMan'>
                                                        <span>
                                                            <span className='bold'>
                                                                {`${stepData.title}:`}
                                                            </span>
                                                            {" "}
                                                            {stepData?.updatedAt ? new Date(stepData.updatedAt).toUTCString() : stepData?.createdAt ? new Date(stepData.createdAt).toUTCString() : '-'}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                        :
                                        <>
                                            <div className='col'>
                                                <div className='pl-2 pr-2 rounded-lg bg-light-danger'>
                                                    <div className='centerMan'>
                                                        <span>
                                                            <span className='bold'>
                                                                {`${stepData.title}:`}
                                                            </span>
                                                            {" "}
                                                            <span className="text-danger">
                                                                Not Complete
                                                            </span>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="card mb-5 rounded-xl">
                    <div className="card-body">
                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} className="mb-4">
                            <Grid item sm={6} md={6} xs={12}>
                                <div className="centerMan">
                                    <Button
                                        variant="contained"
                                        size="medium"
                                        color="success"
                                        onClick={async () => {

                                            setConfirmationModalTitle("Mark Step as Completed");
                                            setShowConfirmationModal(true);
                                            setUpState(true);

                                        }}
                                        disabled={stepData.state ? true : false}
                                        startIcon={<CheckCircleTwoToneIcon />}
                                    >
                                        Mark step as done
                                    </Button>
                                </div>
                            </Grid>
                            <Grid item sm={6} md={6} xs={12}>
                                <div className="centerMan">
                                    <Button
                                        variant="contained"
                                        size="medium"
                                        color="error"
                                        aria-label="Add"
                                        onClick={() => {

                                            setConfirmationModalTitle("Mark Step as Incomplete");
                                            setShowConfirmationModal(true);
                                            setDownState(true);

                                        }}
                                        disabled={stepData.state ? false : true}
                                        startIcon={<CancelTwoToneIcon />}
                                    >
                                        Mark step as not done
                                    </Button>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </>}

            {(isLoading || !specDriverData || !stepData) && (<>
                <div className='centerMan'>
                    <BasicLoader
                        size={'lg'}
                    />
                </div>
            </>)}

            {/* Ghana Card */}
            {
                !isLoading && specDriverData && stepData && stepIdentifier === riderOnboardingStepNames[0] && (
                    <>
                        <div className="card card-custom rounded-xl mb-2">
                            <div className="card-header border-0 pt-5">
                                <h3 className="card-title font-weight-bolder">
                                    Rider's Ghana Card
                                </h3>
                            </div>
                            <div className="card-body">
                                <div className="d-flex justify-content-end">
                                    <Button
                                        variant="contained"
                                        size="medium"
                                        color={toggleGhanaCardAdditionArea ? "error" : "success"}
                                        aria-label="Add"
                                        onClick={() => {
                                            toggleCardAdditionArea();
                                        }}
                                        disabled={loading ? true : false}
                                        startIcon={toggleGhanaCardAdditionArea ?
                                            (<>
                                                <CancelTwoToneIcon />
                                            </>) :
                                            (<>
                                                <EditTwoToneIcon />
                                            </>)
                                        }
                                    >
                                        {toggleGhanaCardAdditionArea ?
                                            (<>
                                                <span>
                                                    Cancel Edit
                                                </span>
                                            </>) :
                                            (<>
                                                <span>
                                                    Update rider's Ghana card
                                                </span>
                                            </>)
                                        }
                                    </Button>
                                </div>
                                {toggleGhanaCardAdditionArea && <div className="form-group row">
                                    <div className="col-lg-12 col-xl-12">
                                        {(!imageArray || imageArray.length <= 0) && (
                                            <>
                                                <div className="centerMan">

                                                    <div className="col-xs-12 col-sm-12 col-md-12 mt-1 mb-3">
                                                        <div className="centerMan text-info">
                                                            <Alert
                                                                icon={
                                                                    <InfoTwoToneIcon fontSize="inherit" />
                                                                }
                                                                severity="info"
                                                            >
                                                                <span className='bold'>
                                                                    Choose front image first, then back image for Ghana card
                                                                </span>
                                                            </Alert>
                                                        </div>
                                                    </div>

                                                    <div
                                                        className="image-input image-input-outline"
                                                        id="kt_profile_avatar"
                                                    >
                                                        <div
                                                            className="image-input-wrapper"
                                                            style={{ backgroundImage: "none" }}
                                                        />
                                                        <label
                                                            className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                                                            data-action="change"
                                                            data-toggle="tooltip"
                                                            title=""
                                                            data-original-title="Change avatar"
                                                        >
                                                            <i className="fa fa-pen icon-sm text-muted"></i>
                                                            <input
                                                                type="file"
                                                                name="pic"
                                                                accept="image/*"
                                                                multiple
                                                                onChange={e => handleNewProfileImage(e)}
                                                            />
                                                            <input type="hidden" name="profile_avatar_remove" />
                                                        </label>
                                                        <span
                                                            className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                                                            data-action="cancel"
                                                            data-toggle="tooltip"
                                                            title=""
                                                            data-original-title="Cancel avatar"
                                                        >
                                                            <i className="ki ki-bold-close icon-xs text-muted"></i>
                                                        </span>
                                                        <span
                                                            onClick={() => {
                                                                setImageArray([]);
                                                            }}
                                                            className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                                                            data-action="remove"
                                                            data-toggle="tooltip"
                                                            title=""
                                                            data-original-title="Remove avatar"
                                                        >
                                                            <i className="ki ki-bold-close icon-xs text-muted"></i>
                                                        </span>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                        <div className="m-0">
                                            {imageArray && imageArray.length > 0 &&
                                                (
                                                    <>
                                                        <div className="d-flex flex-column w-100 centerMan">
                                                            <div className='d-flex align-items-center'>
                                                                {imageArray.map((image, index) =>
                                                                    <div className='p-2' key={index}>
                                                                        <div className="image-input image-input-outline" id="kt_profile_avatar">
                                                                            <div className="image-input-wrapper" style={{ backgroundImage: `url(${image})` }} />
                                                                            <label
                                                                                className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                                                                                data-action="change"
                                                                                data-toggle="tooltip"
                                                                                title=""
                                                                                data-original-title="Change avatar"
                                                                            >
                                                                                <i className="fa fa-pen icon-sm text-muted"></i>
                                                                                <input
                                                                                    type="file"
                                                                                    name="pic"
                                                                                    accept="image/*"
                                                                                    multiple
                                                                                    onChange={e => handleNewProfileImage(e)}
                                                                                />
                                                                                <input type="hidden" name="profile_avatar_remove" />
                                                                            </label>
                                                                            <span
                                                                                className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                                                                                data-action="cancel"
                                                                                data-toggle="tooltip"
                                                                                title=""
                                                                                data-original-title="Cancel avatar"
                                                                            >
                                                                                <i className="ki ki-bold-close icon-xs text-muted"></i>
                                                                            </span>
                                                                            <span
                                                                                onClick={() => {

                                                                                    if (index > -1) {

                                                                                        const currentImageArray = imageArray;
                                                                                        const currentImageFileArray = ghanaCardImageFiles;

                                                                                        currentImageFileArray.splice(index, 1);
                                                                                        currentImageArray.splice(index, 1);

                                                                                        setImageArray(currentImageArray);
                                                                                        setGhanaCardImageFiles(currentImageFileArray);

                                                                                        setReRender(prevState => prevState + 1);

                                                                                    }

                                                                                }}
                                                                                className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                                                                                data-action="remove"
                                                                                data-toggle="tooltip"
                                                                                title=""
                                                                                data-original-title="Remove avatar"
                                                                            >
                                                                                <i className="ki ki-bold-close icon-xs text-muted"></i>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>

                                                            <div className="col-xs-12 col-sm-12 col-md-12 mt-8">
                                                                <p className="centerMan text-info">
                                                                    Note that the images selected will replace already uploaded Ghana Card images below
                                                                </p>
                                                            </div>

                                                            <div className="col-xs-12 col-sm-12 col-md-12 mt-1">
                                                                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} className="mb-4">
                                                                    <Grid item xs={6}>
                                                                        <div className="centerMan">
                                                                            <Button
                                                                                variant="contained"
                                                                                size="medium"
                                                                                color="error"
                                                                                aria-label="Add"
                                                                                onClick={() => {
                                                                                    toggleCardAdditionArea();
                                                                                }}
                                                                                disabled={isUpdatingImages}
                                                                            >
                                                                                Cancel
                                                                            </Button>
                                                                        </div>
                                                                    </Grid>
                                                                    <Grid item xs={6}>
                                                                        <div className="centerMan">
                                                                            <Button
                                                                                variant="contained"
                                                                                size="medium"
                                                                                color="success"
                                                                                aria-label="Add"
                                                                                onClick={() => {

                                                                                    setIsUpdatingImages(true);

                                                                                    if (ghanaCardImageFiles[0].length > 2) {

                                                                                        setAlertType({
                                                                                            type: 'danger',
                                                                                            icon: 'flaticon-warning mana-icon-white'
                                                                                        });
                                                                                        modalError.current = "you cannot upload more than two files";
                                                                                        setFormAlertEnabled(true);

                                                                                        return setIsUpdatingImages(false);

                                                                                    }

                                                                                    if (ghanaCardImageFiles[0].length < 2) {

                                                                                        setAlertType({
                                                                                            type: 'danger',
                                                                                            icon: 'flaticon-warning mana-icon-white'
                                                                                        });
                                                                                        modalError.current = "you cannot upload only one side of the Ghana card";
                                                                                        setFormAlertEnabled(true);

                                                                                        return setIsUpdatingImages(false);

                                                                                    }

                                                                                    const values = {
                                                                                        userID: specDriverData?.user_data?.document?.ID,
                                                                                        idCardFront: ghanaCardImageFiles[0][0],
                                                                                        idCardBack: ghanaCardImageFiles[0][1],
                                                                                    };

                                                                                    updateDriverGhanaCard(values).then((response) => {

                                                                                        if (response) {

                                                                                            setFormAlertEnabled(true);

                                                                                            setAlertType({
                                                                                                type: 'success',
                                                                                                icon: 'fa fa-check-circle mana-icon-white'
                                                                                            });

                                                                                            onChangeFunction();
                                                                                            isChanger();
                                                                                            modalError.current = response.data.message;
                                                                                            setFormAlertEnabled(true);
                                                                                            setIsUpdatingImages(false);

                                                                                            setTimeout(() => {
                                                                                                return modalBackControl();
                                                                                            }, 2000);

                                                                                        }

                                                                                    }).catch((error) => {

                                                                                        setFormAlertEnabled(true);

                                                                                        setAlertType({
                                                                                            type: 'danger',
                                                                                            icon: 'flaticon-warning mana-icon-white'
                                                                                        });

                                                                                        onChangeFunction();
                                                                                        isChanger();
                                                                                        modalError.current = error?.response?.data?.error?.code
                                                                                            ??
                                                                                            error?.response?.data?.message
                                                                                            ??
                                                                                            'an error occurred, please try again later';

                                                                                        setFormAlertEnabled(true);
                                                                                        return setIsUpdatingImages(false);

                                                                                    });

                                                                                }}
                                                                                disabled={isUpdatingImages}
                                                                            >
                                                                                Update Images
                                                                                {" "}
                                                                                {isUpdatingImages && <BasicLoader size={"sm"} />}
                                                                            </Button>
                                                                        </div>
                                                                    </Grid>
                                                                </Grid>
                                                            </div>

                                                        </div>
                                                    </>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>}
                                <ImageList
                                    sx={{
                                        width: "100%",
                                        height: "100%",
                                        transform: 'translateZ(0)',
                                    }}
                                    rowHeight={250}
                                    gap={3}
                                    className="mt-5 rounded"
                                >
                                    {ghanaCardImages.map((item, index) => {

                                        const cols = item.featured ? 2 : 1;
                                        const rows = item.featured ? 2 : 1;

                                        return (
                                            <ImageListItem key={index} cols={cols} rows={rows}>
                                                <img
                                                    {...srcset(item.img, 250, 200, rows, cols)}
                                                    alt={item.title}
                                                    loading="lazy"
                                                />
                                                <ImageListItemBar
                                                    sx={{
                                                        background:
                                                            'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
                                                            'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
                                                    }}
                                                    title={item.title}
                                                    position="top"
                                                    actionIcon={
                                                        <IconButton
                                                            sx={{ color: 'white' }}
                                                            aria-label={`star ${item.title}`}
                                                        >
                                                            <CircleTwoToneIcon
                                                                fontSize="small"
                                                            />
                                                        </IconButton>
                                                    }
                                                    actionPosition="left"
                                                />
                                            </ImageListItem>
                                        );
                                    })}
                                </ImageList>
                            </div>
                        </div>
                    </>
                )
            }

            {/* Digital Address Form */}
            {
                !isLoading && specDriverData && stepData && stepIdentifier === riderOnboardingStepNames[1] && (
                    <>
                        <div className="card card-custom rounded-xl mb-2">
                            <div className="card-header border-0 pt-5">
                                <h3 className="card-title font-weight-bolder">
                                    Rider Details
                                </h3>
                            </div>
                            <div className="card-body">
                                <Form onSubmit={updateDigitalAddressFormik.handleSubmit}>

                                    <Form.Group controlId="digitalAddressFormDriverID">
                                        <Form.Label>
                                            Rider ID
                                        </Form.Label>
                                        <Form.Control type="text" disabled={true} value={updateDigitalAddressFormik.values.userID} onChange={updateDigitalAddressFormik.handleChange} className={`${getDigitalAddressInputClasses("userID")}`} name="userID" {...updateDigitalAddressFormik.getFieldProps("userID")} />
                                        <Form.Text className="text-muted">
                                            Rider ID
                                        </Form.Text>
                                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                        <Form.Control.Feedback type="invalid">
                                            Rider ID cannot be blank
                                        </Form.Control.Feedback>
                                        {updateDigitalAddressFormik.touched.userID && updateDigitalAddressFormik.errors.userID ? (
                                            <div className="fv-plugins-message-container">
                                                <div className="fv-help-block">
                                                    {updateDigitalAddressFormik.errors.userID}
                                                </div>
                                            </div>
                                        ) : null}
                                    </Form.Group>

                                    <Form.Group controlId="digitalAddressFormAddress">
                                        <Form.Label>
                                            Address
                                        </Form.Label>
                                        <Form.Control type="text" placeholder="Rider's Address" value={updateDigitalAddressFormik.values.address} onChange={updateDigitalAddressFormik.handleChange} className={`${getDigitalAddressInputClasses("address")}`} name="address" {...updateDigitalAddressFormik.getFieldProps("address")} />
                                        <Form.Text className="text-muted">
                                            Rider's Address
                                        </Form.Text>
                                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                        <Form.Control.Feedback type="invalid">
                                            Rider's Address cannot be blank
                                        </Form.Control.Feedback>
                                        {updateDigitalAddressFormik.touched.address && updateDigitalAddressFormik.errors.address ? (
                                            <div className="fv-plugins-message-container">
                                                <div className="fv-help-block">
                                                    {updateDigitalAddressFormik.errors.address}
                                                </div>
                                            </div>
                                        ) : null}
                                    </Form.Group>

                                    <Form.Group controlId="digitalAddressFormStreetAddress">
                                        <Form.Label>
                                            Street Address
                                        </Form.Label>
                                        <Form.Control type="text" placeholder="Rider's Street Address" value={updateDigitalAddressFormik.values.streetAddress} onChange={updateDigitalAddressFormik.handleChange} className={`${getDigitalAddressInputClasses("streetAddress")}`} name="streetAddress" {...updateDigitalAddressFormik.getFieldProps("streetAddress")} />
                                        <Form.Text className="text-muted">
                                            Rider's Street Address
                                        </Form.Text>
                                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                        <Form.Control.Feedback type="invalid">
                                            Rider's Street Address cannot be blank
                                        </Form.Control.Feedback>
                                        {updateDigitalAddressFormik.touched.streetAddress && updateDigitalAddressFormik.errors.streetAddress ? (
                                            <div className="fv-plugins-message-container">
                                                <div className="fv-help-block">
                                                    {updateDigitalAddressFormik.errors.streetAddress}
                                                </div>
                                            </div>
                                        ) : null}
                                    </Form.Group>

                                    <Form.Group controlId="digitalAddressFormDistrict">
                                        <Form.Label>
                                            District
                                        </Form.Label>
                                        <Form.Control type="text" placeholder="Rider's District" value={updateDigitalAddressFormik.values.district} onChange={updateDigitalAddressFormik.handleChange} className={`${getDigitalAddressInputClasses("district")}`} name="district" {...updateDigitalAddressFormik.getFieldProps("district")} />
                                        <Form.Text className="text-muted">
                                            Rider's District
                                        </Form.Text>
                                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                        <Form.Control.Feedback type="invalid">
                                            Rider's District cannot be blank
                                        </Form.Control.Feedback>
                                        {updateDigitalAddressFormik.touched.district && updateDigitalAddressFormik.errors.district ? (
                                            <div className="fv-plugins-message-container">
                                                <div className="fv-help-block">
                                                    {updateDigitalAddressFormik.errors.district}
                                                </div>
                                            </div>
                                        ) : null}
                                    </Form.Group>

                                    <Form.Group controlId="digitalAddressFormRegion">
                                        <Form.Label>
                                            Region
                                        </Form.Label>
                                        <Form.Control as="select" data-live-search="true" placeholder="Rider's Region" value={updateDigitalAddressFormik.values.region} onChange={updateDigitalAddressFormik.handleChange} className={`${getDigitalAddressInputClasses("region")}`} name="region" {...updateDigitalAddressFormik.getFieldProps("region")}>
                                            <option disabled value="">Select Region</option>
                                            <option value="Greater Accra Region">Greater Accra Region</option>
                                            <option value="Ashanti Region">Ashanti Region</option>
                                            <option value="Brong-Ahafo Region">Brong-Ahafo Region</option>
                                            <option value="Central Region">Central Region</option>
                                            <option value="Eastern Region">Eastern Region</option>
                                            <option value="North East Region">North East Region</option>
                                            <option value="Northern Region">Northern Region</option>
                                            <option value="Volta Region">Volta Region</option>
                                            <option value="Upper East Region">Upper East Region</option>
                                            <option value="Upper West Region">Upper West Region</option>
                                            <option value="Western Region">Western Region</option>
                                            <option value="Western North Region">Western North Region</option>
                                            <option value="Ahafo Region">Ahafo Region</option>
                                            <option value="Bono Region">Bono Region</option>
                                            <option value="Oti Region">Oti Region</option>
                                            <option value="Savannah Region">Savannah Region</option>
                                        </Form.Control>
                                        <Form.Text className="text-muted">
                                            Rider's Region
                                        </Form.Text>
                                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                        <Form.Control.Feedback type="invalid">
                                            Rider's Region cannot be blank
                                        </Form.Control.Feedback>
                                        {updateDigitalAddressFormik.touched.region && updateDigitalAddressFormik.errors.region ? (
                                            <div className="fv-plugins-message-container">
                                                <div className="fv-help-block">
                                                    {updateDigitalAddressFormik.errors.region}
                                                </div>
                                            </div>
                                        ) : null}
                                    </Form.Group>

                                    <Form.Group controlId="digitalAddressFormGhanaPostGpsCode">
                                        <Form.Label>
                                            Ghana Post GPS Code
                                        </Form.Label>
                                        <Form.Control type="text" placeholder="Rider's Ghana Post GPS Code" value={updateDigitalAddressFormik.values.ghanaPostGpsCode} onChange={updateDigitalAddressFormik.handleChange} className={`${getDigitalAddressInputClasses("ghanaPostGpsCode")}`} name="ghanaPostGpsCode" {...updateDigitalAddressFormik.getFieldProps("ghanaPostGpsCode")} />
                                        <Form.Text className="text-muted">
                                            Rider's Ghana Post GPS Code
                                        </Form.Text>
                                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                        <Form.Control.Feedback type="invalid">
                                            Rider's Ghana Post GPS Code cannot be blank
                                        </Form.Control.Feedback>
                                        {updateDigitalAddressFormik.touched.ghanaPostGpsCode && updateDigitalAddressFormik.errors.ghanaPostGpsCode ? (
                                            <div className="fv-plugins-message-container">
                                                <div className="fv-help-block">
                                                    {updateDigitalAddressFormik.errors.ghanaPostGpsCode}
                                                </div>
                                            </div>
                                        ) : null}
                                    </Form.Group>

                                    <Form.Group controlId="digitalAddressFormDateOfBirth">
                                        <Form.Label>
                                            Date of Birth
                                        </Form.Label>
                                        <Form.Control type="date" placeholder="Rider's Date of Birth" value={updateDigitalAddressFormik.values.dateOfBirth} onChange={updateDigitalAddressFormik.handleChange} className={`${getDigitalAddressInputClasses("dateOfBirth")}`} name="dateOfBirth" {...updateDigitalAddressFormik.getFieldProps("dateOfBirth")} />
                                        <Form.Text className="text-muted">
                                            Rider's Date of Birth
                                        </Form.Text>
                                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                        <Form.Control.Feedback type="invalid">
                                            Rider's Date of Birth cannot be blank
                                        </Form.Control.Feedback>
                                        {updateDigitalAddressFormik.touched.dateOfBirth && updateDigitalAddressFormik.errors.dateOfBirth ? (
                                            <div className="fv-plugins-message-container">
                                                <div className="fv-help-block">
                                                    {updateDigitalAddressFormik.errors.dateOfBirth}
                                                </div>
                                            </div>
                                        ) : null}
                                    </Form.Group>

                                    <Form.Group controlId="digitalAddressFormLevelOfEducation">
                                        <Form.Label>
                                            Level of Education
                                        </Form.Label>
                                        <Form.Control as="select" data-live-search="true" placeholder="Rider's Level of Education" value={updateDigitalAddressFormik.values.levelOfEducation} onChange={updateDigitalAddressFormik.handleChange} className={`${getDigitalAddressInputClasses("levelOfEducation")}`} name="levelOfEducation" {...updateDigitalAddressFormik.getFieldProps("levelOfEducation")}>
                                            <option disabled value="">Select Rider's Level of Education</option>
                                            <option value="Junior High">Junior High</option>
                                            <option value="Senior High">Senior High</option>
                                            <option value="Vocational Education">Vocational Education</option>
                                            <option value="Bachelor Degree">Bachelor Degree</option>
                                            <option value="Master Degree">Master Degree</option>
                                            <option value="Other">Other</option>
                                        </Form.Control>
                                        <Form.Text className="text-muted">
                                            Rider's Level of Education
                                        </Form.Text>
                                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                        <Form.Control.Feedback type="invalid">
                                            Rider's Level of Education cannot be blank
                                        </Form.Control.Feedback>
                                        {updateDigitalAddressFormik.touched.levelOfEducation && updateDigitalAddressFormik.errors.levelOfEducation ? (
                                            <div className="fv-plugins-message-container">
                                                <div className="fv-help-block">
                                                    {updateDigitalAddressFormik.errors.levelOfEducation}
                                                </div>
                                            </div>
                                        ) : null}
                                    </Form.Group>

                                    <Form.Group controlId="digitalAddressFormMobileMoneyNumber">
                                        <Form.Label>
                                            Rider's Mobile Money Number
                                        </Form.Label>
                                        <Form.Control type="text" placeholder="Rider's Mobile Money Number" value={updateDigitalAddressFormik.values.mobileMoneyNumber} onChange={updateDigitalAddressFormik.handleChange} className={`${getDigitalAddressInputClasses("mobileMoneyNumber")}`} name="mobileMoneyNumber" {...updateDigitalAddressFormik.getFieldProps("mobileMoneyNumber")} />
                                        <Form.Text className="text-muted">
                                            Rider's Mobile Money Number
                                        </Form.Text>
                                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                        <Form.Control.Feedback type="invalid">
                                            Rider's Mobile Money Number cannot be blank
                                        </Form.Control.Feedback>
                                        {updateDigitalAddressFormik.touched.mobileMoneyNumber && updateDigitalAddressFormik.errors.mobileMoneyNumber ? (
                                            <div className="fv-plugins-message-container">
                                                <div className="fv-help-block">
                                                    {updateDigitalAddressFormik.errors.mobileMoneyNumber}
                                                </div>
                                            </div>
                                        ) : null}
                                    </Form.Group>

                                    {updateDigitalAddressFormik.status ? <FormAlert isEnabled={isFormAlertEnabled} type={alertType.type} message={updateDigitalAddressFormik.status} icon={alertType.icon} handleDismiss={handleAlertDismiss} /> : null}

                                    <Button
                                        variant="contained"
                                        size="medium"
                                        color="info"
                                        type="submit"
                                        disabled={updateDigitalAddressFormik.isSubmitting}
                                        className={`font-weight-bold px-9 py-4 my-3`}
                                    >
                                        Update Digital Address
                                        {loading && <span className="ml-5 spinner spinner-white"></span>}
                                    </Button>
                                </Form>
                            </div>
                        </div>
                    </>
                )
            }

            {/* Info Session Form */}
            {
                !isLoading && specDriverData && stepData && stepIdentifier === riderOnboardingStepNames[2] && (
                    <>
                        <div className="card card-custom rounded-xl mb-2">
                            <div className="card-header border-0 pt-5">
                                <h3 className="card-title font-weight-bolder">
                                    Info Session
                                </h3>
                            </div>
                            <div className="card-body d-flex flex-column">
                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xxl-6 p-2">
                                        <div className="card card-custom bg-light-success">
                                            <div className="card-body d-flex flex-column">
                                                <h4 className="font-weight-bolder">
                                                    Cohort ID
                                                </h4>
                                                <div className='font-weight-bolder'>
                                                    {stepData?.driverCohortID ? stepData?.driverCohortID : (<>
                                                        <ErrorTwoToneIcon
                                                            color='error'
                                                            fontSize='medium'
                                                        />
                                                    </>)}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xxl-6 p-2">
                                        <div className="card card-custom bg-light-success">
                                            <div className="card-body d-flex flex-column">
                                                <h4 className="font-weight-bolder">
                                                    Cohort Tracker ID
                                                </h4>
                                                <div className='font-weight-bolder'>
                                                    {stepData?.cohortTrackerID ? stepData?.cohortTrackerID : (<>
                                                        <ErrorTwoToneIcon
                                                            color='error'
                                                            fontSize='medium'
                                                        />
                                                    </>)}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )
            }

            {/* Payment Plans Form */}
            {
                !isLoading && specDriverData && stepData && stepIdentifier === riderOnboardingStepNames[3] && (
                    <>
                        <div className="card card-custom rounded-xl">
                            <div className="card-header border-0 pt-5">
                                <h3 className="card-title font-weight-bolder">
                                    Selected Plan
                                </h3>
                            </div>
                            <div className="card-body d-flex flex-column">
                                <div className='centerMan mb-2'>
                                    <Avatar
                                        className={classes.avatar}
                                        sx={(theme) => ({
                                            width: 80,
                                            height: 80,
                                            color: '#fff',
                                            backgroundColor: theme.palette.info.light,
                                        })}
                                    >
                                        {driverSubscriptionPlan?.document?.weekNumber ? (<>
                                            <div className='centerMan'>
                                                <span className='bold'>
                                                    {driverSubscriptionPlan?.document?.weekNumber}
                                                </span>
                                                <span>
                                                    <small>
                                                        cycles
                                                    </small>
                                                </span>
                                            </div>
                                        </>) : (<>
                                            <ErrorTwoToneIcon
                                                color='error'
                                                fontSize='medium'
                                            />
                                        </>)}
                                    </Avatar>
                                    <span>
                                        Plan ID:
                                        {" "}
                                        {stepData?.subscriptionPlanID ? (<>
                                            <span className='bold'>
                                                {stepData.subscriptionPlanID}
                                            </span>
                                        </>) : (<>
                                            <ErrorTwoToneIcon
                                                color='error'
                                                fontSize='medium'
                                            />
                                        </>)}
                                    </span>
                                </div>
                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xxl-6 p-2">
                                        <div className="card card-custom bg-light-primary">
                                            <div className="card-body d-flex flex-column">
                                                <div className="font-weight-bolder">
                                                    Plan Price
                                                </div>
                                                <div className='font-weight-bolder'>
                                                    {driverSubscriptionPlan?.document?.planPrice ? (<>
                                                        <h4 className="font-weight-bolder">
                                                            <span>
                                                                GHS
                                                            </span>
                                                            {" "}
                                                            <span>
                                                                {driverSubscriptionPlan?.document?.planPrice}
                                                            </span>
                                                        </h4>
                                                    </>) : (<>
                                                        <ErrorTwoToneIcon
                                                            color='error'
                                                            fontSize='medium'
                                                        />
                                                    </>)}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xxl-6 p-2">
                                        <div className="card card-custom bg-light-primary">
                                            <div className="card-body d-flex flex-column">
                                                <div className="font-weight-bolder">
                                                    Payment Frequency
                                                </div>
                                                <div className='font-weight-bolder'>
                                                    {driverSubscriptionPlan?.document?.planDuration ? (<>
                                                        <h4 className="font-weight-bolder">
                                                            {(driverSubscriptionPlan?.document?.planDuration).toUpperCase()}
                                                        </h4>
                                                    </>) : (<>
                                                        <ErrorTwoToneIcon
                                                            color='error'
                                                            fontSize='medium'
                                                        />
                                                    </>)}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xxl-12 p-2">
                                        {!isSubscriptionPlansLoading && (<>
                                            <div className='centerMan mb-5'>
                                                <Button
                                                    size='medium'
                                                    variant='contained'
                                                    color={showSubscriptionPlansDiv ? "error" : "info"}
                                                    startIcon={showSubscriptionPlansDiv ? (<>
                                                        <HighlightOffTwoToneIcon />
                                                    </>) : (<>
                                                        <ChangeCircleTwoToneIcon />
                                                    </>)}
                                                    onClick={() => {
                                                        setShowSubscriptionPlansDiv(prevState => !prevState);
                                                    }}
                                                >
                                                    {showSubscriptionPlansDiv && "Close Payment Plan Form"}
                                                    {!showSubscriptionPlansDiv && "Change Rider's Payment Plan"}
                                                </Button>
                                            </div>
                                        </>)}

                                        {showSubscriptionPlansDiv && (<>

                                            <div>
                                                <Form onSubmit={paymentPlanFormik.handleSubmit}>
                                                    <Form.Group controlId="paymentPlanDriverID">
                                                        <Form.Label>
                                                            Driver ID
                                                        </Form.Label>
                                                        <Form.Control type="text" disabled={true} value={paymentPlanFormik.values.driverID} onChange={paymentPlanFormik.handleChange} className={`${changePaymentPlanClasses("driverID")}`} name="driverID" {...paymentPlanFormik.getFieldProps("driverID")} />
                                                        <Form.Text className="text-muted">
                                                            You do not need to provide a value for this field
                                                        </Form.Text>
                                                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                                        <Form.Control.Feedback type="invalid">
                                                            Driver ID cannot be blank
                                                        </Form.Control.Feedback>
                                                        {paymentPlanFormik.touched.driverID && paymentPlanFormik.errors.driverID ? (
                                                            <div className="fv-plugins-message-container">
                                                                <div className="fv-help-block">
                                                                    {paymentPlanFormik.errors.driverID}
                                                                </div>
                                                            </div>
                                                        ) : null}
                                                    </Form.Group>

                                                    <Form.Group controlId="paymentPlanSubscriptionPlanID">
                                                        <Form.Label>
                                                            Subscription Plan
                                                        </Form.Label>
                                                        <Form.Control as="select" placeholder="Guarantor's First Name" value={paymentPlanFormik.values.subscriptionPlanID} onChange={paymentPlanFormik.handleChange} className={`${changePaymentPlanClasses("subscriptionPlanID")}`} name="subscriptionPlanID" {...paymentPlanFormik.getFieldProps("subscriptionPlanID")}>
                                                            <option disabled defaultValue="" value="">Select one</option>
                                                            {subscriptionPlans.data.map((item, index) => {
                                                                return (
                                                                    <option value={item.subscriptionID} key={index}>
                                                                        {`${item.document.weekNumber} Weeks, GHS ${item.document.planPrice}.00 per week`}
                                                                    </option>
                                                                );
                                                            })}
                                                        </Form.Control>
                                                        <Form.Text className="text-muted">
                                                            Select Subscription Plan
                                                        </Form.Text>
                                                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                                        <Form.Control.Feedback type="invalid">
                                                            Subscription Plan cannot be blank
                                                        </Form.Control.Feedback>
                                                        {paymentPlanFormik.touched.subscriptionPlanID && paymentPlanFormik.errors.subscriptionPlanID ? (
                                                            <div className="fv-plugins-message-container">
                                                                <div className="fv-help-block">
                                                                    {paymentPlanFormik.errors.subscriptionPlanID}
                                                                </div>
                                                            </div>
                                                        ) : null}
                                                    </Form.Group>

                                                    <Form.Group controlId="paymentPlanDailyPaymentBuffer">
                                                        <Form.Label>
                                                            Daily Payment Buffer
                                                        </Form.Label>
                                                        <Form.Control type="number" placeholder="# of days until first invoice is generated" value={paymentPlanFormik.values.dailyPaymentBuffer} onChange={paymentPlanFormik.handleChange} className={`${changePaymentPlanClasses("dailyPaymentBuffer")}`} name="dailyPaymentBuffer" {...paymentPlanFormik.getFieldProps("dailyPaymentBuffer")} />
                                                        <Form.Text className="text-muted">
                                                            This only applies to daily plans - # of days until first invoice is generated
                                                        </Form.Text>
                                                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                                        <Form.Control.Feedback type="invalid">
                                                            Daily payment buffer cannot be blank
                                                        </Form.Control.Feedback>
                                                        {paymentPlanFormik.touched.dailyPaymentBuffer && paymentPlanFormik.errors.dailyPaymentBuffer ? (
                                                            <div className="fv-plugins-message-container">
                                                                <div className="fv-help-block">
                                                                    {paymentPlanFormik.errors.dailyPaymentBuffer}
                                                                </div>
                                                            </div>
                                                        ) : null}
                                                    </Form.Group>

                                                    {paymentPlanFormik.status ? <FormAlert isEnabled={isFormAlertEnabled} type={alertType.type} message={paymentPlanFormik.status} icon={alertType.icon} handleDismiss={handleAlertDismiss} /> : null}

                                                    <Button
                                                        variant="contained"
                                                        size="medium"
                                                        color="info"
                                                        type="submit"
                                                        disabled={paymentPlanFormik.isSubmitting}
                                                        className={`font-weight-bold px-9 py-4 my-3`}
                                                    >
                                                        Change Payment Plan
                                                        {loading && <span className="ml-5 spinner spinner-white"></span>}
                                                    </Button>

                                                </Form>
                                            </div>

                                        </>)}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )
            }

            {/* Safety Video Form */}

            {/* Maintenance Video Form */}

            {/* Initial Deposit Form */}

            {/* Interview Attended Form */}

            {/* Guarantors Form */}
            {
                !isLoading && specDriverData && stepData && stepIdentifier === riderOnboardingStepNames[8] && (
                    <>
                        <div className="card card-custom rounded-xl mb-2">
                            <div className="card-header border-0 pt-5">
                                <h3 className="card-title font-weight-bolder">
                                    Guarantor Details
                                </h3>
                            </div>
                            <div className="card-body">
                                <Form onSubmit={guarantorDetailsFormik.handleSubmit}>
                                    <Form.Group controlId="guarantorDetailsDriverID">
                                        <Form.Label>
                                            Driver ID
                                        </Form.Label>
                                        <Form.Control type="text" disabled={true} value={guarantorDetailsFormik.values.driverID} onChange={guarantorDetailsFormik.handleChange} className={`${getGuarantorDetailsInputClasses("driverID")}`} name="driverID" {...guarantorDetailsFormik.getFieldProps("driverID")} />
                                        <Form.Text className="text-muted">
                                            Driver ID
                                        </Form.Text>
                                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                        <Form.Control.Feedback type="invalid">
                                            Driver ID cannot be blank
                                        </Form.Control.Feedback>
                                        {guarantorDetailsFormik.touched.driverID && guarantorDetailsFormik.errors.driverID ? (
                                            <div className="fv-plugins-message-container">
                                                <div className="fv-help-block">
                                                    {guarantorDetailsFormik.errors.driverID}
                                                </div>
                                            </div>
                                        ) : null}
                                    </Form.Group>

                                    <Form.Group controlId="guarantorDetailsFirstName">
                                        <Form.Label>
                                            Guarantor's First Name
                                        </Form.Label>
                                        <Form.Control type="text" placeholder="Guarantor's First Name" value={guarantorDetailsFormik.values.firstName} onChange={guarantorDetailsFormik.handleChange} className={`${getGuarantorDetailsInputClasses("firstName")}`} name="firstName" {...guarantorDetailsFormik.getFieldProps("firstName")} />
                                        <Form.Text className="text-muted">
                                            Guarantor's First Name
                                        </Form.Text>
                                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                        <Form.Control.Feedback type="invalid">
                                            Guarantor's First Name cannot be blank
                                        </Form.Control.Feedback>
                                        {guarantorDetailsFormik.touched.firstName && guarantorDetailsFormik.errors.firstName ? (
                                            <div className="fv-plugins-message-container">
                                                <div className="fv-help-block">
                                                    {guarantorDetailsFormik.errors.firstName}
                                                </div>
                                            </div>
                                        ) : null}
                                    </Form.Group>

                                    <Form.Group controlId="guarantorDetailsLastName">
                                        <Form.Label>
                                            Guarantor's Last Name
                                        </Form.Label>
                                        <Form.Control type="text" placeholder="Guarantor's Last Name" value={guarantorDetailsFormik.values.lastName} onChange={guarantorDetailsFormik.handleChange} className={`${getGuarantorDetailsInputClasses("lastName")}`} name="lastName" {...guarantorDetailsFormik.getFieldProps("lastName")} />
                                        <Form.Text className="text-muted">
                                            Guarantor's Last Name
                                        </Form.Text>
                                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                        <Form.Control.Feedback type="invalid">
                                            Guarantor's Last Name cannot be blank
                                        </Form.Control.Feedback>
                                        {guarantorDetailsFormik.touched.lastName && guarantorDetailsFormik.errors.lastName ? (
                                            <div className="fv-plugins-message-container">
                                                <div className="fv-help-block">
                                                    {guarantorDetailsFormik.errors.lastName}
                                                </div>
                                            </div>
                                        ) : null}
                                    </Form.Group>

                                    <Form.Group controlId="guarantorDetailsPhoneNumber">
                                        <Form.Label>
                                            Phone Number
                                        </Form.Label>
                                        <Form.Control type="text" placeholder="+233 ..." value={guarantorDetailsFormik.values.phoneNumber} onChange={guarantorDetailsFormik.handleChange} className={`${getGuarantorDetailsInputClasses("phoneNumber")}`} name="phoneNumber" {...guarantorDetailsFormik.getFieldProps("phoneNumber")} />
                                        <Form.Text className="text-muted">
                                            Phone Number
                                        </Form.Text>
                                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                        <Form.Control.Feedback type="invalid">
                                            Phone Number cannot be blank
                                        </Form.Control.Feedback>
                                        {guarantorDetailsFormik.touched.phoneNumber && guarantorDetailsFormik.errors.phoneNumber ? (
                                            <div className="fv-plugins-message-container">
                                                <div className="fv-help-block">
                                                    {guarantorDetailsFormik.errors.phoneNumber}
                                                </div>
                                            </div>
                                        ) : null}
                                    </Form.Group>

                                    <Form.Group controlId="guarantorDetailsDigitalAddress">
                                        <Form.Label>
                                            Digital Address
                                        </Form.Label>
                                        <Form.Control type="text" placeholder="Guarantor's Digital Address" value={guarantorDetailsFormik.values.digitalAddress} onChange={guarantorDetailsFormik.handleChange} className={`${getGuarantorDetailsInputClasses("digitalAddress")}`} name="digitalAddress" {...guarantorDetailsFormik.getFieldProps("digitalAddress")} />
                                        <Form.Text className="text-muted">
                                            Digital Address
                                        </Form.Text>
                                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                        <Form.Control.Feedback type="invalid">
                                            Digital Address cannot be blank
                                        </Form.Control.Feedback>
                                        {guarantorDetailsFormik.touched.digitalAddress && guarantorDetailsFormik.errors.digitalAddress ? (
                                            <div className="fv-plugins-message-container">
                                                <div className="fv-help-block">
                                                    {guarantorDetailsFormik.errors.digitalAddress}
                                                </div>
                                            </div>
                                        ) : null}
                                    </Form.Group>

                                    <Form.Group controlId="guarantorDetailsEmail">
                                        <Form.Label>
                                            Email
                                        </Form.Label>
                                        <Form.Control type="text" placeholder="Guarantor's Email" value={guarantorDetailsFormik.values.email} onChange={guarantorDetailsFormik.handleChange} className={`${getGuarantorDetailsInputClasses("email")}`} name="email" {...guarantorDetailsFormik.getFieldProps("email")} />
                                        <Form.Text className="text-muted">
                                            Email
                                        </Form.Text>
                                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                        <Form.Control.Feedback type="invalid">
                                            Email cannot be blank
                                        </Form.Control.Feedback>
                                        {guarantorDetailsFormik.touched.email && guarantorDetailsFormik.errors.email ? (
                                            <div className="fv-plugins-message-container">
                                                <div className="fv-help-block">
                                                    {guarantorDetailsFormik.errors.email}
                                                </div>
                                            </div>
                                        ) : null}
                                    </Form.Group>

                                    <Form.Group controlId="guarantorDetailsProfession">
                                        <Form.Label>
                                            Profession
                                        </Form.Label>
                                        <Form.Control type="text" placeholder="Guarantor's Profession" value={guarantorDetailsFormik.values.profession} onChange={guarantorDetailsFormik.handleChange} className={`${getGuarantorDetailsInputClasses("profession")}`} name="profession" {...guarantorDetailsFormik.getFieldProps("profession")} />
                                        <Form.Text className="text-muted">
                                            Profession
                                        </Form.Text>
                                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                        <Form.Control.Feedback type="invalid">
                                            Profession cannot be blank
                                        </Form.Control.Feedback>
                                        {guarantorDetailsFormik.touched.profession && guarantorDetailsFormik.errors.profession ? (
                                            <div className="fv-plugins-message-container">
                                                <div className="fv-help-block">
                                                    {guarantorDetailsFormik.errors.profession}
                                                </div>
                                            </div>
                                        ) : null}
                                    </Form.Group>

                                    <Form.Group controlId="guarantorDetailsBusinessAddress">
                                        <Form.Label>
                                            Business Address
                                        </Form.Label>
                                        <Form.Control type="text" placeholder="Guarantor's Business Address" value={guarantorDetailsFormik.values.businessAddress} onChange={guarantorDetailsFormik.handleChange} className={`${getGuarantorDetailsInputClasses("businessAddress")}`} name="businessAddress" {...guarantorDetailsFormik.getFieldProps("businessAddress")} />
                                        <Form.Text className="text-muted">
                                            Business Address
                                        </Form.Text>
                                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                        <Form.Control.Feedback type="invalid">
                                            Business Address cannot be blank
                                        </Form.Control.Feedback>
                                        {guarantorDetailsFormik.touched.businessAddress && guarantorDetailsFormik.errors.businessAddress ? (
                                            <div className="fv-plugins-message-container">
                                                <div className="fv-help-block">
                                                    {guarantorDetailsFormik.errors.businessAddress}
                                                </div>
                                            </div>
                                        ) : null}
                                    </Form.Group>

                                    <div className="form-group row">
                                        <div className="col-lg-12 col-xl-12">
                                            {(!guarantorImageArray || guarantorImageArray.length <= 0) && (
                                                <>
                                                    <div className="centerMan">

                                                        <div className="col-xs-12 col-sm-12 col-md-12 mt-1 mb-3">
                                                            <div className="centerMan text-info">
                                                                <Alert
                                                                    icon={
                                                                        <InfoTwoToneIcon fontSize="inherit" />
                                                                    }
                                                                    severity="info"
                                                                >
                                                                    Choose front image first, then back image for Ghana card
                                                                </Alert>
                                                            </div>
                                                        </div>

                                                        <div className="image-input image-input-outline" id="kt_profile_avatar">
                                                            <div className="image-input-wrapper" style={{ backgroundImage: "none" }} />
                                                            <label
                                                                className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                                                                data-action="change"
                                                                data-toggle="tooltip"
                                                                title=""
                                                                data-original-title="Change avatar"
                                                            >
                                                                <i className="fa fa-pen icon-sm text-muted"></i>
                                                                <input
                                                                    type="file"
                                                                    name="pic"
                                                                    accept="image/*"
                                                                    multiple
                                                                    onChange={e => handleGuarantorGhanaCardImage(e)}
                                                                />
                                                                <input type="hidden" name="profile_avatar_remove" />
                                                            </label>
                                                            <span
                                                                className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                                                                data-action="cancel"
                                                                data-toggle="tooltip"
                                                                title=""
                                                                data-original-title="Cancel avatar"
                                                            >
                                                                <i className="ki ki-bold-close icon-xs text-muted"></i>
                                                            </span>
                                                            <span
                                                                onClick={() => {
                                                                    setGuarantorImageArray([]);
                                                                }}
                                                                className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                                                                data-action="remove"
                                                                data-toggle="tooltip"
                                                                title=""
                                                                data-original-title="Remove avatar"
                                                            >
                                                                <i className="ki ki-bold-close icon-xs text-muted"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                            <div className="m-0">
                                                {guarantorImageArray && guarantorImageArray.length > 0 &&
                                                    (
                                                        <>

                                                            <div className="d-flex flex-column w-100 centerMan">

                                                                <div className='d-flex align-items-center'>
                                                                    {guarantorImageArray.map((image, index) =>
                                                                        <div className='p-2' key={index}>
                                                                            <div className="image-input image-input-outline" id="kt_profile_avatar">
                                                                                <div className="image-input-wrapper" style={{ backgroundImage: `url(${image})` }} />
                                                                                <label
                                                                                    className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                                                                                    data-action="change"
                                                                                    data-toggle="tooltip"
                                                                                    title=""
                                                                                    data-original-title="Change avatar"
                                                                                >
                                                                                    <i className="fa fa-pen icon-sm text-muted"></i>
                                                                                    <input
                                                                                        type="file"
                                                                                        name="pic"
                                                                                        accept="image/*"
                                                                                        multiple
                                                                                        onChange={e => handleGuarantorGhanaCardImage(e)}
                                                                                    />
                                                                                    <input type="hidden" name="profile_avatar_remove" />
                                                                                </label>
                                                                                <span
                                                                                    className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                                                                                    data-action="cancel"
                                                                                    data-toggle="tooltip"
                                                                                    title=""
                                                                                    data-original-title="Cancel avatar"
                                                                                >
                                                                                    <i className="ki ki-bold-close icon-xs text-muted"></i>
                                                                                </span>
                                                                                <span
                                                                                    onClick={() => {

                                                                                        if (index > -1) {

                                                                                            const currentImageArray = guarantorImageArray;
                                                                                            const currentImageFileArray = guarantorGhanaCardImageFiles;

                                                                                            currentImageFileArray.splice(index, 1);
                                                                                            currentImageArray.splice(index, 1);

                                                                                            setGuarantorImageArray(currentImageArray);
                                                                                            setGuarantorGhanaCardImageFiles(currentImageFileArray);

                                                                                            setReRender(prevState => prevState + 1);

                                                                                        }

                                                                                    }}
                                                                                    className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                                                                                    data-action="remove"
                                                                                    data-toggle="tooltip"
                                                                                    title=""
                                                                                    data-original-title="Remove avatar"
                                                                                >
                                                                                    <i className="ki ki-bold-close icon-xs text-muted"></i>
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </div>

                                                                <div className="col-xs-12 col-sm-12 col-md-12 mt-8">
                                                                    <p className="centerMan text-info">
                                                                        Note that the images selected will replace already uploaded Ghana Card images
                                                                    </p>
                                                                </div>

                                                            </div>

                                                        </>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <ImageList
                                        sx={{
                                            width: "100%",
                                            height: "100%",
                                            transform: 'translateZ(0)',
                                        }}
                                        rowHeight={200}
                                        gap={1}
                                        className="mt-5"
                                    >
                                        {guarantorGhanaCardImages.map((item, index) => {

                                            const cols = item.featured ? 2 : 1;
                                            const rows = item.featured ? 2 : 1;

                                            return (
                                                <ImageListItem key={index} cols={cols} rows={rows}>
                                                    <img
                                                        {...srcset(item.img, 250, 200, rows, cols)}
                                                        alt={item.title}
                                                        loading="lazy"
                                                    />
                                                    <ImageListItemBar
                                                        sx={{
                                                            background:
                                                                'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
                                                                'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
                                                        }}
                                                        title={item.title}
                                                        position="top"
                                                        actionIcon={
                                                            <IconButton
                                                                sx={{ color: 'white' }}
                                                                aria-label={`star ${item.title}`}
                                                            >
                                                                <CircleTwoToneIcon
                                                                    fontSize="small"
                                                                />
                                                            </IconButton>
                                                        }
                                                        actionPosition="left"
                                                    />
                                                </ImageListItem>
                                            );
                                        })}
                                    </ImageList>

                                    {guarantorDetailsFormik.status ? <FormAlert isEnabled={isFormAlertEnabled} type={alertType.type} message={guarantorDetailsFormik.status} icon={alertType.icon} handleDismiss={handleAlertDismiss} /> : null}

                                    <Button
                                        variant="contained"
                                        size="medium"
                                        color="info"
                                        type="submit"
                                        disabled={guarantorDetailsFormik.isSubmitting}
                                        className={`font-weight-bold px-9 py-4 my-3`}
                                    >
                                        Update Guarantor Details
                                        {loading && <span className="ml-5 spinner spinner-white"></span>}
                                    </Button>

                                </Form>
                            </div>
                        </div>
                    </>
                )
            }

            {/* Home Visit Form */}

            {/* Driver contract */}
            {
                !isLoading && specDriverData && stepData && stepIdentifier === riderOnboardingStepNames[10] && (
                    <>
                        <div className="card card-custom rounded-xl mb-2">
                            <div className="card-header border-0 pt-5">
                                <h3 className="card-title font-weight-bolder">
                                    Rider Contract
                                </h3>
                            </div>
                            <div className="card-body">
                                <Form onSubmit={driverContractFormik.handleSubmit}>
                                    <Form.Group controlId="driverContractDriverUserID">
                                        <Form.Label>
                                            Rider ID
                                        </Form.Label>
                                        <Form.Control type="text" disabled={true} value={driverContractFormik.values.userID} onChange={driverContractFormik.handleChange} className={`${getDriverContractInputClasses("userID")}`} name="userID" {...driverContractFormik.getFieldProps("userID")} />
                                        <Form.Text className="text-muted">
                                            You do not need to provide this field
                                        </Form.Text>
                                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                        <Form.Control.Feedback type="invalid">
                                            Rider ID cannot be blank
                                        </Form.Control.Feedback>
                                        {driverContractFormik.touched.userID && driverContractFormik.errors.userID ? (
                                            <div className="fv-plugins-message-container">
                                                <div className="fv-help-block">
                                                    {driverContractFormik.errors.userID}
                                                </div>
                                            </div>
                                        ) : null}
                                    </Form.Group>

                                    <div className="form-group row">
                                        <div className="col-lg-12 col-xl-12">
                                            {(!driverContractArray || driverContractArray.length <= 0) && (
                                                <>
                                                    <Form.Group controlId="driverContractDriverUserID">
                                                        <Form.Label>
                                                            Rider Signed Contract
                                                        </Form.Label>
                                                        <br />
                                                        <input
                                                            type="file"
                                                            name="file"
                                                            accept="*"
                                                            onChange={e => handleDriverContractFile(e)}
                                                            required
                                                        />
                                                    </Form.Group>
                                                </>
                                            )}
                                            <div className="m-0">
                                                {driverContractArray && driverContractArray.length > 0 &&
                                                    (
                                                        <>
                                                            <div className="row">

                                                                <div className="col-xs-12 col-sm-12 col-md-12 mt-8">
                                                                    <div className='centerMan'>
                                                                        <h4>
                                                                            Contract
                                                                        </h4>
                                                                    </div>
                                                                </div>

                                                                <div className="col-xs-12 col-sm-12 col-md-12 mt-8">
                                                                    <div className='centerMan'>
                                                                        <Button
                                                                            variant='contained'
                                                                            onClick={() => {
                                                                                setDriverContractArray([]);
                                                                                setDriverContractFiles([]);
                                                                            }}
                                                                            color='success'
                                                                            size='small'
                                                                        >
                                                                            Replace this Contract
                                                                        </Button>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    {driverContractArray && driverContractArray.length > 0 && driverContractArray.map((item, index) => {
                                        return (
                                            <iframe
                                                title={"Driver Contract"}
                                                src={`${item?.document?.driverContractDocURL ?? item}`}
                                                style={{ width: "100%", height: "600px" }}
                                                key={index}
                                            >
                                            </iframe>
                                        );
                                    })}

                                    {driverContractFormik.status ? <FormAlert isEnabled={isFormAlertEnabled} type={alertType.type} message={driverContractFormik.status} icon={alertType.icon} handleDismiss={handleAlertDismiss} /> : isFormAlertEnabled ? (<>
                                        <FormAlert
                                            isEnabled={isFormAlertEnabled}
                                            type={alertType.type}
                                            message={modalError.current}
                                            icon={alertType.icon}
                                            handleDismiss={handleAlertDismiss}
                                        />
                                    </>) : null}

                                    <Button
                                        variant="contained"
                                        size="medium"
                                        color="info"
                                        type="submit"
                                        disabled={driverContractFormik.isSubmitting || disableDriverContractSubBtn}
                                        className={`font-weight-bold px-9 py-4 my-3`}
                                    >
                                        Update Rider Contract
                                        {loading && <span className="ml-5 spinner spinner-white"></span>}
                                    </Button>

                                </Form>
                            </div>
                        </div>
                    </>
                )
            }

            {/* Guarantor Contract Form */}
            {
                !isLoading && specDriverData && stepData && stepIdentifier === riderOnboardingStepNames[11] && (
                    <>
                        <div className="card card-custom rounded-xl mb-2">
                            <div className="card-header border-0 pt-5">
                                <h3 className="card-title font-weight-bolder">
                                    Guarantor Contract
                                </h3>
                            </div>
                            <div className="card-body">
                                <Form onSubmit={driverGuarantorContractFormik.handleSubmit}>
                                    <Form.Group controlId="driverContractDriverUserID">
                                        <Form.Label>
                                            Rider ID
                                        </Form.Label>
                                        <Form.Control type="text" disabled={true} value={driverGuarantorContractFormik.values.userID} onChange={driverGuarantorContractFormik.handleChange} className={`${getDriverContractInputClasses("userID")}`} name="userID" {...driverGuarantorContractFormik.getFieldProps("userID")} />
                                        <Form.Text className="text-muted">
                                            You do not need to provide this field
                                        </Form.Text>
                                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                        <Form.Control.Feedback type="invalid">
                                            Rider ID cannot be blank
                                        </Form.Control.Feedback>
                                        {driverGuarantorContractFormik.touched.userID && driverGuarantorContractFormik.errors.userID ? (
                                            <div className="fv-plugins-message-container">
                                                <div className="fv-help-block">
                                                    {driverGuarantorContractFormik.errors.userID}
                                                </div>
                                            </div>
                                        ) : null}
                                    </Form.Group>

                                    <div className="form-group row">
                                        <div className="col-lg-12 col-xl-12">
                                            {(!driverGuarantorContractArray || driverGuarantorContractArray.length <= 0) && (
                                                <>
                                                    <Form.Group controlId="driverGuarantorContractDriverUserID">
                                                        <Form.Label>
                                                            Guarantor's Signed Contract
                                                        </Form.Label>
                                                        <br />
                                                        <input
                                                            type="file"
                                                            name="file"
                                                            accept="*"
                                                            onChange={e => handleDriverGuarantorContractFile(e)}
                                                            required
                                                        />
                                                    </Form.Group>
                                                </>
                                            )}
                                            <div className="m-0">
                                                {driverGuarantorContractArray && driverGuarantorContractArray.length > 0 &&
                                                    (
                                                        <>
                                                            <div className="row">

                                                                <div className="col-xs-12 col-sm-12 col-md-12 mt-8">
                                                                    <div className='centerMan'>
                                                                        <h4>
                                                                            Contract
                                                                        </h4>
                                                                    </div>
                                                                </div>

                                                                <div className="col-xs-12 col-sm-12 col-md-12 mt-8">
                                                                    <div className='centerMan'>
                                                                        <Button
                                                                            variant='contained'
                                                                            onClick={() => {
                                                                                setDriverGuarantorContractArray([]);
                                                                                setDriverGuarantorContractFiles([]);
                                                                            }}
                                                                            color='success'
                                                                            size='small'
                                                                        >
                                                                            Replace this Contract
                                                                        </Button>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    {driverGuarantorContractArray && driverGuarantorContractArray.length > 0 && driverGuarantorContractArray.map((item, index) => {
                                        return (
                                            <iframe
                                                title={"Guarantor Contract"}
                                                src={`${item?.document?.guarantorContractDocURL ?? item}`}
                                                style={{ width: "100%", height: "600px" }}
                                                key={index}
                                            >
                                            </iframe>
                                        );
                                    })}

                                    {driverGuarantorContractFormik.status ? <FormAlert isEnabled={isFormAlertEnabled} type={alertType.type} message={driverGuarantorContractFormik.status} icon={alertType.icon} handleDismiss={handleAlertDismiss} /> : isFormAlertEnabled ? (<>
                                        <FormAlert
                                            isEnabled={isFormAlertEnabled}
                                            type={alertType.type}
                                            message={modalError.current}
                                            icon={alertType.icon}
                                            handleDismiss={handleAlertDismiss}
                                        />
                                    </>) : null}

                                    <Button
                                        variant="contained"
                                        size="medium"
                                        color="info"
                                        type="submit"
                                        disabled={driverGuarantorContractFormik.isSubmitting || disableDriverGuarantorContractSubBtn}
                                        className={`font-weight-bold px-9 py-4 my-3`}
                                    >
                                        Update Guarantor Contract
                                        {loading && <span className="ml-5 spinner spinner-white"></span>}
                                    </Button>

                                </Form>
                            </div>
                        </div>
                    </>
                )
            }

            {/* Bike Received Form */}

            {/* Accessories Received Form */}

            {/* Vetting Form */}
            {
                !isLoading && specDriverData && stepData && stepIdentifier === riderOnboardingStepNames[14] && (
                    <>
                        <div className="card card-custom rounded-xl mb-2">
                            <div className="card-header border-0 pt-5">
                                <h3 className="card-title font-weight-bolder">
                                    Vetting Evaluation
                                </h3>
                            </div>
                            <div className="card-body d-flex flex-column">
                                <div className="row">
                                    {stepData?.responses && stepData?.responses.length > 0 ? stepData?.responses.map((response, index) => {

                                        return (
                                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xxl-12 p-2" key={index}>
                                                <div className="card card-custom rounded-xl">
                                                    <div className="card-body d-flex flex-column">
                                                        <h4 className="font-weight-bolder">
                                                            {`Attempt #${index + 1}`}
                                                        </h4>
                                                        <div className='font-weight-bolder mb-5'>
                                                            <span>
                                                                {"Score: "}
                                                                <span className={classes.largerNumber}>
                                                                    {response.score}
                                                                </span>
                                                                {" - "}
                                                                {response.isPassed ? (<>
                                                                    <span className='text-success bold'>
                                                                        Above score threshold
                                                                    </span>
                                                                </>) : (<>
                                                                    <span className='text-danger bold'>
                                                                        Below score threshold
                                                                    </span>
                                                                </>)}
                                                            </span>
                                                        </div>
                                                        <h4 className="font-weight-bolder">
                                                            Selected Options
                                                        </h4>
                                                        <div className='font-weight-bolder'>
                                                            {response.selectedOptions.map((selectedOption, innerIndex) => {
                                                                return (
                                                                    <div key={innerIndex} className='mb-3 p-3 bg-light-primary rounded-xl'>
                                                                        <span>
                                                                            <span>
                                                                                {innerIndex + 1}
                                                                            </span>
                                                                            {". "}
                                                                            {quizData.document.questions[innerIndex]}
                                                                        </span>
                                                                        <br />
                                                                        <span>
                                                                            <span className='bg-light-success p-1 rounded bold'>
                                                                                Selected option:
                                                                            </span>
                                                                            {" "}
                                                                            {quizData.document.answers[innerIndex] === selectedOption ? (
                                                                                <>
                                                                                    <VerifiedTwoToneIcon
                                                                                        color='success'
                                                                                    />
                                                                                </>
                                                                            ) : (
                                                                                <>
                                                                                    <CancelTwoToneIcon
                                                                                        color='error'
                                                                                    />
                                                                                </>
                                                                            )}
                                                                            {selectedOption}
                                                                        </span>
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );

                                    }) : (<>
                                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xxl-12">
                                            <div className='centerMan text-danger bold'>
                                                <span>
                                                    <ErrorTwoToneIcon
                                                        color='error'
                                                        fontSize='medium'
                                                    />
                                                    {" "}
                                                    This rider has not attempted the vetting assessment!
                                                </span>
                                            </div>
                                        </div>
                                    </>)}
                                </div>
                            </div>
                        </div>
                    </>
                )
            }

            {/* <div className="mt-4">
                <FormAlert
                    isEnabled={isFormAlertEnabled}
                    type={alertType.type}
                    message={modalError.current}
                    icon={alertType.icon}
                    handleDismiss={handleAlertDismiss}
                />
            </div> */}

            {/* {
                stateDataKeyLength > 3 && stepIdentifier === riderOnboardingStepNames[8] && <div className="card mb-2">
                    <div className="card-body">
                        <Form onSubmit={updateDigitalAddressFormik.handleSubmit}>

                            <Form.Group controlId="guarantorDetailsFormDriverID">
                                <Form.Label>
                                    Driver ID
                                </Form.Label>
                                <Form.Control type="text" disabled={true} value={updateDigitalAddressFormik.values.userID} onChange={updateDigitalAddressFormik.handleChange} className={`${getInputClasses("userID")}`} name="userID" {...updateDigitalAddressFormik.getFieldProps("userID")} />
                                <Form.Text className="text-muted">
                                    Driver ID
                                </Form.Text>
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">
                                    Driver ID cannot be blank
                                </Form.Control.Feedback>
                                {updateDigitalAddressFormik.touched.userID && updateDigitalAddressFormik.errors.userID ? (
                                    <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">
                                            {updateDigitalAddressFormik.errors.userID}
                                        </div>
                                    </div>
                                ) : null}
                            </Form.Group>

                            <Form.Group controlId="guarantorDetailsFormAddress">
                                <Form.Label>
                                    Address
                                </Form.Label>
                                <Form.Control type="text" placeholder="Driver's Address" value={updateDigitalAddressFormik.values.address} onChange={updateDigitalAddressFormik.handleChange} className={`${getInputClasses("address")}`} name="address" {...updateDigitalAddressFormik.getFieldProps("address")} />
                                <Form.Text className="text-muted">
                                    Driver's Address
                                </Form.Text>
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">
                                    Driver's Address cannot be blank
                                </Form.Control.Feedback>
                                {updateDigitalAddressFormik.touched.address && updateDigitalAddressFormik.errors.address ? (
                                    <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">
                                            {updateDigitalAddressFormik.errors.address}
                                        </div>
                                    </div>
                                ) : null}
                            </Form.Group>

                            <Form.Group controlId="guarantorDetailsFormStreetAddress">
                                <Form.Label>
                                    Driver's Street Address
                                </Form.Label>
                                <Form.Control type="text" placeholder="Driver's Street Address" value={updateDigitalAddressFormik.values.streetAddress} onChange={updateDigitalAddressFormik.handleChange} className={`${getInputClasses("streetAddress")}`} name="streetAddress" {...updateDigitalAddressFormik.getFieldProps("streetAddress")} />
                                <Form.Text className="text-muted">
                                    Driver's Street Address
                                </Form.Text>
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">
                                    Driver's Street Address cannot be blank
                                </Form.Control.Feedback>
                                {updateDigitalAddressFormik.touched.streetAddress && updateDigitalAddressFormik.errors.streetAddress ? (
                                    <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">
                                            {updateDigitalAddressFormik.errors.streetAddress}
                                        </div>
                                    </div>
                                ) : null}
                            </Form.Group>

                            <Form.Group controlId="guarantorDetailsDistrict">
                                <Form.Label>
                                    Driver's District
                                </Form.Label>
                                <Form.Control type="text" placeholder="Driver's District" value={updateDigitalAddressFormik.values.district} onChange={updateDigitalAddressFormik.handleChange} className={`${getInputClasses("district")}`} name="district" {...updateDigitalAddressFormik.getFieldProps("district")} />
                                <Form.Text className="text-muted">
                                    Driver's District
                                </Form.Text>
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">
                                    Driver's District cannot be blank
                                </Form.Control.Feedback>
                                {updateDigitalAddressFormik.touched.district && updateDigitalAddressFormik.errors.district ? (
                                    <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">
                                            {updateDigitalAddressFormik.errors.district}
                                        </div>
                                    </div>
                                ) : null}
                            </Form.Group>

                            <Form.Group controlId="guarantorDetailsFormRegion">
                                <Form.Label>
                                    Driver's Region
                                </Form.Label>
                                <Form.Control type="text" placeholder="Driver's Region" value={updateDigitalAddressFormik.values.region} onChange={updateDigitalAddressFormik.handleChange} className={`${getInputClasses("region")}`} name="region" {...updateDigitalAddressFormik.getFieldProps("region")} />
                                <Form.Text className="text-muted">
                                    Driver's Region
                                </Form.Text>
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">
                                    Driver's Region cannot be blank
                                </Form.Control.Feedback>
                                {updateDigitalAddressFormik.touched.region && updateDigitalAddressFormik.errors.region ? (
                                    <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">
                                            {updateDigitalAddressFormik.errors.region}
                                        </div>
                                    </div>
                                ) : null}
                            </Form.Group>

                            <Form.Group controlId="digitalAddressFormGhanaPostGpsCode">
                                <Form.Label>
                                    Driver's Ghana Post GPS Code
                                </Form.Label>
                                <Form.Control type="text" placeholder="Driver's Ghana Post GPS Code" value={updateDigitalAddressFormik.values.ghanaPostGpsCode} onChange={updateDigitalAddressFormik.handleChange} className={`${getInputClasses("ghanaPostGpsCode")}`} name="ghanaPostGpsCode" {...updateDigitalAddressFormik.getFieldProps("ghanaPostGpsCode")} />
                                <Form.Text className="text-muted">
                                    Driver's Ghana Post GPS Code
                                </Form.Text>
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">
                                    Driver's Ghana Post GPS Code cannot be blank
                                </Form.Control.Feedback>
                                {updateDigitalAddressFormik.touched.ghanaPostGpsCode && updateDigitalAddressFormik.errors.ghanaPostGpsCode ? (
                                    <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">
                                            {updateDigitalAddressFormik.errors.ghanaPostGpsCode}
                                        </div>
                                    </div>
                                ) : null}
                            </Form.Group>

                            {updateDigitalAddressFormik.status ? <FormAlert isEnabled={isFormAlertEnabled} type={alertType.type} message={updateDigitalAddressFormik.status} icon={alertType.icon} handleDismiss={handleAlertDismiss} /> : null}

                            <Button
                                variant="contained"
                                size="medium"
                                color="info"
                                type="submit"
                                disabled={updateDigitalAddressFormik.isSubmitting}
                                className={`font-weight-bold px-9 py-4 my-3`}
                            >
                                Update Digital Address
                                {loading && <span className="ml-5 spinner spinner-white"></span>}
                            </Button>
                        </Form>
                    </div>
                </div>
            } */}

        </>
    );
}